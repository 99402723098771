import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { useRouter } from 'next/router'
import { useTrans } from '@next-nx/hooks'
import { ProdottoType } from '@next-nx/utils/types'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import { Alert } from '@next-nx/shared-ui/icons'
import {
  Button,
  FormLayout,
  FormField,
  FormInput,
  FormError,
  NotificationBar,
  FormSelect,
  FormRadio,
} from '@next-nx/shared-ui/atoms'
import styles from './PreventivatoreFastQuoteCasa.module.sass'
import {
  useTipologieAbitazioneQuery,
  useTipologieProprietaQuery,
  useTipologieResidenzaQuery,
} from '@next-nx/shared-graphql/assicurazioni'
import { useGetPremioCasaFastMutation as useGetPremioCasaFastMutationAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { sendGTMData } from 'libs/shared-ui/utils/gtm'

interface Props {
  title?: string
  prodotto: ProdottoType
  formClassName?: string
  submitting: boolean
  setSubmitting: (v: boolean) => void
  idVariant?: string
  variant?: 'home' | 'prodotto'
  isModal?: boolean
  className?: string
}

const PreventivatoreFastQuoteCasa = (props: Props) => {
  const {
    prodotto = undefined,
    formClassName = '',
    submitting = false,
    setSubmitting = () => undefined,
    idVariant = '',
    variant = 'home',
    isModal = false,
    className = '',
  } = props

  const t = useTrans()
  const [error, setError] = useState<string | null>(null)
  const router = useRouter()

  const validationSchema = yup.object().shape({
    proprieta: yup.string().required(t('Campo obbligatorio')),
    cap: yup.string().required(t('Campo obbligatorio')),
    tipoResidenza: yup.string().required(t('Campo obbligatorio')),
    tipoAbitazione: yup.string().required(t('Campo obbligatorio')),
    piano: yup
      .number()
      .integer(t('Non è possibile inserire un numero con la virgola'))
      .typeError('Inserisci un numero valido')
      .min(0, t('Inserisci un numero di piano valido'))
      .max(50, t('Inserisci un numero di piano valido'))
      .required(t('Inserisci un numero di piano valido')),
    metriQuadrati: yup
      .number()
      .integer(t('Non è possibile inserire un numero con la virgola'))
      .typeError('Inserisci un numero valido')
      .min(
        30,
        t('è necessario specificare la superficie dell’abitazione maggiore o uguale a 30 mq')
      )
      .max(999, t('è stato raggiunto il limite di metri quadri possibili.'))
      .required(t('Inserisci un numero valido')),
    mail: yup.string().required(t('Campo obbligatorio')).email(t("Inserire un'email valida")),
    consensoTrattamento: yup.string().required(t('Campo obbligatorio')),
  })

  const [getPremioCasaFastMutationAssicurazioni] = useGetPremioCasaFastMutationAssicurazioni()

  // Solo site === ASSICURAZIONI
  const { data: tipologieProprietaData } = useTipologieProprietaQuery()
  const tipologieProprieta = tipologieProprietaData?.tipologieProprieta
  const { data: tipologieResidenzaData } = useTipologieResidenzaQuery()
  const tipologieResidenza = tipologieResidenzaData?.tipologieResidenza
  const { data: tipologieAbitazioneData } = useTipologieAbitazioneQuery()
  const tipologieAbitazione = tipologieAbitazioneData?.tipologieAbitazione

  const proprietaOptions = tipologieProprieta?.map((x) => ({
    value: x?.nome,
    label: x?.titolo,
  }))
  const tipoResidenzaOptions = tipologieResidenza?.map((x) => ({
    value: x?.nome,
    label: x?.titolo,
  }))

  const tipoAbitazioneOptions = tipologieAbitazione?.map((x) => ({
    value: x?.nome,
    label: x?.titolo,
  }))

  const flexFrom = isModal ? 'sm' : variant === 'prodotto' ? 'lg' : 'md'

  const initialValues = {
    proprieta: proprietaOptions?.[0]?.value || '',
    cap: '',
    tipoResidenza: tipoResidenzaOptions?.[0]?.value || '',
    tipoAbitazione: tipoAbitazioneOptions?.[0]?.value || '',
    piano: 0,
    metriQuadrati: undefined,
    mail: '',
    consensoTrattamento: 'SI',
  }

  return prodotto ? (
    <div
      className={`${styles.root} ${styles[`root--${variant}`]} ${className ? ` ${className}` : ''}`}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          setSubmitting(true)
          getPremioCasaFastMutationAssicurazioni({
            variables: {
              input: {
                proprieta: values.proprieta,
                cap: values.cap,
                tipoResidenza: values.tipoResidenza,
                tipoAbitazione: values.tipoAbitazione,
                metriQuadrati: values.metriQuadrati
                  ? parseInt(values.metriQuadrati)
                  : values.metriQuadrati,
                email: values.mail,
                consensoTrattamento: values.consensoTrattamento,
                piano: values.piano ? parseInt(values.piano) : values.piano,
              },
            },
          })
            .then((res) => {
              if (res?.data?.getPremioCasaFast?.status) {
                setError(null)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'preventivatore',
                  eventAction: 'timeout_page',
                  eventLabel: 200,
                })
                window.location.href =
                  '/quotazione/casa/' + res?.data?.getPremioCasaFast?.quotazione?.idQuotazione
                // router.push('/quotazione/casa/' + res?.data?.getPremioCasaFast?.quotazione?.idQuotazione)
              } else {
                setSubmitting(false)
                actions.setSubmitting(false)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'preventivatore',
                  eventAction: 'timeout_page',
                  eventLabel: 404,
                })
                if (
                  res?.data?.getPremioCasaFast?.message &&
                  res?.data?.getPremioCasaFast?.message != 'KO'
                ) {
                  setError(res?.data?.getPremioCasaFast?.message)
                } else {
                  setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
                }
              }
            })
            .catch((err) => {
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'preventivatore',
                eventAction: 'timeout_page',
                eventLabel: 400,
              })
              console.log(err)
              setSubmitting(false)
              actions.setSubmitting(false)
              setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <div className={`${styles.formContent} ${formClassName}`}>
              {/* Questo scrolla nella Modal */}
              <h2
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: t('<strong>Richiedi una quotazione</strong> in pochi click'),
                }}
              />
              <p className={styles.description}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'Puoi richiedere una quotazione solo se sei <strong>Cliente di Crédit Agricole Italia</strong>.<br>Vuoi diventare Cliente?'
                    ),
                  }}
                />
                <span className={styles.noClienteCta}>
                  <a
                    href="https://conti.credit-agricole.it/"
                    target="_blank"
                    onClick={() => {
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'preventivatore',
                        eventAction: prodotto?.titolo?.toGTMFormat(),
                        eventLabel: 'non_sei_cliente',
                      })
                    }}
                  >
                    <strong>{t('Clicca qui')}</strong>
                  </a>
                </span>
              </p>
              <FormLayout space="sm" className={styles.formLayout} flexFrom={flexFrom}>
                <FormField
                  label={t("Proprietà dell'immobile")}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('proprieta', values, errors, touched)}
                  className={`${styles.field} ${styles['field--md']}`}
                  id={'preventivatoreCasa_form_proprieta'}
                  required
                >
                  <FormSelect
                    id={'preventivatoreCasa_form_proprieta'}
                    name="proprieta"
                    placeholder={t('Seleziona')}
                    value={values.proprieta}
                    options={proprietaOptions}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                      setFieldValue('tipoResidenza', 'PRINCIPALE')
                    }}
                    onBlur={handleBlur}
                    status={touched.proprieta && errors.proprieta ? 'error' : ''}
                  />
                </FormField>
                <FormField
                  label={t('CAP')}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('cap', values, errors, touched)}
                  className={`${styles.field} ${styles['field--sm']}`}
                  id={'preventivatoreCasa_form_cap'}
                  required
                >
                  <FormInput
                    id={'preventivatoreCasa_form_cap'}
                    name="cap"
                    placeholder={t('Inserisci il CAP')}
                    value={values.cap}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                    status={touched.cap && errors.cap ? 'error' : ''}
                    required
                  />
                </FormField>
                <FormField
                  id={'preventivatoreCasa_form_tipoResidenza'}
                  label={t('Tipo residenza')}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('tipoResidenza', values, errors, touched)}
                  className={`${styles.field} ${styles['field--lg']}`}
                  required
                >
                  <FormSelect
                    id={'preventivatoreCasa_form_tipoResidenza'}
                    name="tipoResidenza"
                    placeholder={t('Seleziona')}
                    value={values.tipoResidenza}
                    options={
                      values.proprieta != 'AFFITTO'
                        ? tipoResidenzaOptions
                        : tipoResidenzaOptions?.filter((item) => item.value != 'LOCATA')
                    }
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                    status={touched.tipoResidenza && errors.tipoResidenza ? 'error' : ''}
                  />
                </FormField>
                <FormField
                  label={t('Tipo abitazione')}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('tipoAbitazione', values, errors, touched)}
                  className={`${styles.field} ${styles['field--md']}`}
                  id={'preventivatoreCasa_form_tipoAbitazione'}
                  required
                >
                  <FormSelect
                    id={'preventivatoreCasa_form_tipoAbitazione'}
                    name="tipoAbitazione"
                    placeholder={t('Seleziona')}
                    value={values.tipoAbitazione}
                    options={tipoAbitazioneOptions}
                    onChange={(e) => {
                      const newTipoAbitazione = e.target.value
                      setFieldValue(e.target.name, newTipoAbitazione)
                      if (newTipoAbitazione !== 'APPARTAMENTO') {
                        setFieldValue('piano', 0)
                      }
                    }}
                    onBlur={handleBlur}
                    status={touched.tipoAbitazione && errors.tipoAbitazione ? 'error' : ''}
                  />
                </FormField>
                {values.tipoAbitazione === 'APPARTAMENTO' && (
                  <FormField
                    id={'preventivatoreCasa_form_numeroPiano'}
                    label={t('Numero piano')}
                    flexFrom={flexFrom}
                    {...getFormFieldArgs('piano', values, errors, touched)}
                    className={`${styles.field} ${styles['field--sm']}`}
                    helper={{
                      id: 'preventivatore_fast_quote_piano_tooltip',
                      label: t('Indica il piano della tua abitazione'),
                      pos: 'bottom',
                    }}
                    required
                  >
                    <FormInput
                      id={'preventivatoreCasa_form_numeroPiano'}
                      name="piano"
                      type="number"
                      placeholder={t('Inserisci il piano')}
                      value={values.piano}
                      onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                      onBlur={handleBlur}
                      min="0"
                      status={touched.piano && errors.piano ? 'error' : ''}
                      required
                    />
                  </FormField>
                )}
                <FormField
                  label={t('Metri quadri')}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('metriQuadrati', values, errors, touched)}
                  className={`${styles.field} ${
                    values.tipoAbitazione === 'APPARTAMENTO'
                      ? styles['field--lg']
                      : styles['field--xl']
                  }`}
                  helper={{
                    id: 'preventivatore_fast_quote_metriquadrati_tooltip',
                    label: t(
                      'Misura commerciale che comprende i\
                    muri esterni, un terzo della superficie\
                    di balconi e/o terrazzi; non devono\
                    essere comprese le superfici di box,\
                    cantine e solai. In genere la superficie\
                    è indicata sull’atto di acquisto o sul\
                    contratto di affitto'
                    ),
                    pos: 'bottom',
                  }}
                  id={'preventivatoreCasa_form_metriQuadrati'}
                  required
                >
                  <FormInput
                    id={'preventivatoreCasa_form_metriQuadrati'}
                    name="metriQuadrati"
                    type="number"
                    placeholder={t('Inserisci la metratura')}
                    value={values.metriQuadrati}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                    status={touched.metriQuadrati && errors.metriQuadrati ? 'error' : ''}
                    min="0"
                    required
                  />
                </FormField>
                <FormField
                  label={t('E-mail')}
                  flexFrom={flexFrom}
                  {...getFormFieldArgs('mail', values, errors, touched)}
                  layout="full"
                  id={'preventivatoreCasa_form_mail'}
                  required
                >
                  <FormInput
                    id={'preventivatoreCasa_form_mail'}
                    name="mail"
                    type="email"
                    placeholder={t('Inserisci la tua e-mail')}
                    value={values.mail}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                    status={touched.mail && errors.mail ? 'error' : ''}
                    required
                  />
                </FormField>
                {/* <FormField
                  className={styles.consensoTrattamentoField}
                  {...getFormFieldArgs('consensoTrattamento', values, errors, touched)}
                  layout="full"
                >
                  <p className={styles.consensoTrattamentoLabel}>
                    {t(
                      'Acconsento, anche ai fini del trattamento dei dati personali, a ricevere informazioni, promozioni e comunicazioni commerciali di prodotti e servizi, anche tramite tecniche di comunicazioni a distanza ai recapiti da me forniti.'
                    )}
                  </p>
                  <FormRadio
                    id={'consenso_trattamento' + idVariant}
                    name="consensoTrattamento"
                    value={values.consensoTrattamento}
                    options={[
                      {
                        id: 'consenso_trattamento_si' + idVariant,
                        value: 'SI',
                        label: 'Acconsento',
                      },
                      {
                        id: 'consenso_trattamento_no' + idVariant,
                        value: 'NO',
                        label: 'Non acconsento',
                      },
                    ]}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    status={
                      touched.consensoTrattamento && errors.consensoTrattamento ? 'error' : ''
                    }
                  />
                  <ErrorMessage
                    name="consensoTrattamento"
                    render={(msg) => <FormError message={msg} />}
                  />
                </FormField> */}
                {error && (
                  <div className={styles.error}>
                    <FormError message={`<strong>Attenzione</strong>: ${error}`} visible />
                  </div>
                )}
              </FormLayout>
              <div className={styles.bottom}>
                <p
                  className={styles.privacyDisclaimer}
                  dangerouslySetInnerHTML={{
                    __html: t('Proseguendo, si accetta l’informativa {0}Privacy{1}.', [
                      '<a href="/privacy" target="_blank">',
                      '</a>',
                    ]),
                  }}
                />
                <Button
                  type="submit"
                  label={t('Calcola subito il preventivo')}
                  className={styles.submit}
                  loading={submitting}
                  disabled={!Object.keys(touched).length || !isValid || isSubmitting || submitting}
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'preventivatore',
                      eventAction: prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'step1',
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.ctaBoxMobile}>
              <Button
                type="submit"
                label={t('Prosegui con la quotazione')}
                className={styles.submitMobile}
                loading={submitting}
                disabled={!Object.keys(touched).length || !isValid || isSubmitting || submitting}
              />
            </div>
          </Form>
        )}
      </Formik>
      <p className={styles.campiObbligatori}>{t('*campi obbligatori')}</p>
    </div>
  ) : (
    <></>
  )
}

export default PreventivatoreFastQuoteCasa
