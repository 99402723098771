import * as icons from '@next-nx/shared-ui/icons'

export const getIcon = (key: string | null | undefined, idVariant?: string) => {
  if (!key) return null

  const keyToComponentName = key
    .split('_')
    .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
    .join('')

  //@ts-ignore
  const Icon = icons[`${keyToComponentName}`]

  if (Icon) return <Icon aria-hidden="true" idVariant={idVariant} />

  return null
}
