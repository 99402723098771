import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { Button, Modal } from '@next-nx/shared-ui/atoms'
import {
  QuotazioneGaranziaAutoBox,
  QuotazionePacchettoAutoSimpleBox,
} from '@next-nx/shared-ui/molecules'
import styles from './QuotazioneAutoRimozionePacchettoGaranzieNecessarieModal.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'

interface Props {
  variant: 'rimozione_garanzia_abbinata' | 'rimozione_garanzia_necessaria_pacchetto'
  pacchetto: PacchettoGaranziaQuotazioneAutoType
  garanzia: GaranziaQuotazioneAutoType
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoRimozionePacchettoGaranzieNecessarieModal = (props: Props) => {
  const {
    variant,
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const getGaranzieNecessariePacchetto = () => {
    const garanzieNecessariePacchetto = garanzie.filter(
      (x) =>
        x.codice !== garanzia.codice &&
        x?.[`garanzieNecessariePacchetto${garanzia.pacchetto.codice}`].includes(garanzia.codice) &&
        garanzieSelezionate.includes(x.codice)
    )

    // tolgo quelle che sono verificate dalla selezione di altri pacchetti
    const garanzieNecessariePacchettoVerified: string[] = []
    garanzieNecessariePacchetto.forEach((x) => {
      quotazione?.pacchetti
        .filter((p) => p.codice !== garanzia.pacchetto.codice)
        .forEach((p) => {
          if (
            x?.[`garanzieNecessariePacchetto${p.codice}`]?.length &&
            // isPacchettoChecked
            garanzie
              .filter((x) => x.pacchetto?.codice === p.codice)
              .map((x) => x.codice)
              .every((v) => garanzieSelezionate.includes(v))
          ) {
            garanzieNecessariePacchettoVerified.push(x.codice)
            return
          }
        })
    })

    return garanzieNecessariePacchetto.filter(
      (x) => !garanzieNecessariePacchettoVerified.includes(x.codice)
    )
  }

  const garanzieCollegate = getGaranzieNecessariePacchetto()

  return (
    <Modal
      id={'QuotazioneAutoRimozionePacchettoGaranzieNecessarie'}
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.QuotazioneAutoRimozioneGaranziaAbbinataModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('Annulla')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('annulla_rimozione')
            }}
          />
          <Button
            label={t('Rimuovi le garanzie')}
            onClick={() => {
              const response = handleGaranzieAndPacchetti(
                'garanzia',
                'remove',
                [],
                [...garanzieCollegate.map((x) => x.codice)],
                garanzieSelezionate,
                true,
                true
              )

              if (response) {
                handleGtm('rimuovi_garanzie')
                setOpen(false)
              }
            }}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p
          id={'QuotazioneAutoRimozionePacchettoGaranzieNecessarie_title'}
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('Vuoi <strong>rimuovere</strong> il pacchetto <strong>{0}</strong>?', [
              pacchetto.titolo || '',
            ]),
          }}
        />
        <div className={styles.description}>{'Per rimuovere il pacchetto:'}</div>
        <div className={styles.list}>
          <QuotazionePacchettoAutoSimpleBox
            quotazione={quotazione}
            pacchetto={pacchetto}
            garanzie={garanzie}
            garanzieSelezionate={garanzieSelezionate}
          />
        </div>
        <div className={styles.description}>
          Perderai anche{' '}
          {garanzieCollegate.length > 1 ? 'le garanzie collegate' : 'la garanzia collegata'}:
        </div>
        <div className={styles.list}>
          {garanzieCollegate.map((x) => (
            <QuotazioneGaranziaAutoBox
              garanzia={x}
              key={x.pk}
              showCheckbox={false}
              showCondizioni={false}
              showPrezzi={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoRimozionePacchettoGaranzieNecessarieModal
