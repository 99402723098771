import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { useSite, useTrans, useUtente } from '@next-nx/hooks'
import { Alert } from '@next-nx/shared-ui/icons'
import { FormSelect, Chip, Loader, NotificationBar, Pagination } from '@next-nx/shared-ui/atoms'
import { ArticoloCard } from '@next-nx/shared-ui/molecules'
import styles from './MagazineListing.module.sass'
import { useArticoliMagazineQuery as useArticoliMagazineQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useCategorieArticoliQuery as useCategorieArticoliQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useArticoliMagazineQuery as useArticoliMagazineQueryVita } from '@next-nx/shared-graphql/vita'
import { useCategorieArticoliQuery as useCategorieArticoliQueryVita } from '@next-nx/shared-graphql/vita'
import { ASSICURAZIONI, MAGAZINE_LISTING_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  className?: string
}

const MagazineListing = (props: Props) => {
  const { className = '' } = props

  const site = useSite()
  const useArticoliMagazineQuery =
    site === ASSICURAZIONI ? useArticoliMagazineQueryAssicurazioni : useArticoliMagazineQueryVita
  const useCategorieArticoliQuery =
    site === ASSICURAZIONI ? useCategorieArticoliQueryAssicurazioni : useCategorieArticoliQueryVita

  const t = useTrans()

  const orderOptions = [
    { label: t('Dal più recente'), value: '-publication_date' },
    { label: t('Dal meno recente'), value: 'publication_date' },
  ]

  const [page, setPage] = useState<number>(1)
  const [filtriAttivi, setFiltriAttivi] = useState<string[]>([])
  const [order, setOrder] = useState<string>(orderOptions[0].value)

  const handleChangeFiltri = (slug: string | null | undefined) => {
    let newFiltriAttivi: string[] = []

    if (slug) {
      if (filtriAttivi.indexOf(slug) > -1) newFiltriAttivi = filtriAttivi.filter((x) => x !== slug)
      else newFiltriAttivi = filtriAttivi.concat([slug])
    }

    setFiltriAttivi(newFiltriAttivi)
    setPage(1)

    refetch({
      offset: 0,
      first: MAGAZINE_LISTING_ITEMS_PER_PAGE,
      categoria_Slug_In: newFiltriAttivi.join(','),
      orderBy: order,
    })

    let elemToFocus = document?.querySelector(
      `#listing_articolo_${data?.articoliMagazine?.edges[0]?.node?.pk}`
    )

    if (elemToFocus) {
      elemToFocus.focus()
    }
  }

  const handleChangeOrder = (value: string) => {
    setOrder(value)
    setPage(1)

    refetch({
      offset: 0,
      first: MAGAZINE_LISTING_ITEMS_PER_PAGE,
      categoria_Slug_In: filtriAttivi.join(','),
      orderBy: value,
    })
  }

  const handleChangePage = (value: number) => {
    setPage(value)

    refetch({
      offset: (value - 1) * MAGAZINE_LISTING_ITEMS_PER_PAGE,
      first: MAGAZINE_LISTING_ITEMS_PER_PAGE,
      categoria_Slug_In: filtriAttivi.join(','),
      orderBy: order,
    })
  }

  const { data: categorieArticoliData } = useCategorieArticoliQuery()

  const [totaleArticoli, setTotaleArticoli] = useState<number>(0)

  useEffect(() => {
    if (categorieArticoliData) {
      let tmp = 0
      categorieArticoliData?.categorieArticoli?.edges.forEach((item) => {
        tmp += item?.node?.numArticoli ?? 0
      })
      setTotaleArticoli(tmp)
    }
  }, [categorieArticoliData])

  const { data, loading, error, refetch } = useArticoliMagazineQuery({
    variables: {
      offset: 0,
      first: MAGAZINE_LISTING_ITEMS_PER_PAGE,
    },
  })
  const totalCount = data?.articoliMagazine?.totalCount

  const swiperRef = useRef(null)

  const handleFocus = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index)
    }
  }

  useEffect(() => {
    if (swiperRef?.current) {
      const element = document?.querySelector('.slider-tag-news')
      if (element) {
        element.setAttribute('role', 'tablist')
      }
    }
  }, [swiperRef?.current])

  const { utente } = useUtente()

  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const menuRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 40 : 0) - 80)
      document.body.classList.add('news-menu-sticky')
    else document.body.classList.remove('news-menu-sticky')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={`${styles.magazineListing} ${className ? ` ${className}` : ''}`}>
      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: `${t('<strong>Tutte</strong> le news')} (${totaleArticoli})`,
        }}
      />
      <div className={styles.options}>
        <div className={styles.filters}>
          {!!categorieArticoliData?.categorieArticoli?.edges.length && (
            <Swiper
              ref={swiperRef}
              id="news-categories"
              spaceBetween={16}
              slidesPerView="auto"
              watchOverflow
              modules={[A11y]}
              a11y={{
                enabled: true,
                prevSlideMessage: t('Slide precedente'),
                nextSlideMessage: t('Slide successiva'),
                paginationBulletMessage: 'Vai alla slide {{index}}',
                slideRole: 'tab',
              }}
              wrapperClass="slider-tag-news"
            >
              <SwiperSlide
                role="tab"
                aria-selected={filtriAttivi.length === 0}
                aria-controls="news-listing"
              >
                <Chip
                  label={`${t('Tutti')} (${totaleArticoli})`}
                  onClick={() => {
                    handleChangeFiltri(null)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'filtri',
                      eventAction: 'tutti',
                      eventLabel: 'magazine',
                    })
                  }}
                  active={filtriAttivi.length === 0}
                  onFocus={() => handleFocus(0)}
                />
              </SwiperSlide>
              {categorieArticoliData?.categorieArticoli?.edges.map((cat, index) =>
                cat?.node && cat.node.numArticoli ? (
                  <SwiperSlide
                    role="tab"
                    key={cat.node.pk}
                    aria-selected={cat.node.slug ? filtriAttivi.indexOf(cat.node.slug) > -1 : false}
                    aria-controls="news-listing"
                  >
                    <Chip
                      label={`${cat?.node?.title} (${cat?.node?.numArticoli})`}
                      onClick={() => {
                        handleChangeFiltri(cat.node?.slug)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'filtri',
                          eventAction: cat?.node?.title?.toGTMFormat() || '',
                          eventLabel: 'magazine',
                        })
                      }}
                      active={cat.node.slug ? filtriAttivi.indexOf(cat.node.slug) > -1 : false}
                      onFocus={() => handleFocus(index)}
                    />
                  </SwiperSlide>
                ) : null
              )}
            </Swiper>
          )}
        </div>
        <div className={styles.order}>
          <label htmlFor={'ordina_per_comunicati'} className={styles.orderLabel}>
            {t('Ordina per')}:
          </label>
          <FormSelect
            id={'ordina_per_comunicati'}
            value={order}
            onChange={(e) => {
              handleChangeOrder(
                typeof e.target.value === 'string' ? e.target.value : '-publication_date'
              )
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'filtri',
                eventAction: `ordina_per_${
                  typeof e.target.value === 'string' &&
                  orderOptions.find((o) => o.value === e.target.value)?.label.toGTMFormat()
                }`,
                eventLabel: 'magazine',
              })
            }}
            options={orderOptions}
            className={styles.orderField}
            variant="dropdown"
          />
        </div>
        <div className={styles.menuSectionRef} ref={menuRef}>
          <div className={`${styles.menu} ${styles['menu--mobile']}`}>
            {/* MOBILE */}
            <Swiper
              id="swiper-menu-mobile"
              spaceBetween={0}
              slidesPerView={1}
              modules={[A11y, Navigation]}
              navigation={{ enabled: true }}
              a11y={{
                enabled: true,
                prevSlideMessage: t('Slide precedente'),
                nextSlideMessage: t('Slide successiva'),
                paginationBulletMessage: 'Vai alla slide {{index}}',
              }}
              className={styles.swiperMobile}
            >
              <SwiperSlide
                role="tab"
                aria-selected={filtriAttivi.length === 0}
                aria-controls="news-listing"
              >
                <a
                  className={`${styles.menuItem} ${
                    filtriAttivi.length === 0 ? styles['menuItem--active'] : ''
                  }`}
                  onClick={() => {
                    handleChangeFiltri(null)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'filtri',
                      eventAction: 'tutti',
                      eventLabel: 'magazine',
                    })
                  }}
                  aria-labelledby={`#menu_all_label`}
                >
                  <span
                    id={`#menu_all_label`}
                    dangerouslySetInnerHTML={{ __html: `${t('Tutti')} (${totaleArticoli})` || '' }}
                  />
                </a>
              </SwiperSlide>
              {categorieArticoliData?.categorieArticoli?.edges.map((cat, index) =>
                cat?.node && cat.node.numArticoli ? (
                  <SwiperSlide
                    role="tab"
                    key={cat.node.pk}
                    aria-selected={cat.node.slug ? filtriAttivi.indexOf(cat.node.slug) > -1 : false}
                    aria-controls="news-listing"
                  >
                    <a
                      key={cat.node.pk}
                      href={`#menu_${cat.node.pk}`}
                      className={`${styles.menuItem} ${
                        cat.node.slug && filtriAttivi.indexOf(cat.node.slug) > -1
                          ? styles['menuItem--active']
                          : ''
                      }`}
                      onClick={() => {
                        handleChangeFiltri(cat.node?.slug)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'filtri',
                          eventAction: cat?.node?.title?.toGTMFormat() || '',
                          eventLabel: 'magazine',
                        })
                      }}
                      aria-labelledby={`#menu_${cat.node.pk}_label`}
                    >
                      <span
                        id={`#menu_${cat.node.pk}_label`}
                        dangerouslySetInnerHTML={{
                          __html: `${cat?.node?.title} (${cat?.node?.numArticoli})` || '',
                        }}
                      />
                    </a>
                  </SwiperSlide>
                ) : null
              )}
            </Swiper>
          </div>
        </div>
      </div>
      {loading && !data ? (
        <Loader className={styles.loader} />
      ) : error ? (
        <div className={styles.error}>
          <NotificationBar variant="alert" label={error.message} icon={<Alert />} />
        </div>
      ) : (
        <>
          <div
            className={styles.list}
            id="news-listing"
            role="tabpanel"
            aria-label={t('Articoli news')}
          >
            {data?.articoliMagazine?.edges.map(
              (articolo) =>
                articolo?.node && (
                  <div className={styles.item} key={articolo?.node?.pk}>
                    <ArticoloCard
                      id={'listing_articolo_' + articolo?.node?.pk}
                      articolo={articolo.node}
                      onClick={() =>
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'articolo',
                          eventAction: articolo.node?.title?.toGTMFormat() || '',
                          eventLabel: 'magazine',
                        })
                      }
                    />
                  </div>
                )
            )}
          </div>
          <Pagination
            currentPage={page}
            totalCount={totalCount ? totalCount : 0}
            pageSize={MAGAZINE_LISTING_ITEMS_PER_PAGE}
            onPageChange={(page: number) => handleChangePage(page)}
            className={styles.pagination}
          />
        </>
      )}
    </div>
  )
}

export default MagazineListing
