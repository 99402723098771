import React from 'react'
import { WContainer, Breadcrumbs, Button } from '@next-nx/shared-ui/atoms'
import { Page } from '@next-nx/shared-ui/organisms'
import styles from './ContattaciThankYouTemplate.module.sass'
import { useTrans } from '@next-nx/hooks'

interface Props {
  pagina?: any
}

const ContattaciThankYouTemplate = (props: Props) => {
  const { pagina = null } = props

  const t = useTrans()

  return (
    <Page pagina={pagina} item={{}}>
      <main>
        <div className={`${styles.headBg}`}>
          <WContainer>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          </WContainer>
        </div>
        <div className="bg-white">
          <WContainer>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: pagina?.nome || '',
              }}
            />
            <div
              className={styles.descrizione}
              dangerouslySetInnerHTML={{
                __html: pagina?.descrizione || '',
              }}
            />
          </WContainer>
        </div>
        <div className={`${styles.homeBtnContainer} bg-grey`}>
          <Button label={t('Torna alla home')} href="/" />
        </div>
      </main>
    </Page>
  )
}

export default ContattaciThankYouTemplate
