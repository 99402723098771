import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { monthYearFormat } from '@next-nx/utils/safe'
import { FascicoloType } from '@next-nx/utils/types'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { Button, Chip } from '@next-nx/shared-ui/atoms'
import styles from './FascicoloCard.module.sass'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  fascicolo: FascicoloType
  className?: string
  isCatalogoFondi?: boolean
}

const FascicoloCard = (props: Props) => {
  const { fascicolo = undefined, className = '', isCatalogoFondi = false } = props

  const t = useTrans()

  return fascicolo ? (
    <div className={`${styles.fascicoloCard} ${className ? ` ${className}` : ''}`}>
      {fascicolo.edizione && (
        <p className={styles.edizione}>
          {t('Edizione')}:{' '}
          <span className={styles.value}>{monthYearFormat(fascicolo.edizione)}</span>
        </p>
      )}
      {!!fascicolo.tags?.length && (
        <div className={styles.chips}>
          {fascicolo.tags.map((tag) => (
            <Chip key={tag.pk} label={tag.titolo} variant="blue" size="sm" weight="regular" />
          ))}
        </div>
      )}
      {fascicolo.titolo && <p className={styles.title}>{fascicolo.titolo}</p>}
      {fascicolo.descrizione && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: fascicolo.descrizione || '' }}
        />
      )}
      {!!fascicolo.files?.length && (
        <div className={styles.ctaBox}>
          {fascicolo.files.map((file) => (
            <div key={file.pk}>
              <Button
                label={file.titolo}
                rightIcon={<ArrowRight />}
                variant="ghost"
                newPage
                href={file.url}
                ariaLabel={`${file.titolo} - Visualizza il documento in una nuova pagina`}
                onClick={() =>
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: isCatalogoFondi ? 'catalogo_fondi' : 'fascicolo',
                    eventAction: fascicolo.titolo?.toGTMFormat(),
                    eventLabel: file.url,
                  })
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default FascicoloCard
