import React, { useRef, useState, useEffect } from 'react'
import { scrollTo } from '@next-nx/utils/safe'
import { useSite, useTrans, useUtente } from '@next-nx/hooks'
import { WContainer, Breadcrumbs, MainTitle, MainDescription } from '@next-nx/shared-ui/atoms'
import {
  QuotazioneAutoHeadBox,
  QuotazioneAutoPromoBox,
  QuotazioneGaranziaAutoBox,
  QuotazioneGaranziaRcaBox,
  QuotazionePacchettoAutoBox,
} from '@next-nx/shared-ui/molecules'
import {
  Page,
  SimpleHeader,
  SimpleFooter,
  QuotazioneVerificaFormAuto,
  PreventivatoreFastQuoteLoader,
  QuotazioneAutoRiepilogo,
  QuotazioneAutoGaranzieModal,
  QuotazioneAutoModal,
} from '@next-nx/shared-ui/organisms'
import styles from './QuotazioneAutoTemplate.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoGaranzieModalVariant,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import { BANCA, SITES_ID } from 'libs/shared-ui/utils/settings'
import {
  CODICE_GARANZIA_RCA,
  CODICE_PACCHETTO_EVENTI,
  CODICE_PACCHETTO_PERSONA,
  CODICE_PACCHETTO_VEICOLO,
  STATO_GARANZIA_ESCL,
  STATO_GARANZIA_OBBLIGATORIA,
  STATO_GARANZIA_SEL,
  getKeyPremioFromSelected,
  getPremioAutoFast,
  getScontoPacchetto,
  getSelectedFromKeyPremio,
  isPacchettoSelectable,
  validateDataDecorrenza,
} from '@next-nx/utils/fastquote'
import { getGaranziaDetails, sendGTMData } from 'libs/shared-ui/utils/gtm'
import { useGetPremioAutoFastFullDetailMutation } from '@next-nx/shared-graphql/assicurazioni'

interface Props {
  pagina?: any
  quotazione?: QuotazioneAutoType
}

const QuotazioneAutoTemplate = (props: Props) => {
  const { pagina = null, quotazione: quotazioneIniziale } = props

  const t = useTrans()
  const site = useSite()
  const formRef = useRef()
  const { utente } = useUtente()

  const [quotazione, setQuotazione] = useState(quotazioneIniziale)
  const [quotationVerificaSubmitting, setQuotationVerificaSubmitting] = useState(false)
  const [garanzieSelezionate, setGaranzieSelezionate] = useState<string[]>([])
  const [blackboxSelezionata, setBlackboxSelezionata] = useState(false)
  const [pacchettiSelezionati, setPacchettiSelezionati] = useState<any>()
  const [actualKeyPremio, setActualKeyPremio] = useState<string>()
  const [keysPremio, setKeysPremio] = useState<string[]>()
  const [actualMassimaleRca, setActualMassimaleRca] = useState<string>()
  const [actualMassimaleCristalli, setActualMassimaleCristalli] = useState<string>()
  const [garanzie, setGaranzie] = useState<GaranziaQuotazioneAutoType[]>([])
  const [isValid, setValid] = useState(true)
  const [isValidDataDecorrenza, setValidDataDecorrenza] = useState(true)
  const [totaleQuotazione, setTotaleQuotazione] = useState<number>(0)
  const [totaleScontatoQuotazione, setTotaleScontatoQuotazione] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [garanzieModalOpen, setGaranzieModalOpen] = useState(false)
  const [dataGaranzieModal, setDataGaranzieModal] = useState<{
    variant: QuotazioneAutoGaranzieModalVariant
    pacchetto: PacchettoGaranziaQuotazioneAutoType | undefined
    garanzia: GaranziaQuotazioneAutoType | undefined
  }>()
  const [modalsQueue, setModalsQueue] = useState<(() => {})[]>([])
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)
  const [openGaranzieObbligatorie, setOpenGaranzieObbligatorie] = useState(false)

  const [getPremioAutoFastMutation] = useGetPremioAutoFastFullDetailMutation()

  const isGaranziaChecked = (garanzia: string) => {
    return garanzieSelezionate.indexOf(garanzia) > -1
  }

  /**
   * Controlla se il pacchetto passato è selezionato oppure no
   * Se viene passato il paramentro garanzieSelezionate allora esegue il controllo su quella lista di garanzie
   */
  const isPacchettoChecked = (
    pacchettoCodice: string | undefined,
    garanzieSelezionate?: string[]
  ) => {
    if (!pacchettoCodice) return false

    if (garanzieSelezionate) {
      return garanzie
        .filter((x) => x.pacchetto?.codice === pacchettoCodice)
        .map((x) => x.codice)
        .every((v) => garanzieSelezionate.includes(v))
    }
    return pacchettiSelezionati?.[pacchettoCodice]
  }

  useEffect(() => {
    // solo la prima volta che actualKeyPremio è null
    if (keysPremio?.length && !actualKeyPremio) {
      setActualKeyPremio(keysPremio[0])
    }
  }, [keysPremio])

  const getKeysPremio = (quotazione: QuotazioneAutoType) => {
    if (quotazione?.garanzie) {
      return [
        ...new Set(quotazione.garanzie.map((g: GaranziaQuotazioneAutoType) => g.keyPremio).sort()),
      ]
    }
    return []
  }

  useEffect(() => {
    if (quotazione) {
      setKeysPremio(getKeysPremio(quotazione))
      setActualMassimaleRca(quotazione.massimaleRca)
      setActualMassimaleCristalli(quotazione.massimaleCristalli)
      resetGaranzie()
    }
  }, [quotazione])

  const getGaranzieUniche = (q: QuotazioneAutoType) => {
    if (q?.garanzie) {
      let _gs = []
      _gs = q.garanzie.filter((g: GaranziaQuotazioneAutoType) => g.keyPremio == actualKeyPremio)
      return _gs
    }
    return []
  }

  /**
   * Cambia il keyPremio per aggiungere la blackbox
   */
  const addBlackbox = () => {
    const newKeyPremio = getKeyPremioFromSelected(
      true,
      pacchettiSelezionati[CODICE_PACCHETTO_VEICOLO],
      pacchettiSelezionati[CODICE_PACCHETTO_PERSONA],
      pacchettiSelezionati[CODICE_PACCHETTO_EVENTI]
    )
    setActualKeyPremio(newKeyPremio)
    // setBlackboxSelezionata(true) // => non serve, viene settata al cambio di actualKeyPremio
  }

  /**
   * Cambia il keyPremio per rimuovere la blackbox
   */
  const removeBlackbox = () => {
    const newKeyPremio = getKeyPremioFromSelected(
      false,
      pacchettiSelezionati[CODICE_PACCHETTO_VEICOLO],
      pacchettiSelezionati[CODICE_PACCHETTO_PERSONA],
      pacchettiSelezionati[CODICE_PACCHETTO_EVENTI]
    )
    setActualKeyPremio(newKeyPremio)
    // setBlackboxSelezionata(false) => non serve, viene settata al cambio di actualKeyPremio
  }

  /**
   * Aggiunge/rimuove pacchetti/garanzie
   * Esegue i controlli dei popup
   *
   * @param variant gestisce i pacchetti o le garanzie
   * @param action aggiungo, rimuovo o sostituisco la {variant}
   * @param pacchettiCodici lista di codici pacchetto sulla quale si applica la {action}
   * @param garanzieCodici lista di codici garanzie sulla quale si applica la {action}
   * @param garanzieSelezionate lista delle garanzie selezionate sulla quale vengono fatti i controlli (non usare la variabile di stato perchè si aggiorna in modo asincrono)
   * @param checkPacchetto booleano che indica se controllare anche l'aggiunta/rimozione dei pacchetti dopo l'aggiunta/rimozione delle garanzie
   * @param lazy se è true cambiando un pacchetto aggiorno solo la keyPremio, se false aggiungo/rimuovo anche le garanzie del pacchetto
   * @param garanzieSostituite lista di codici garanzie sostituite da {garanzieCodici}
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire la {action} perchè i controlli dei popup non sono rispettati
   */
  const handleGaranzieAndPacchetti = (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto: boolean = true,
    lazy: boolean = true,
    garanzieSostituite: string[] = []
  ): string[] | undefined => {
    const queueObj = [
      handleGaranzieAndPacchetti,
      variant,
      action,
      pacchettiCodici,
      garanzieCodici,
      garanzieSelezionate,
      checkPacchetto,
      lazy,
      garanzieSostituite,
    ]
    if (variant === 'pacchetto') {
      if (action === 'add') {
        return addPacchetti(pacchettiCodici, false)
      } else if (action === 'remove') {
        const checkRimozioneNecessariePacchetto = pacchettiCodici.map((p) =>
          garanzie
            .filter((x) => x.pacchetto?.codice === p)
            .map((x) => checkRimozioneGaranzieNecessariePacchetto(x.codice, garanzieSelezionate))
        )

        if (checkRimozioneNecessariePacchetto.find((x) => x.every((y) => y))) {
          const index = checkRimozioneNecessariePacchetto.findIndex((x) => x.every((y) => y))
          const pacchetto = quotazione?.pacchetti.find((p) => p.codice === pacchettiCodici[index])
          const garanzieCodici = garanzie
            .filter((x) => x.pacchetto?.codice === pacchettiCodici[index])
            .map((x) => x.codice)

          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'rimozione_pacchetto_garanzia_necessaria_pacchetto',
            pacchetto: pacchetto,
            garanzia: garanzie.find(
              (x) =>
                x.codice ===
                garanzieCodici?.[
                  checkRimozioneNecessariePacchetto[index].findIndex((y) => y === true)
                ]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }

        return removePacchetti(pacchettiCodici, garanzieSelezionate, checkPacchetto, lazy)
      }
    } else if (variant === 'garanzia') {
      if (action === 'add') {
        // Per ciascuna garanzia eseguo i controlli (garanzie escluse, garanzie abbinate, garanzie necessarie pacchetto)
        const checkEscluse = garanzieCodici.map((x) => checkGaranzieEscluse(x, garanzieSelezionate))
        const checkAbbinate = garanzieCodici.map((x) =>
          checkGaranzieAbbinate(x, [
            ...new Set([...garanzieSelezionate, ...garanzieCodici.filter((y) => y !== x)]),
          ])
        )
        const checkNecessariePacchetti = garanzieCodici.map((x) =>
          checkGaranzieNecessariePacchetto(x, [
            ...new Set([...garanzieSelezionate, ...garanzieCodici.filter((y) => y !== x)]),
          ])
        )

        // Se almeno un controllo non è passato => apro popup corrispondente
        if (Object.values(checkEscluse).some((x) => x)) {
          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'garanzie_escluse',
            pacchetto: undefined,
            garanzia: garanzie.find(
              (x) => x.codice === garanzieCodici?.[checkEscluse.findIndex((y) => y === true)]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }
        if (Object.values(checkAbbinate).some((x) => x)) {
          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'garanzie_abbinate',
            pacchetto: undefined,
            garanzia: garanzie.find(
              (x) => x.codice === garanzieCodici?.[checkAbbinate.findIndex((y) => y === true)]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }
        if (Object.values(checkNecessariePacchetti).some((x) => x)) {
          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'garanzie_necessarie_pacchetto',
            pacchetto: undefined,
            garanzia: garanzie.find(
              (x) =>
                x.codice === garanzieCodici?.[checkNecessariePacchetti.findIndex((y) => y === true)]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }

        // se tutti i controlli sono passati => aggiungo le garanzie
        if (
          Object.values(checkEscluse).every((x) => !x) &&
          Object.values(checkAbbinate).every((x) => !x) &&
          Object.values(checkNecessariePacchetti).every((x) => !x)
        ) {
          garanzie
            .filter((x) => garanzieCodici.includes(x.codice))
            .forEach((x) => {
              if (!garanzieSelezionate?.includes(x.codice)) handleGtm('add', x)
            })
          return addGaranzie(garanzieCodici, garanzieSelezionate, checkPacchetto, false)
        }
      } else if (action === 'remove') {
        // Per ciascuna garanzia eseguo i controlli (rimozione garanzie abbinate, rimozione garanzie necessarie pacchetto)
        const checkRimozioneAbbinate = garanzieCodici.map((x) =>
          checkRimozioneGaranzieAbbinate(
            x,
            garanzieSelezionate.filter((y) => !garanzieCodici.includes(y))
          )
        )
        const checkRimozioneNecessariePacchetto = garanzieCodici.map((x) =>
          checkRimozioneGaranzieNecessariePacchetto(x, garanzieSelezionate)
        )

        // Se almeno un controllo non è passato => apro popup corrispondente
        if (Object.values(checkRimozioneAbbinate).some((x) => x)) {
          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'rimozione_garanzia_abbinata',
            pacchetto: undefined,
            garanzia: garanzie.find(
              (x) =>
                x.codice === garanzieCodici?.[checkRimozioneAbbinate.findIndex((y) => y === true)]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }
        if (Object.values(checkRimozioneNecessariePacchetto).some((x) => x)) {
          setGaranzieModalOpen(true)
          setDataGaranzieModal({
            variant: 'rimozione_garanzia_necessaria_pacchetto',
            pacchetto: undefined,
            garanzia: garanzie.find(
              (x) =>
                x.codice ===
                garanzieCodici?.[checkRimozioneNecessariePacchetto.findIndex((y) => y === true)]
            ),
          })
          setModalsQueue((modalsQueue) => [queueObj, ...modalsQueue])
          return undefined
        }

        // se tutti i controlli sono passati => rimuovo le garanzie
        if (
          Object.values(checkRimozioneAbbinate).every((x) => !x) &&
          Object.values(checkRimozioneNecessariePacchetto).every((x) => !x)
        ) {
          garanzie
            .filter((x) => garanzieCodici.includes(x.codice))
            .forEach((x) => {
              if (garanzieSelezionate?.includes(x.codice)) handleGtm('remove', x)
            })
          return removeGaranzie(garanzieCodici, garanzieSelezionate, checkPacchetto, lazy)
        }
      } else if (action === 'substitute') {
        // prima rimuovo le garanzie {garanzieSostituite} poi aggiungo le garanzie {garanzieCodici}
        garanzie
          .filter((x) => garanzieSostituite.includes(x.codice))
          .forEach((x) => {
            if (garanzieSelezionate?.includes(x.codice)) handleGtm('remove', x)
          })
        const newGaranzieSelezionate = removeGaranzie(
          garanzieSostituite,
          garanzieSelezionate,
          checkPacchetto,
          lazy
        )
        garanzie
          .filter((x) => garanzieCodici.includes(x.codice))
          .forEach((x) => {
            if (!garanzieSelezionate?.includes(x.codice)) handleGtm('add', x)
          })
        return addGaranzie(garanzieCodici, newGaranzieSelezionate, checkPacchetto, false)
      }
    }

    return undefined
  }

  /**
   * Aggiunge i pacchetti (analogamente a blackbox) eseguendo eventualmente i controlli dei popup
   *
   * @param pacchettiCodici lista di codici pacchetto da aggiungere
   * @param lazy se false aggiungo anche le garanzie associate ai pacchetti, se true cambio solo la keyPremio
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire l'aggiunta perchè i controlli dei popup non sono rispettati
   */
  const addPacchetti = (pacchettiCodici: string[], lazy: boolean = false) => {
    const newKeyPremio = getKeyPremioFromSelected(
      blackboxSelezionata,
      pacchettiCodici.includes(CODICE_PACCHETTO_VEICOLO)
        ? true
        : pacchettiSelezionati?.[CODICE_PACCHETTO_VEICOLO] || false,
      pacchettiCodici.includes(CODICE_PACCHETTO_PERSONA)
        ? true
        : pacchettiSelezionati?.[CODICE_PACCHETTO_PERSONA] || false,
      pacchettiCodici.includes(CODICE_PACCHETTO_EVENTI)
        ? true
        : pacchettiSelezionati?.[CODICE_PACCHETTO_EVENTI] || false
    )

    // seleziono le garanzie del pacchetto solo se non è lazy
    if (!lazy) {
      const response = addGaranziePacchetti(pacchettiCodici, garanzieSelezionate)
      if (response) setActualKeyPremio(newKeyPremio)
      return response
    }

    setActualKeyPremio(newKeyPremio)
    return garanzieSelezionate
  }

  /**
   * Rimuove i pacchetti (analogamente a blackbox) eseguendo eventualmente i controlli dei popup
   *
   * @param pacchettiCodici lista di codici pacchetto da rimuovere
   * @param garanzieSelezionate lista delle garanzie selezionate sulla quale vengono fatti i controlli (solo se lazy è false)
   * @param checkPacchetto booleano che indica se controllare anche la rimozione dei pacchetti dopo la rimozione delle garanzie
   * @param lazy se false rimuovo anche le garanzie associate ai pacchetti, se true cambio solo la keyPremio
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire la rimozione perchè i controlli dei popup non sono rispettati
   */
  const removePacchetti = (
    pacchettiCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto: boolean = false,
    lazy: boolean = false
  ) => {
    const newKeyPremio = getKeyPremioFromSelected(
      blackboxSelezionata,
      pacchettiCodici.includes(CODICE_PACCHETTO_VEICOLO)
        ? false
        : pacchettiSelezionati[CODICE_PACCHETTO_VEICOLO],
      pacchettiCodici.includes(CODICE_PACCHETTO_PERSONA)
        ? false
        : pacchettiSelezionati[CODICE_PACCHETTO_PERSONA],
      pacchettiCodici.includes(CODICE_PACCHETTO_EVENTI)
        ? false
        : pacchettiSelezionati[CODICE_PACCHETTO_EVENTI]
    )

    // deseleziono le garanzie del pacchetto solo se non è lazy
    if (!lazy) {
      const response = removeGaranziePacchetti(pacchettiCodici, garanzieSelezionate, checkPacchetto)
      if (response) setActualKeyPremio(newKeyPremio)
      return response
    }

    setActualKeyPremio(newKeyPremio)
    return garanzieSelezionate
  }

  /**
   * Aggiunge le garanzie associate ai pacchetti eseguendo i controlli dei popup
   *
   * @param pacchettiCodici lista di codici pacchetto delle garanzie da aggiungere
   * @param garanzieSelezionate lista delle garanzie selezionate sulla quale vengono fatti i controlli (non usare la variabile di stato perchè si aggiorna in modo asincrono)
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire l'aggiunta perchè i controlli dei popup non sono rispettati
   */
  const addGaranziePacchetti = (pacchettiCodici: string[], garanzieSelezionate: string[]) => {
    const data = garanzie
      .filter((x) => pacchettiCodici.includes(x.pacchetto?.codice))
      .filter(
        (x) =>
          x.statoIniziale !== STATO_GARANZIA_OBBLIGATORIA && x.statoIniziale !== STATO_GARANZIA_ESCL
      )
      .map((x) => x.codice)

    return handleGaranzieAndPacchetti(
      'garanzia',
      'add',
      [],
      data,
      garanzieSelezionate,
      false,
      false
    )
  }

  /**
   * Rimuove le garanzie associate ai pacchetti eseguendo i controlli dei popup
   *
   * @param pacchettiCodici lista di codici pacchetto delle garanzie da rimuovere
   * @param garanzieSelezionate lista delle garanzie selezionate sulla quale vengono fatti i controlli (non usare la variabile di stato perchè si aggiorna in modo asincrono)
   * @param checkPacchetto booleano che indica se controllare anche la rimozione dei pacchetti dopo la rimozione delle garanzie
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire la rimozione perchè i controlli dei popup non sono rispettati
   */
  const removeGaranziePacchetti = (
    pacchettiCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto: boolean = false
  ) => {
    const data = garanzie
      .filter((x) => pacchettiCodici.includes(x.pacchetto?.codice))
      .filter((x) => x.statoIniziale !== STATO_GARANZIA_OBBLIGATORIA)
      .map((x) => x.codice)

    return handleGaranzieAndPacchetti(
      'garanzia',
      'remove',
      [],
      data,
      garanzieSelezionate,
      checkPacchetto,
      false
    )
  }

  /**
   * Aggiunge le garanzie senza eseguire i controlli dei popup, modificando la variabile di stato garanzieSelezionate
   * Eventualmente esegue il controllo per aggiungere il pacchetto associato alle garanzie passate
   *
   * @param data lista di codici garanzie da aggiungere
   * @param garanzieSelezionate lista delle garanzie selezionate (non usare la variabile di stato perchè si aggiorna in modo asincrono)
   * @param checkPacchetto booleano che indica se controllare anche l'aggiunta dei pacchetti dopo l'aggiunta delle garanzie
   * @param lazy utilizzato solo se {checkPacchetto} è true, se è true cambiando un pacchetto aggiorno solo la keyPremio, se false aggiungo anche le garanzie del pacchetto
   * @param _garanzie lista delle garanzie da usare in sostituzione alla variabile di stato garanzie
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire l'aggiunta perchè i controlli dei popup non sono rispettati
   */
  const addGaranzie = (
    data: string[],
    garanzieSelezionate: string[],
    checkPacchetto: boolean = true,
    lazy: boolean = false,
    _garanzie: GaranziaQuotazioneAutoType[] = []
  ) => {
    const garanzieList = _garanzie?.length ? _garanzie : garanzie
    if (data) {
      let newGaranzieSelezionate = [...new Set([...garanzieSelezionate, ...data])]
      setGaranzieSelezionate((state) => {
        newGaranzieSelezionate = [...new Set([...state, ...data])]
        return [...newGaranzieSelezionate]
      })

      if (checkPacchetto) {
        const pacchettiCodici = [
          ...new Set([
            ...garanzieList
              .filter((x) => x.pacchetto?.codice && data.includes(x.codice))
              .map((x) => x.pacchetto?.codice),
          ]),
        ]
        const pacchettiChecked = {}
        pacchettiCodici.forEach((pacchettoCodice) => {
          pacchettiChecked[pacchettoCodice] = isPacchettoChecked(
            pacchettoCodice,
            newGaranzieSelezionate
          )
        })
        if (pacchettiCodici.length && Object.values(pacchettiChecked).some((x) => x)) {
          addPacchetti([...pacchettiCodici.filter((x) => pacchettiChecked[x])], lazy)
        }
      }

      return newGaranzieSelezionate
    }

    return garanzieSelezionate
  }

  /**
   * Rimuove le garanzie senza eseguire i controlli dei popup, modificando la variabile di stato garanzieSelezionate
   * Eventualmente esegue il controllo per rimuovere il pacchetto associato alle garanzie passate
   *
   * @param data lista di codici garanzie da rimuovere
   * @param garanzieSelezionate lista delle garanzie selezionate (non usare la variabile di stato perchè si aggiorna in modo asincrono)
   * @param checkPacchetto booleano che indica se controllare anche la rimozione dei pacchetti dopo la rimozione delle garanzie
   * @param lazy utilizzato solo se {checkPacchetto} è true, se è true cambiando un pacchetto aggiorno solo la keyPremio, se false rimuovo anche le garanzie del pacchetto
   * @param _garanzie lista delle garanzie da usare in sostituzione alla variabile di stato garanzie
   * @returns lista aggiornata delle garanzie selezionate oppure undefined se non è possibile eseguire la rimozione perchè i controlli dei popup non sono rispettati
   */
  const removeGaranzie = (
    data: string[],
    garanzieSelezionate: string[],
    checkPacchetto: boolean = true,
    lazy: boolean = false
  ) => {
    if (data) {
      let newGaranzieSelezionate = [...garanzieSelezionate.filter((x) => !data.includes(x))]
      let oldGaranzieSelezionate = [...garanzieSelezionate]
      setGaranzieSelezionate((state) => {
        oldGaranzieSelezionate = [...state]
        newGaranzieSelezionate = [...oldGaranzieSelezionate.filter((x) => !data.includes(x))]
        return oldGaranzieSelezionate.filter((x) => !data.includes(x))
      })

      if (checkPacchetto) {
        const pacchettiCodici = [
          ...new Set([
            ...garanzie
              .filter((x) => x.pacchetto?.codice && data.includes(x.codice))
              .map((x) => x.pacchetto?.codice),
          ]),
        ]
        const pacchettiChecked = {}
        pacchettiCodici.forEach((pacchettoCodice) => {
          pacchettiChecked[pacchettoCodice] = isPacchettoChecked(
            pacchettoCodice,
            oldGaranzieSelezionate
          )
        })
        if (pacchettiCodici.length && Object.values(pacchettiChecked).some((x) => x)) {
          removePacchetti(
            [...pacchettiCodici.filter((x) => pacchettiChecked[x])],
            newGaranzieSelezionate,
            checkPacchetto,
            lazy
          )
        }
      }

      return newGaranzieSelezionate
    }

    return garanzieSelezionate
  }

  const resetGaranzie = () => {
    if (quotazione) {
      const garanzieUniche = getGaranzieUniche(quotazione)
      setGaranzie(garanzieUniche)

      if (garanzieUniche?.length > 0) handleGtm('impressions', null, garanzieUniche)

      // Se una garanzia selezionata non è più presente tra le garanzie della quotazione
      // => la rimuovo
      const allGaranzieCodici: string[] = []
      garanzieUniche.forEach((g) => {
        allGaranzieCodici.push(g.codice)
        g.children?.forEach((gc) => {
          allGaranzieCodici.push(gc.codice)
        })
      })
      // TODO/WAIT:
      // Se nella nuova quotazione ci sono garanzie con statoIniz == SEL
      // => le seleziono
      const newGaranzieSel = [] // garanzieUniche.filter((g) => g.statoIniziale === STATO_GARANZIA_SEL)
      setGaranzieSelezionate((garanzieSelezionate) => [
        ...new Set([
          ...garanzieSelezionate.filter((codice) => allGaranzieCodici.includes(codice)),
          ...garanzieUniche
            .filter(
              (x) =>
                x.statoIniziale === STATO_GARANZIA_OBBLIGATORIA && x.codice !== CODICE_GARANZIA_RCA
            )
            .map((x) => x.codice),
          // ...newGaranzieSel.map((g) => g.codice),
        ]),
      ])
    }
  }

  const validateDataQuotazione = () => {
    setValidDataDecorrenza(validateDataDecorrenza(quotazione?.dataDecorrenza))
  }

  useEffect(() => {
    setValid(isValidDataDecorrenza)
  }, [isValidDataDecorrenza])

  useEffect(() => {
    if (quotazione && actualKeyPremio) {
      resetGaranzie()
      setKeysPremio(getKeysPremio(quotazione))
      // seleziono blackBox e i pacchetti a seconda della keyPremio
      const selected = getSelectedFromKeyPremio(actualKeyPremio)
      setBlackboxSelezionata(selected.blackBox)
      setPacchettiSelezionati({
        [CODICE_PACCHETTO_VEICOLO]: selected.pacchettoVeicolo,
        [CODICE_PACCHETTO_PERSONA]: selected.pacchettoPersona,
        [CODICE_PACCHETTO_EVENTI]: selected.pacchettoEventi,
      })
      validateDataQuotazione()
    }
  }, [actualKeyPremio])

  useEffect(() => {
    if (quotazione && actualMassimaleRca && quotazione.massimaleRca != actualMassimaleRca) {
      // richiamo la getPremioAuto quando viene selezionato un nuovo massimale
      // in caso di successo, aggiornato la quotazione
      // altrimenti, ripristino il massimale della quotazione
      setQuotationVerificaSubmitting(true)
      getPremioAutoFast(
        getPremioAutoFastMutation,
        { siteId: SITES_ID[site], massimaleRca: actualMassimaleRca },
        quotazione
      )
        .then((res) => {
          if (res?.data?.getPremioAutoFast?.status) {
            setQuotazione(res?.data?.getPremioAutoFast?.quotazione)
          } else {
            setActualMassimaleRca(quotazione.massimaleRca)
          }
          setQuotationVerificaSubmitting(false)
        })
        .catch((err) => {
          console.log(err)
          setActualMassimaleRca(quotazione.massimaleRca)
          setQuotationVerificaSubmitting(false)
        })
    }
  }, [actualMassimaleRca])

  useEffect(() => {
    if (
      quotazione &&
      actualMassimaleCristalli &&
      quotazione?.massimaleCristalli != actualMassimaleCristalli
    ) {
      // richiamo la getPremioAuto quando viene selezionato un nuovo massimale
      // in caso di successo, aggiornato la quotazione
      // altrimenti, ripristino il massimale della quotazione
      setQuotationVerificaSubmitting(true)
      getPremioAutoFast(
        getPremioAutoFastMutation,
        { siteId: SITES_ID[site], massimaleCristalli: actualMassimaleCristalli },
        quotazione
      )
        .then((res) => {
          if (res?.data?.getPremioAutoFast?.status) {
            setQuotazione(res?.data?.getPremioAutoFast?.quotazione)
          } else {
            setActualMassimaleCristalli(quotazione.massimaleCristalli)
          }
          setQuotationVerificaSubmitting(false)
        })
        .catch((err) => {
          console.log(err)
          setActualMassimaleCristalli(quotazione.massimaleCristalli)
          setQuotationVerificaSubmitting(false)
        })
    }
  }, [actualMassimaleCristalli])

  /**
   * Quando aggiungo una garanzia controllo se ci sono garanzie del pacchetto da aggiungere per selezionare l'intero pacchetto
   * Solo se il pacchetto ha uno sconto
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkUpselling = (
    pacchettoCodice: string,
    garanzia: GaranziaQuotazioneAutoType,
    garanzieSelezionate: string[]
  ) => {
    if (!isPacchettoSelectable(pacchettoCodice, garanzie)) {
      return false
    }

    const garanziePacchettiRimanenti =
      garanzie.filter(
        (g) =>
          g.pacchetto?.codice === pacchettoCodice &&
          g.codice !== garanzia.codice &&
          !garanzieSelezionate.includes(g.codice)
      ) || []
    if (
      !isPacchettoChecked(pacchettoCodice) &&
      garanziePacchettiRimanenti.length > 0 &&
      getScontoPacchetto(quotazione, pacchettoCodice)
    ) {
      return true
    }
    return false
  }

  /**
   * Quando aggiungo una garanzie controllo se le garanzie abbinate ad essa sono già selezionate oppure no
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkGaranzieAbbinate = (
    garanziaCodice: string | undefined,
    garanzieSelezionate: string[]
  ) => {
    const garanzieAbbinate =
      garanzie.find((x) => x.codice === garanziaCodice)?.garanzieAbbinate || []

    // Considero anche i codici delle garanzie figlie nel caso di garanzie contenitori
    const garanzieSelezionateConFigli = garanzie
      .filter((x) => garanzieSelezionate.includes(x.codice))
      .map((x) => x.codice)
    garanzie
      .filter((x) => garanzieSelezionate.includes(x.codice))
      .forEach((x) => {
        x?.children?.forEach((y) => {
          garanzieSelezionateConFigli.push(y.codice)
        })
      })

    // ritorna true se non sono state selezionate tutte le garanzie abbinate alla garanzia "garanzia"
    return (
      garanzieAbbinate.length > 0 &&
      !garanzieAbbinate.every((v) => garanzieSelezionateConFigli.includes(v))
    )
  }

  /**
   * Quando aggiungo una garanzie controllo se le garanzie necessarie pacchetto sono già selezionate oppure no
   * Ciascuna garanzia ha 3 liste (una per ogni pacchetto)
   * Almeno un pacchetto deve essere selezionato per verificare la condizione
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkGaranzieNecessariePacchetto = (
    garanziaCodice: string | undefined,
    garanzieSelezionate: string[]
  ) => {
    const garanzia = garanzie.find((x) => x.codice === garanziaCodice)

    const garanzieSelezionateConFigli = garanzie
      .filter((x) => garanzieSelezionate.includes(x.codice))
      .map((x) => x.codice)
    garanzie
      .filter((x) => garanzieSelezionate.includes(x.codice))
      .forEach((x) => {
        x?.children?.forEach((y) => {
          garanzieSelezionateConFigli.push(y.codice)
        })
      })

    let status = true,
      allEmpty = true
    quotazione?.pacchetti.forEach((p) => {
      const garanzieNecessariePacchetto = garanzia?.[`garanzieNecessariePacchetto${p.codice}`] || []
      if (garanzieNecessariePacchetto.length !== 0) {
        allEmpty = false
      } else {
        return
      }
      const garanziePacchetto = garanzie
        .filter((x) => x.pacchetto?.codice === p.codice)
        .map((x) => x.codice)
      // aggiungo eventualmente anche i codici delle garanzie figli delle garanzie pacchetto considerate
      garanzie
        .filter((x) => garanziePacchetto.includes(x.codice))
        .forEach((x) => {
          x?.children?.forEach((y) => {
            garanziePacchetto.push(y.codice)
          })
        })

      if (
        garanzieNecessariePacchetto.every((x) => garanziePacchetto.includes(x)) &&
        isPacchettoChecked(p.codice, garanzieSelezionateConFigli)
      ) {
        status = false
        return
      }
    })

    // ritorna true se garanzieNecessarie{x} non sono tutte vuote
    // e nessun pacchetto è selezionato
    return !allEmpty && status
  }

  /**
   * Quando aggiungo una garanzie controllo se le garanzie escluse non sono già selezionate oppure no
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkGaranzieEscluse = (
    garanziaCodice: string | undefined,
    garanzieSelezionate: string[]
  ) => {
    // garanzie escluse da rimuovere se aggiungi la garanzia "garanzia"
    const garanzieEscluse = garanzie
      .filter(
        (x) => x.garanzieEscluse.includes(garanziaCodice) && garanzieSelezionate.includes(x.codice)
      )
      .map((x) => x.codice)

    // ritorna true se non è possibile aggiungere la garanzia "garanzia"
    // perchè ha delle garanzie escluse associate
    return garanzieEscluse.length != 0
  }

  /**
   * Quando rimuovo un pacchetto, se è selezionato e ha uno sconto associato apro il popup
   * (Opposto di Upselling)
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkRimozionePacchetto = (pacchettoCodice: string) => {
    if (isPacchettoChecked(pacchettoCodice) && getScontoPacchetto(quotazione, pacchettoCodice)) {
      return true
    }
    return false
  }

  /**
   * Quando rimuovo una garanzia se il pacchetto associato è selezionato e ha uno sconto allora apro il popup
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkRimozioneGaranzia = (garanzia: GaranziaQuotazioneAutoType | undefined) => {
    if (garanzia?.pacchetto && !isPacchettoSelectable(garanzia.pacchetto.codice, garanzie)) {
      return false
    }
    if (
      garanzia?.pacchetto &&
      isPacchettoChecked(garanzia.pacchetto.codice) &&
      getScontoPacchetto(quotazione, garanzia.pacchetto.codice)
    ) {
      return true
    }
    return false
  }

  /**
   * Quando rimuovo una garanzia controllo se sono selezionate delle garanzie abbinate ad essa
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkRimozioneGaranzieAbbinate = (
    garanziaCodice: string | undefined,
    garanzieSelezionate: string[]
  ) => {
    // garanzie abbinate da rimuovere se rimuovi la garanzia "garanzia"
    const garanzieAbbinate = garanzie
      .filter(
        (x) => x.garanzieAbbinate.includes(garanziaCodice) && garanzieSelezionate.includes(x.codice)
      )
      .map((x) => x.codice)

    // ritorna true se non è possibile rimuovere la garanzia "garanzie"
    // perchè ha garanzie abbinate associate
    return garanzieAbbinate.length != 0
  }

  /**
   * Quando rimuovo una garanzia (e quindi il suo pacchetto) controllo le condizioni sulle garanzie necessarie pacchetto sono ancora rispettate
   *
   * Ritorna true se il controllo non è passato => si apre popup
   */
  const checkRimozioneGaranzieNecessariePacchetto = (
    garanziaCodice: string | undefined,
    garanzieSelezionate: string[]
  ) => {
    const garanzia = garanzie.find((x) => x.codice === garanziaCodice)
    if (!garanzia?.pacchetto) return false

    // tutte le garanzie selezionate che hannno quel pacchetto come necessario
    const garanzieNecessariePacchetto = garanzie.filter(
      (x) =>
        x.codice !== garanziaCodice &&
        x?.[`garanzieNecessariePacchetto${garanzia.pacchetto.codice}`].includes(garanziaCodice) &&
        garanzieSelezionate.includes(x.codice)
    )

    // togliendo quel pacchetto, quali garanzie selezionate non sarebbero più valide?
    // quelle che hanno altri pacchetti come necessari ma non sono selezionati
    const garanzieNecessariePacchettoVerified: string[] = []
    garanzieNecessariePacchetto.forEach((x) => {
      quotazione?.pacchetti
        .filter((p) => p.codice !== garanzia.pacchetto.codice)
        .forEach((p) => {
          if (
            x?.[`garanzieNecessariePacchetto${p.codice}`]?.length &&
            isPacchettoChecked(p.codice, garanzieSelezionate)
          ) {
            if (!garanzieNecessariePacchettoVerified.includes(x.codice))
              garanzieNecessariePacchettoVerified.push(x.codice)
            return
          }
        })
    })

    return garanzieNecessariePacchettoVerified.length !== garanzieNecessariePacchetto.length
  }

  /*
  Ritorno le garanzie nell'ordine mostrato sul sito per impostare il campo "position" correttamente nel tracciamento
  */
  const getGaranziePerTracciamenti = (garanzie: GaranziaQuotazioneAutoType[]) => {
    const garanziePerTracciamenti: GaranziaQuotazioneAutoType[] = []

    const garanziaObb = garanzie.find((g) => g.codice === CODICE_GARANZIA_RCA)
    if (garanziaObb) garanziePerTracciamenti.push(garanziaObb)

    quotazione?.pacchetti.forEach((p) => {
      const garanziePacchetto = garanzie.filter((g) => g.pacchetto?.codice === p.codice)
      garanziePacchetto.forEach((g) => garanziePerTracciamenti.push(g))
    })

    garanzie
      ?.filter((x) => x.codice !== CODICE_GARANZIA_RCA && !x.pacchetto)
      .forEach((g) => {
        garanziePerTracciamenti.push(g)
      })

    return garanziePerTracciamenti
  }

  const handleGtm = (
    type: 'add' | 'remove' | 'impressions',
    garanzia?: GaranziaQuotazioneAutoType | null,
    garanzie?: GaranziaQuotazioneAutoType[]
  ) => {
    sendGTMData({
      event:
        type === 'add' ? 'addToCart' : type === 'remove' ? 'removeFromCart' : 'productImpression',
      ecommerce: {
        currencyCode: 'eur',
        ...(type === 'add' && {
          add: {
            actionField: {},
            products: [
              {
                ...getGaranziaDetails(garanzia, site, quotazione, garanzia.pacchetto?.titolo || ''),
                quantity: 1,
              },
            ],
          },
        }),
        ...(type === 'remove' && {
          remove: {
            actionField: {},
            products: [
              {
                ...getGaranziaDetails(garanzia, site, quotazione, garanzia.pacchetto?.titolo || ''),
                quantity: 1,
              },
            ],
          },
        }),
        ...(type === 'impressions' && {
          impressions: getGaranziePerTracciamenti(garanzie)?.map((x, i) => ({
            ...getGaranziaDetails(x, site, quotazione, x.pacchetto?.titolo || ''),
            position: i + 1,
          })),
        }),
      },
    })
  }

  useEffect(() => {
    site === BANCA &&
      sendGTMData({
        event: 'GAevent',
        eventCategory: 'preventivatore_banca',
        eventAction: quotazione?.prodotto?.titolo?.toGTMFormat() || '',
        eventLabel: 'step1',
      })
  }, [])

  return (
    <Page
      pagina={pagina}
      item={{}}
      header={<SimpleHeader fixed={quotationVerificaSubmitting} />}
      footer={<SimpleFooter className={styles.footer} />}
      contentClassName={styles.quotazioneContent}
      pageWrapClassName={styles.quotazionePageWrap}
      recaptchaRequired
    >
      <main>
        {!!quotazione && (
          <>
            <div
              className={`${styles.headBg} bg-grey ${
                site === BANCA ? styles['headBg--no-breadcrumbs'] : ''
              }`}
            >
              <WContainer>
                {site !== BANCA && <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />}
                <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
                <MainDescription html={pagina?.descrizione} />
              </WContainer>
            </div>
            <div className={styles.headBox}>
              <QuotazioneAutoHeadBox
                quotazione={quotazione}
                onClick={() => scrollTo(formRef.current, toolbarPresente, 0)}
              />
            </div>
            <div className="bg-white">
              <WContainer>
                <p
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: t('<strong>Seleziona le garanzie</strong> in base ai tuoi bisogni'),
                  }}
                />
                <div className={styles.layout}>
                  <div className={styles.main}>
                    <div className={styles.garanzieObbligatorie}>
                      {garanzie
                        ?.filter((x) => x.codice === CODICE_GARANZIA_RCA)
                        .map((x) => (
                          <QuotazioneGaranziaRcaBox
                            key={x.pk}
                            checked
                            openChildren={openGaranzieObbligatorie}
                            toggleChildren={() =>
                              setOpenGaranzieObbligatorie(!openGaranzieObbligatorie)
                            }
                            garanzia={x}
                            description={t('La RCA comprende le seguenti garanzie:')}
                            blackboxSelezionata={blackboxSelezionata}
                            toggleBlackbox={() => {
                              if (blackboxSelezionata) {
                                removeBlackbox()
                              } else {
                                addBlackbox()
                              }
                            }}
                            massimaleRcaValue={actualMassimaleRca}
                            setMassimaleRcaValue={setActualMassimaleRca}
                          />
                        ))}
                    </div>
                    <QuotazioneAutoPromoBox quotazione={quotazione} />
                    {quotazione.pacchetti.map((pacchetto) => (
                      <QuotazionePacchettoAutoBox
                        key={pacchetto.codice}
                        quotazione={quotazione}
                        pacchetto={pacchetto}
                        garanzie={garanzie.filter((x) => x.pacchetto?.codice === pacchetto.codice)}
                        garanzieSelezionate={garanzieSelezionate}
                        onClickGaranzia={(x) => {
                          const checked = isGaranziaChecked(x.codice)
                          if (checked) {
                            if (checkRimozioneGaranzia(x)) {
                              setGaranzieModalOpen(true)
                              setDataGaranzieModal({
                                variant: 'rimozione_garanzia',
                                pacchetto: x.pacchetto,
                                garanzia: x,
                              })
                            } else {
                              handleGaranzieAndPacchetti(
                                'garanzia',
                                'remove',
                                [],
                                [x.codice],
                                garanzieSelezionate,
                                true,
                                true
                              )
                            }
                          } else {
                            handleGaranzieAndPacchetti(
                              'garanzia',
                              'add',
                              [],
                              [x.codice],
                              garanzieSelezionate,
                              true,
                              false
                            )

                            if (checkUpselling(pacchetto.codice, x, garanzieSelezionate)) {
                              setGaranzieModalOpen(true)
                              setDataGaranzieModal({
                                variant: 'upselling',
                                pacchetto: x.pacchetto,
                                garanzia: x,
                              })
                            }
                          }
                        }}
                        massimaleCristalliValue={actualMassimaleCristalli}
                        setMassimaleCristalliValue={setActualMassimaleCristalli}
                        checked={isPacchettoChecked(pacchetto.codice)}
                        onClick={() => {
                          if (isPacchettoChecked(pacchetto.codice)) {
                            if (checkRimozionePacchetto(pacchetto.codice)) {
                              setGaranzieModalOpen(true)
                              setDataGaranzieModal({
                                variant: 'rimozione_pacchetto',
                                pacchetto: pacchetto,
                                garanzia: undefined,
                              })
                            } else {
                              handleGaranzieAndPacchetti(
                                'pacchetto',
                                'remove',
                                [pacchetto.codice],
                                [],
                                garanzieSelezionate,
                                true,
                                false
                              )
                            }
                          } else {
                            handleGaranzieAndPacchetti(
                              'pacchetto',
                              'add',
                              [pacchetto.codice],
                              [],
                              []
                            )
                          }
                        }}
                      />
                    ))}

                    <div className={styles.garanzieOpzionali}>
                      <p className={styles.garanzieOpzionaliTitle}>
                        {t('Completa la tua polizza')}
                      </p>
                      <div className={styles.garanzieOpzionaliList}>
                        {garanzie
                          ?.filter((x) => x.codice !== CODICE_GARANZIA_RCA && !x.pacchetto)
                          .map((x) => (
                            <QuotazioneGaranziaAutoBox
                              key={x.pk}
                              garanzia={x}
                              checked={
                                x?.statoIniziale === STATO_GARANZIA_OBBLIGATORIA ||
                                garanzieSelezionate.indexOf(x.codice) > -1
                              }
                              massimaleCristalliValue={actualMassimaleCristalli}
                              setMassimaleCristalliValue={setActualMassimaleCristalli}
                              onClick={() => {
                                const checked = isGaranziaChecked(x.codice)
                                if (checked) {
                                  handleGaranzieAndPacchetti(
                                    'garanzia',
                                    'remove',
                                    [],
                                    [x.codice],
                                    garanzieSelezionate,
                                    false,
                                    true
                                  )
                                } else {
                                  handleGaranzieAndPacchetti(
                                    'garanzia',
                                    'add',
                                    [],
                                    [x.codice],
                                    garanzieSelezionate,
                                    false,
                                    false
                                  )
                                }
                              }}
                            ></QuotazioneGaranziaAutoBox>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sidebar}>
                    <QuotazioneAutoRiepilogo
                      quotazione={quotazione}
                      garanzie={garanzie}
                      garanzieSelezionate={garanzieSelezionate}
                      pacchettiSelezionati={pacchettiSelezionati}
                      totaleScontatoQuotazione={totaleScontatoQuotazione}
                      setTotaleQuotazione={setTotaleQuotazione}
                      setTotaleScontatoQuotazione={setTotaleScontatoQuotazione}
                      isValid={isValid}
                      onClick={() => {
                        setModalOpen(true)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                          eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                          eventLabel: 'step2_0',
                          value: totaleScontatoQuotazione,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className={styles.bottom} ref={formRef}>
                  <QuotazioneVerificaFormAuto
                    quotazione={quotazione}
                    setQuotazione={setQuotazione}
                    setSubmitting={setQuotationVerificaSubmitting}
                  />
                </div>
              </WContainer>
            </div>
            <QuotazioneAutoModal
              quotazione={quotazione}
              garanzie={garanzie}
              keyPremio={actualKeyPremio}
              importoLordoAnnuoTotale={totaleQuotazione}
              importoLordoAnnuoTotaleScontato={totaleScontatoQuotazione}
              garanzieObbligatorie={garanzie
                .filter((g) => g.codice === CODICE_GARANZIA_RCA)
                .map((g) => g.codice)}
              garanzieSelezionate={garanzieSelezionate}
              open={modalOpen}
              setOpen={setModalOpen}
            />
            <QuotazioneAutoGaranzieModal
              variant={dataGaranzieModal?.variant}
              pacchetto={dataGaranzieModal?.pacchetto}
              garanzia={dataGaranzieModal?.garanzia}
              quotazione={quotazione}
              garanzie={garanzie}
              garanzieSelezionate={garanzieSelezionate}
              handleGaranzieAndPacchetti={handleGaranzieAndPacchetti}
              modalsQueue={modalsQueue}
              setModalsQueue={setModalsQueue}
              open={garanzieModalOpen}
              setOpen={setGaranzieModalOpen}
            />
          </>
        )}
        <PreventivatoreFastQuoteLoader
          prodotto={quotazione?.prodotto}
          open={quotationVerificaSubmitting}
        />
      </main>
    </Page>
  )
}

export default QuotazioneAutoTemplate
