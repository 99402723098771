import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'
import { getIcon } from '@next-nx/utils/icons'
import { MenuType } from '@next-nx/utils/types'
import { WContainer } from '@next-nx/shared-ui/atoms'
import styles from './MotiviSection.module.sass'
import { useTrans } from '@next-nx/hooks'

interface Props {
  item: MenuType
  className?: string
}

const MotiviSection = (props: Props) => {
  const { item, className = '' } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.motiviSection} ${className ? ` ${className}` : ''}`}>
      <WContainer variant="lg">
        <div className={styles.main}>
          <div className={styles.content}>
            <h3 className={styles.title}>{item.name}</h3>
            {!!item.description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </div>
        </div>
        {item.children?.edges?.length && (
          <div className={styles.slider}>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              modules={[Pagination, A11y]}
              pagination={{ clickable: true }}
              watchOverflow
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1100: {
                  slidesPerView: 4,
                },
              }}
              a11y={{
                enabled: true,
                prevSlideMessage: t('Slide precedente'),
                nextSlideMessage: t('Slide successiva'),
                paginationBulletMessage: 'Vai alla slide {{index}}',
              }}
            >
              {item.children.edges.map((child) => (
                <SwiperSlide key={child.node.pk} role="group" aria-roledescription="slide">
                  <div className={styles.item}>
                    {!!child.node.style && (
                      <div className={styles.itemIcon}>
                        {getIcon(child.node.style, 'motivisection_' + child.node?.pk?.toString())}
                      </div>
                    )}
                    {!!child.node.name && <p className={styles.itemTitle}>{child.node.name}</p>}
                    {!!child.node.description && (
                      <div
                        className={styles.itemDescription}
                        dangerouslySetInnerHTML={{ __html: child.node.description }}
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default MotiviSection
