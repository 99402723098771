import React from 'react'
import { ProdottoType } from '@next-nx/utils/types'
import { Button, Tooltip } from '@next-nx/shared-ui/atoms'
import { PreventivatoreLinee } from '@next-nx/shared-ui/organisms'
import styles from './PreventivatoreIntro.module.sass'
import { useTrans } from '@next-nx/hooks'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  title?: string
  ctaLabel?: string
  prodotto: ProdottoType
  productPage?: boolean
  setFormModalOpen: (v: boolean) => void
  selectedLinee: number[]
  setSelectedLinee: (v: number[]) => void
  className?: string
}

const PreventivatoreIntro = (props: Props) => {
  const getLineeNames = (linee_ids: number[]): string => {
    return linee_ids
      .map((ids) => prodotto?.linee.find((l) => l.pk === ids)?.titolo.toGTMFormat())
      .join(' | ')
  }

  const {
    title = '',
    ctaLabel = '',
    prodotto = undefined,
    productPage = false,
    setFormModalOpen = () => undefined,
    selectedLinee = [],
    setSelectedLinee = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  return prodotto ? (
    <div
      className={`${styles.preventivatoreIntro} ${
        productPage ? styles['preventivatoreIntro--productPage'] : ''
      } ${className ? ` ${className}` : ''}`}
    >
      {title && <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
      <PreventivatoreLinee
        linee={prodotto.linee.filter((linea) => linea.inPreventivatore)}
        productPage={productPage}
        selectedLinee={selectedLinee}
        setSelectedLinee={setSelectedLinee}
        sconto={prodotto.sconto}
      />
      <div className={styles.ctaBox}>
        {!selectedLinee.length ? (
          <Tooltip
            id="preventivatore-tooltip"
            label={t('É necessario selezionare almeno una linea per richiedere un preventivo')}
            className="preventivatore-tooltip"
            fullWidth={productPage ? true : false}
          >
            <Button
              label={ctaLabel}
              onClick={() => setFormModalOpen(true)}
              className={styles.cta}
              disabled={!selectedLinee.length}
            />
          </Tooltip>
        ) : (
          <Button
            label={ctaLabel}
            onClick={() => {
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'preventivatore',
                eventAction: prodotto.titolo?.toGTMFormat() || '',
                eventLabel: 'step1',
                linea_preventivatore: getLineeNames(selectedLinee),
              })
              setFormModalOpen(true)
            }}
            className={styles.cta}
            disabled={!selectedLinee.length}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default PreventivatoreIntro
