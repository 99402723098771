import React, { useRef, useState } from 'react'
import Link from 'next/link'
import { MenuType } from '@next-nx/utils/types'
import { Button, WContainer } from '@next-nx/shared-ui/atoms'
import { TextCtaBox } from '@next-nx/shared-ui/molecules'
import styles from './Nav.module.sass'
import { useFindMenu, useTrans } from '@next-nx/hooks'
import { sendGTMData } from '../../../utils/gtm'
import ArrowRight from '../../atoms/Icons/ArrowRight'

interface Props {
  className?: string
}

const Nav = (props: Props) => {
  const { className = '' } = props

  const [activeItem, setActiveItem] = useState<MenuType | null>(null)

  const mainMenu = useFindMenu('main')

  // Se il livello massimo è 2, non c'è lo stile dei titoletti (vita = 2, assicurazioni = 3)
  const maxLevelMenu =
    mainMenu &&
    mainMenu.children?.edges &&
    mainMenu.children.edges.filter(
      (x) =>
        x.node.children?.edges.length &&
        x.node.children.edges.filter((y) => y.node.children?.edges.length).length > 0
    ).length > 0
      ? 3
      : 2

  const isEnterPressed = (e: KeyboardEvent) => {
    if (e?.key === 'Enter') {
      return true
    } else {
      return false
    }
  }

  const isSpacePressed = (e: KeyboardEvent) => {
    if (e?.code === 'Space') {
      return true
    } else {
      return false
    }
  }

  const isTabPressed = (e: KeyboardEvent) => {
    if (e?.key === 'Tab' && !e.shiftKey) {
      return true
    } else {
      return false
    }
  }

  const isEscPressed = (e: KeyboardEvent) => {
    if (e?.key === 'Escape') {
      return true
    } else {
      return false
    }
  }

  const t = useTrans()

  return (
    <nav
      className={`${styles.nav} ${className ? ` ${className}` : ''}`}
      onMouseLeave={() => setActiveItem(null)}
      onKeyDown={(e) => isEscPressed(e) && setActiveItem(null)}
    >
      <ul role="menubar" className={styles.main}>
        {mainMenu &&
          mainMenu.children?.edges &&
          mainMenu.children.edges.map((item) => (
            <li key={item.node.pk} className={styles.mainItem} role="none">
              <button
                className={`${styles.mainItemLabel} ${styles.noLink} ${
                  activeItem?.pk === item.node.pk ? styles['mainItemLabel--open'] : ''
                }`}
                aria-label={item.node.name}
                aria-expanded={activeItem?.pk === item.node.pk}
                // aria-controls={`menubaritem_${item.node.pk}`}
                onMouseEnter={() => setActiveItem(item.node)}
                onKeyDown={(e) => {
                  if (isEnterPressed(e) || isSpacePressed(e)) {
                    if (activeItem != item.node) {
                      setActiveItem(item.node)
                    } else {
                      setActiveItem(null)
                    }
                  }
                }}
              >
                <span>{item.node.name}</span>
              </button>
              {activeItem?.pk === item?.node?.pk && (
                <div className={`${styles.dropdown} ${activeItem ? styles['dropdown--open'] : ''}`}>
                  <WContainer>
                    {!!activeItem?.children?.edges && (
                      <div
                        className={`${styles.dropdownContent}`}
                        id={`menubaritem_${activeItem.pk}`}
                      >
                        {maxLevelMenu === 3 ? (
                          activeItem.children.edges.map((item) => (
                            <div
                              key={item.node.pk}
                              className={`${styles.dropdownCol} ${
                                item.node.style === 'box' ? styles['dropdownCol--auto'] : ''
                              }`}
                            >
                              {item.node.style === 'box' ? (
                                <TextCtaBox
                                  item={item.node}
                                  size="sm"
                                  onClick={() =>
                                    sendGTMData({
                                      event: 'GAevent',
                                      eventCategory: 'menu',
                                      eventAction: 'banner',
                                      eventLabel: item?.node?.name?.toGTMFormat(),
                                    })
                                  }
                                />
                              ) : (
                                <div className={styles.dropdownItem}>
                                  {item.node.url ? (
                                    <Link legacyBehavior href={item.node.url}>
                                      <a
                                        className={`${styles.dropdownItemLabel} ${styles.link}`}
                                        onClick={() =>
                                          sendGTMData({
                                            event: 'GAevent',
                                            eventCategory: 'menu',
                                            eventAction: 'second_level_menu',
                                            eventLabel: `${activeItem.name?.toGTMFormat()} | ${item.node.name?.toGTMFormat()}`,
                                          })
                                        }
                                        aria-label={item.node.name}
                                        role="menuitem"
                                      >
                                        {item.node.name}
                                      </a>
                                    </Link>
                                  ) : (
                                    <span
                                      className={`${styles.dropdownItemLabel} ${styles.noLink}`}
                                      aria-label={item.node.name}
                                    >
                                      {item.node.name}
                                    </span>
                                  )}
                                  {item?.node?.children?.edges &&
                                    item.node.children.edges.filter((child) => !!child.node.url)
                                      .length > 0 && (
                                      <ul className={styles.dropdownItemChildren} role="menu">
                                        {item.node.children.edges
                                          .filter((child) => !!child.node.url)
                                          .map((child) => (
                                            <li key={child.node.pk}>
                                              <Link legacyBehavior href={child.node.url || '#'}>
                                                <a
                                                  className={styles.dropdownItemChild}
                                                  onClick={() =>
                                                    sendGTMData({
                                                      event: 'GAevent',
                                                      eventCategory: 'menu',
                                                      eventAction: 'third_level_menu',
                                                      eventLabel: `${activeItem.name?.toGTMFormat()} | ${item.node.name?.toGTMFormat()} | ${child.node.name?.toGTMFormat()}`,
                                                    })
                                                  }
                                                  aria-label={child.node.name}
                                                  role="menuitem"
                                                >
                                                  {child.node.name}
                                                </a>
                                              </Link>
                                            </li>
                                          ))}
                                      </ul>
                                    )}
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <>
                            <div
                              className={`${styles.dropdownCol} ${
                                styles[
                                  `dropdownCol--${Math.ceil(
                                    activeItem.children.edges.filter(
                                      (child) => !!child.node.url && child.node.style !== 'box'
                                    ).length / 6
                                  )}-cols`
                                ]
                              }`}
                            >
                              <div className={styles.dropdownItem}>
                                <div
                                  className={`${styles.dropdownItemChildren} ${
                                    styles[
                                      `dropdownItemChildren--${Math.ceil(
                                        activeItem.children.edges.filter(
                                          (child) => !!child.node.url && child.node.style !== 'box'
                                        ).length / 6
                                      )}-cols`
                                    ]
                                  }`}
                                >
                                  {activeItem?.children?.edges
                                    ?.filter(
                                      (child) => !!child.node.url && child.node.style !== 'box'
                                    )
                                    .map((child, index) => (
                                      <div key={child.node.pk}>
                                        {child.node.style !== 'all' ? (
                                          <Link legacyBehavior href={child.node.url || '#'}>
                                            <a
                                              className={styles.dropdownItemChild}
                                              onClick={() =>
                                                sendGTMData({
                                                  event: 'GAevent',
                                                  eventCategory: 'menu',
                                                  eventAction: 'second_level_menu',
                                                  eventLabel: `${activeItem.name?.toGTMFormat()} | ${child.node.name?.toGTMFormat()}`,
                                                })
                                              }
                                              aria-label={child.node.name}
                                              role="menuitem"
                                            >
                                              {child.node.name}
                                            </a>
                                          </Link>
                                        ) : (
                                          <Button
                                            label={t('Vedi tutti')}
                                            href={activeItem?.url}
                                            variant={'ghost'}
                                            rightIcon={<ArrowRight />}
                                          />
                                        )}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                            {activeItem?.children?.edges
                              ?.filter((child) => child.node.style === 'box')
                              .map((child) => (
                                <div
                                  className={`${styles.dropdownCol} ${styles['dropdownCol--auto']}`}
                                >
                                  <TextCtaBox
                                    item={child.node}
                                    size="sm"
                                    onClick={() =>
                                      sendGTMData({
                                        event: 'GAevent',
                                        eventCategory: 'menu',
                                        eventAction: 'banner',
                                        eventLabel: child?.node?.name?.toGTMFormat(),
                                      })
                                    }
                                  />
                                </div>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </WContainer>
                </div>
              )}
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default Nav
