import React from 'react'
import { WContainer, Breadcrumbs, Button } from '@next-nx/shared-ui/atoms'
import { Page, SimpleFooter, SimpleHeader } from '@next-nx/shared-ui/organisms'
import styles from './Custom404Template.module.sass'
import { useSite, useTrans } from '@next-nx/hooks'
import { BANCA } from 'libs/shared-ui/utils/settings'

const Custom404Template = () => {
  const t = useTrans()
  const site = useSite()

  const pagina = {
    titolo: 'page404__titolo',
    descrizione: 'page404__descrizione',
    breadcrumbs: [{ titolo: 'Homepage', url: '/' }, { titolo: '404' }],
  }

  return (
    <Page
      pagina={pagina}
      item={{}}
      header={<SimpleHeader />}
      footer={<SimpleFooter className={styles.footer} />}
      pageWrapClassName={styles.simplePageWrap}
    >
      <main>
        <div className={`${styles.headBg}`}>
          <WContainer>
            {site !== BANCA && <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />}
          </WContainer>
        </div>
        <div className={`bg-white ${site === BANCA ? styles['content--no-breadcrumbs'] : ''}`}>
          <WContainer>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: t(pagina?.titolo) || '',
              }}
            />
            <div
              className={styles.descrizione}
              dangerouslySetInnerHTML={{
                __html: t(pagina?.descrizione) || '',
              }}
            />
          </WContainer>
        </div>
        <div className={`${styles.homeBtnContainer} bg-grey`}>
          <Button
            label={t('Torna alla home')}
            href={site === BANCA ? 'https://www.credit-agricole.it/' : '/'}
          />
        </div>
      </main>
    </Page>
  )
}

export default Custom404Template
