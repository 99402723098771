import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { Button, WImage } from '@next-nx/shared-ui/atoms'
import styles from './HeroSlider.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'
import { useIsMobile } from '@next-nx/hooks'
import { useTrans } from '@next-nx/hooks'
import { A11y, Navigation, Pagination } from 'swiper/modules'

interface Props {
  item?: MenuType
  className?: string
}

const HeroSlider = (props: Props) => {
  const { item = undefined, className = '' } = props

  const isMobile = useIsMobile()

  const t = useTrans()

  const [activeIndex, setActiveIndex] = useState(0)

  const imagesSwiperSettings = {
    navigation: {
      nextEl: '.hero-custom-button-next',
      prevEl: '.hero-custom-button-prev',
    },
    loop: true,
    a11y: {
      enabled: true,
      prevSlideMessage: t('Slide precedente'),
      nextSlideMessage: t('Slide successiva'),
      paginationBulletMessage: 'Vai alla slide {{index}}',
    },
    modules: [Navigation, A11y],
    onSlideChange: (swiper: any) => setActiveIndex(swiper.activeIndex),
  }

  const contentSwiperSettings = {
    pagination: item?.children?.edges?.length === 1 ? false : { clickable: true },
    navigation: {
      nextEl: '.hero-custom-button-next',
      prevEl: '.hero-custom-button-prev',
    },
    loop: true,
    className: `${styles.slider} swiper--pagination swiper--pagination--white`,
    a11y: {
      enabled: true,
      prevSlideMessage: t('Slide precedente'),
      nextSlideMessage: t('Slide successiva'),
      paginationBulletMessage: 'Vai alla slide {{index}}',
    },
    modules: [Pagination, Navigation, A11y],
    onSlideChange: (swiper: any) => setActiveIndex(swiper.activeIndex),
  }

  return item && !!item.children?.edges?.length ? (
    <div className={`${styles.heroSlider} ${className ? ` ${className}` : ''}`}>
      <div className={styles.imagesSlider} role="region">
        <Swiper {...imagesSwiperSettings} aria-hidden>
          {item.children.edges.map((child, i) => (
            <SwiperSlide key={child.node.pk}>
              <div className={styles.imageBox}>
                <WImage
                  src={isMobile ? child.node.mainImageResponsive : child.node.mainImage}
                  maxWidth={1920}
                  maxHeight={575}
                  layout="intrinsic"
                  objectFit="cover"
                  priority={i === 0 ? true : false}
                  loading={i === 0 ? 'eager' : 'lazy'}
                  key={`hero-slide-${i}${isMobile ? '-mobile' : ''}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.contentSlider}>
        {item?.children?.edges?.length > 1 && (
          <>
            <button
              type="button"
              className="hero-custom-button-prev custom-button-prev custom-button-prev--white"
              aria-label={t('precedente')}
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'slider',
                  eventAction: 'previous',
                  eventLabel: 'homepage',
                })
              }
            >
              <ArrowLeft />
            </button>
            <button
              type="button"
              className="hero-custom-button-next custom-button-next custom-button-prev--white"
              aria-label={t('successivo')}
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'slider',
                  eventAction: 'next',
                  eventLabel: 'homepage',
                })
              }
            >
              <ArrowRight />
            </button>
          </>
        )}
        <Swiper {...contentSwiperSettings} role="presentation">
          {item.children.edges.map((child, i) => (
            <SwiperSlide key={child.node.pk}>
              <div className={styles.content}>
                {!!child.node.name && <h2 className={styles.title}>{child.node.name}</h2>}
                {!!child.node.description && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: child.node.description }}
                  />
                )}
                {!!child.node.ctaLabel && !!child.node.url && (
                  <div className={styles.ctaBox}>
                    <Button
                      label={child.node.ctaLabel}
                      variant="tertiary2"
                      href={child.node.url}
                      onClick={() =>
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'slider',
                          eventAction: 'scopri_offerta',
                          eventLabel: 'homepage',
                        })
                      }
                      tabIndex={i === activeIndex ? 0 : -1}
                    />
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default HeroSlider
