import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y } from 'swiper/modules'
import { useSite, useTrans } from '@next-nx/hooks'
import { Alert, Filters, Search } from '@next-nx/shared-ui/icons'
import { Chip, Loader, NotificationBar, Pagination, Button } from '@next-nx/shared-ui/atoms'
import { FascicoloCard } from '@next-nx/shared-ui/molecules'
import styles from './ArchivioFascicoliListing.module.sass'
import { useCategorieFascicoliQuery as useCategorieFascicoliQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useFascicoliQuery as useFascicoliQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useCategorieFascicoliQuery as useCategorieFascicoliQueryVita } from '@next-nx/shared-graphql/vita'
import { useFascicoliQuery as useFascicoliQueryVita } from '@next-nx/shared-graphql/vita'
import { useCatalogoFondiQuery as useCatalogoFondiQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useCatalogoFondiQuery as useCatalogoFondiQueryVita } from '@next-nx/shared-graphql/vita'
import { ASSICURAZIONI, FASCICOLI_LISTING_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  className?: string
  isCatalogoFondi?: boolean
}

const ArchivioFascicoliListing = (props: Props) => {
  const { className = '', isCatalogoFondi = false } = props

  const t = useTrans()

  const site = useSite()
  const useCategorieFascicoliQuery =
    site === ASSICURAZIONI
      ? useCategorieFascicoliQueryAssicurazioni
      : useCategorieFascicoliQueryVita
  const useFascioliQuery = isCatalogoFondi
    ? site === ASSICURAZIONI
      ? useCatalogoFondiQueryAssicurazioni
      : useCatalogoFondiQueryVita
    : site === ASSICURAZIONI
    ? useFascicoliQueryAssicurazioni
    : useFascicoliQueryVita

  const [page, setPage] = useState<number>(1)
  const [filtriAttivi, setFiltriAttivi] = useState<string[]>([])

  const toggleFiltro = (slug: string | null | undefined) => {
    let newFiltriAttivi: string[] = []

    if (slug) {
      if (filtriAttivi.indexOf(slug) > -1) newFiltriAttivi = filtriAttivi.filter((x) => x !== slug)
      else newFiltriAttivi = filtriAttivi.concat([slug])
    }

    setFiltriAttivi(newFiltriAttivi)
    setPage(1)

    refetch({
      offset: 0,
      first: FASCICOLI_LISTING_ITEMS_PER_PAGE,
      ...(isCatalogoFondi ? {} : { categoria_Slug_In: newFiltriAttivi.join(',') }),
    })
  }

  const handleChangePage = (value: number) => {
    setPage(value)

    refetch({
      offset: (value - 1) * FASCICOLI_LISTING_ITEMS_PER_PAGE,
      first: FASCICOLI_LISTING_ITEMS_PER_PAGE,
      ...(isCatalogoFondi ? {} : { categoria_Slug_In: filtriAttivi.join(',') }),
    })
  }

  const [searchInput, setSearchInput] = useState<string | null | undefined>()

  const handleSearchSubmit = (value: string | null | undefined) => {
    setPage(1)

    refetch({
      offset: 0,
      first: FASCICOLI_LISTING_ITEMS_PER_PAGE,
      titolo_Icontains: value ? value : undefined,
    })
  }

  const { data: categorieFascicoliData } = useCategorieFascicoliQuery()

  const { data, loading, error, refetch } = useFascioliQuery({
    variables: {
      offset: 0,
      first: FASCICOLI_LISTING_ITEMS_PER_PAGE,
    },
  })
  const totalCount = isCatalogoFondi ? data?.catalogoFondi?.totalCount : data?.fascicoli?.totalCount
  const listingData = isCatalogoFondi ? data?.catalogoFondi : data?.fascicoli

  return (
    <div className={`${styles.magazineListing} ${className ? ` ${className}` : ''}`}>
      {!isCatalogoFondi && !!categorieFascicoliData?.categorieFascicoli?.edges.length && (
        <>
          <div className={styles.filtersList}>
            {categorieFascicoliData.categorieFascicoli.edges.map((filtro) =>
              filtro?.node && filtro.node.numFascicoli ? (
                <Chip
                  key={filtro.node.pk}
                  label={filtro.node.nome}
                  onClick={() => {
                    toggleFiltro(filtro.node?.slug)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'filtri',
                      eventAction: filtro?.node?.nome?.toGTMFormat(),
                      eventLabel: isCatalogoFondi ? 'catalogo_fondi' : 'fascicoli',
                    })
                  }}
                  active={filtro.node.slug ? filtriAttivi.indexOf(filtro.node.slug) > -1 : false}
                />
              ) : null
            )}
          </div>
          <div className={styles.filtersSlider}>
            <Swiper
              spaceBetween={16}
              slidesPerView="auto"
              watchOverflow
              modules={[A11y]}
              a11y={{
                enabled: true,
                prevSlideMessage: t('Slide precedente'),
                nextSlideMessage: t('Slide successiva'),
                paginationBulletMessage: 'Vai alla slide {{index}}',
              }}
            >
              {categorieFascicoliData.categorieFascicoli.edges.map((filtro) =>
                filtro?.node && filtro.node.numFascicoli ? (
                  <SwiperSlide key={filtro.node.pk}>
                    <Chip
                      label={filtro.node.nome}
                      onClick={() => {
                        toggleFiltro(filtro.node?.slug)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'filtri',
                          eventAction: filtro?.node?.nome?.toGTMFormat(),
                          eventLabel: isCatalogoFondi ? 'catalogo_fondi' : 'fascicoli',
                        })
                      }}
                      active={
                        filtro.node.slug ? filtriAttivi.indexOf(filtro.node.slug) > -1 : false
                      }
                    />
                  </SwiperSlide>
                ) : null
              )}
            </Swiper>
          </div>
        </>
      )}
      <div className={styles.advanced}>
        <h2 className={styles.title}>
          <strong>{t('Risultati')}</strong> ({totalCount})
        </h2>
        <div className={styles.search}>
          <label className={styles.label} id="archivio_fascicoli_search_label">
            {isCatalogoFondi ? t('Cerca un fondo...') : t('Cerca un fascicolo...')}
          </label>
          <input
            type="text"
            aria-labelledby="archivio_fascicoli_search_label"
            placeholder={isCatalogoFondi ? t('Cerca un fondo...') : t('Cerca un fascicolo...')}
            onChange={(e) => {
              const value = e.target.value
              setSearchInput(value)
              if (!value) {
                // reset
                handleSearchSubmit(value)
              }
            }}
            className={styles.input}
            autoComplete="off"
          />
          <button
            type="submit"
            onClick={() => {
              handleSearchSubmit(searchInput)
              sendGTMData({
                event: 'GAevent',
                eventCategory: isCatalogoFondi ? 'search_catalogo_fondi' : 'search_fascicoli',
                eventAction: searchInput?.toGTMFormat(),
                eventLabel: isCatalogoFondi ? 'catalogo_fondi' : 'fascicoli',
              })
            }}
            className={styles.submit}
            aria-label={t('Cerca')}
          >
            <Search />
          </button>
        </div>
      </div>
      {loading && !data ? (
        <Loader className={styles.loader} />
      ) : error ? (
        <div className={styles.error}>
          <NotificationBar variant="alert" label={error.message} icon={<Alert />} />
        </div>
      ) : (
        <>
          <div className={styles.list}>
            {listingData?.edges.map(
              (fascicolo) =>
                fascicolo?.node && (
                  <div className={styles.item} key={fascicolo.node.pk}>
                    <FascicoloCard fascicolo={fascicolo.node} isCatalogoFondi={isCatalogoFondi} />
                  </div>
                )
            )}
          </div>
          <Pagination
            currentPage={page}
            totalCount={totalCount ? totalCount : 0}
            pageSize={FASCICOLI_LISTING_ITEMS_PER_PAGE}
            onPageChange={(page: number) => handleChangePage(page)}
            className={styles.pagination}
          />
        </>
      )}
    </div>
  )
}

export default ArchivioFascicoliListing
