import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper as SwiperType } from 'swiper'
import { useFindMenu, useSite, useTrans, useUtente } from '@next-nx/hooks'
import { scrollTo } from '@next-nx/utils/safe'
import { Breadcrumbs, WContainer } from '@next-nx/shared-ui/atoms'
import {
  AllegatiBox,
  AppBox,
  ContentBox,
  FilledTextBox,
  OutlineTextCtaBox,
  TextCtaBox,
} from '@next-nx/shared-ui/molecules'
import {
  LineeSection,
  MotiviSection,
  Page,
  ProdottiSection,
  PreventivatoreModal,
  AppModal,
  PreventivatoreFastQuoteModal,
  PreventivatoreFastQuoteLoader,
} from '@next-nx/shared-ui/organisms'
import styles from './ProdottoTemplate.module.sass'
import FaqSection from '../../organisms/FaqSection/FaqSection'
import ProdottoHead from '../../organisms/ProdottoHead/ProdottoHead'
import { MenuType, ProdottoType } from '@next-nx/utils/types'
import { getIcon } from '@next-nx/utils/icons'
import { VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'

interface Props {
  prodotto: ProdottoType
  pagina?: {
    breadcrumbs: {
      titolo: string
      url?: string
    }[]
  }
}

const ProdottoTemplate = (props: Props) => {
  const { prodotto, pagina = undefined } = props

  const t = useTrans()
  const { utente } = useUtente()
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const menuRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<HTMLDivElement[]>([])

  const [menuActive, setMenuActive] = useState<string | null>('descrizione')
  const [appModalOpen, setAppModalOpen] = useState<boolean>(false)
  const [appModal, setAppModal] = useState<MenuType | undefined>(undefined)
  const [preventivatoreModalOpen, setPreventivatoreModalOpen] = useState<boolean>(false)
  const [selectedLineePreventivatore, setSelectedLineePreventivatore] = useState<number[]>([])
  const [fastQuoteSubmitting, setFastQuoteSubmitting] = useState(false)

  const addSectionRef = (ref: HTMLDivElement) => {
    if (ref) {
      if (sectionRefs.current.filter((x) => x.id === ref.id).length > 0)
        sectionRefs.current = sectionRefs.current.map((x) => (x.id === ref.id ? ref : x))
      else sectionRefs.current.push(ref)
    }
  }

  const site = useSite()

  const prodottoSharedMenu = useFindMenu('prodotto')

  const topBoxesProdottoMenu = useFindMenu('top-boxes', prodotto.menuItems)
  const topBoxesSharedMenu = useFindMenu('top-boxes', prodottoSharedMenu)
  const topBoxesMenu = topBoxesProdottoMenu || topBoxesSharedMenu

  const descrizioneBoxesMenu = useFindMenu('card_descrizione', prodotto.menuItems)

  const offertaMenu = useFindMenu('offerta', prodotto.menuItems)

  const motiviMenu = useFindMenu('motivi', prodotto.menuItems)

  const vantaggiMenu = useFindMenu('vantaggi', prodotto.menuItems)

  const lineeMenu = useFindMenu('linee', prodotto.menuItems)

  const faqProdottoMenu = useFindMenu('faq', prodotto.menuItems)
  const faqSharedMenu = useFindMenu('faq', prodottoSharedMenu)
  const faqMenu = faqProdottoMenu || faqSharedMenu

  const areaClientiProdottoMenu = useFindMenu('area_clienti', prodotto.menuItems)
  const areaClientiSharedMenu = useFindMenu('area_clienti', prodottoSharedMenu)
  const areaClientiMenu = areaClientiProdottoMenu || areaClientiSharedMenu

  const fondiMenu = site === VITA ? useFindMenu('fondi', prodotto.menuItems) : null

  const allegatiMenu = useFindMenu('allegati', prodotto.menuItems)

  const appProdottoMenu = useFindMenu('app', prodotto.menuItems)
  const appSharedMenu = useFindMenu('app', prodottoSharedMenu)
  const appMenu = appProdottoMenu || appSharedMenu

  const disclaimerMenu = useFindMenu('disclaimer', prodotto.menuItems)

  const [menuSwiper, setMenuSwiper] = useState<SwiperType>()
  const [scrolling, setScrolling] = useState(false)

  const menuItems = ([] as { pk?: string; name?: string }[])
    .concat(descrizioneBoxesMenu ? [{ pk: 'descrizione', name: 'Descrizione' }] : [])
    .concat(offertaMenu ? [{ pk: 'offerta', name: 'Che cosa offre' }] : [])
    .concat(vantaggiMenu ? [{ pk: 'vantaggi', name: 'I Vantaggi' }] : [])
    .concat(lineeMenu && prodotto.linee?.length > 0 ? [{ pk: 'linee', name: 'Linee' }] : [])
    //.concat([{ pk: 'garanzie', name: 'Garanzie' }])
    //.concat([{ pk: 'condizioni', name: 'Condizioni' }])
    .concat(
      faqMenu && prodotto.faqs?.length > 0 ? [{ pk: 'faqs', name: 'Domande e Risposte' }] : []
    )
    .concat(fondiMenu ? [{ pk: 'fondi', name: 'Andamento fondi' }] : [])
    .concat(allegatiMenu ? [{ pk: 'allegati', name: 'Allegati' }] : [])
    .concat(appMenu ? [{ pk: 'app', name: 'App' }] : [])

  const handleScroll = () => {
    if (menuRef.current) {
      if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 80 : 0) - 80)
        document.body.classList.add('prodotto-menu-sticky')
      else document.body.classList.remove('prodotto-menu-sticky')
    }

    // Calcolo se sono all'altezza di un box del menu
    const scrollOffset = window.scrollY + (toolbarPresente ? 80 : 0) + 80 + 80 + 10 // + header + menu + correzzione
    const match = sectionRefs.current.filter(
      (x) => scrollOffset >= x.offsetTop && scrollOffset <= x.offsetTop + x.clientHeight
    )
    // Potrebbero esserci box sovrapposti (es. linee che va sopra a vantaggi), prendo l'ultimo match
    setMenuActive(match.length > 0 ? match[match.length - 1].id?.replace('menu_', '') : null)
  }

  useEffect(() => {
    if (menuActive && !scrolling) {
      const index = menuItems.findIndex((item) => item.pk === menuActive)
      if (index !== -1) menuSwiper?.slideTo(index)
    }
  }, [menuActive])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const focusToElement = (id: string | number) => {
    const elem = document.querySelector(`#menu_${id.toString() || ''}`)
    if (elem && !scrolling) {
      elem.focus()
    }
  }

  // const findPercentilePosition = (items: any, item: string) => {
  //   const index = items.indexOf(item)

  //   if (index === -1) {
  //     return '0%'
  //   }

  //   const percentile = items.length > 1 ? ((index / (items.length - 1)) * 100) : 0

  //   return percentile.toString() + '%'
  // }

  // const sectionIds = menuItems?.map((child) => child?.pk)

  // const [scrollBarWidth, setScrollBarWidth] = useState<string>('0%')
  // const [scrollBarVisible, setScrollBarVisible] = useState<boolean>(false)

  // useEffect(() => {
  //   if (menuActive) {
  //     setScrollBarWidth(findPercentilePosition(sectionIds, menuActive))
  //   }
  // }, [menuActive])

  // const checkIfScrollNeeded = () => {
  //   const swiperMenu = document.getElementById('swiper-menu')
  //   if (swiperMenu) {
  //     const menuWidth = swiperMenu.scrollWidth
  //     const screenWidth = window.innerWidth
  //     return menuWidth > screenWidth
  //   }
  //   return false
  // };

  // useEffect(() => {
  //   if (checkIfScrollNeeded()) {
  //     setScrollBarVisible(true)
  //   }
  // }, [])

  return prodotto ? (
    <Page
      pagina={pagina}
      item={prodotto}
      contentClassName={styles.prodottoContent}
      recaptchaRequired={prodotto.preventivatore}
      className={styles.root}
    >
      <main>
        <WContainer>
          {pagina?.breadcrumbs && (
            <Breadcrumbs breadcrumbs={pagina.breadcrumbs} className={styles.prodottoBreadcrumbs} />
          )}
        </WContainer>
        <ProdottoHead
          prodotto={prodotto}
          selectedLineePreventivatore={selectedLineePreventivatore}
          setSelectedLineePreventivatore={setSelectedLineePreventivatore}
          setPreventivatoreModalOpen={setPreventivatoreModalOpen}
          fastQuoteSubmitting={fastQuoteSubmitting}
          setFastQuoteSubmitting={setFastQuoteSubmitting}
          className={styles.head}
        />
        {topBoxesMenu && topBoxesMenu.children?.edges.length && (
          <WContainer variant="sm">
            <div className={styles.topBoxes}>
              {topBoxesMenu.children.edges.map((child) => (
                <OutlineTextCtaBox
                  key={child.node.pk}
                  item={child.node}
                  onClick={() =>
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'cta',
                      eventAction: child.node?.ctaLabel?.toGTMFormat(),
                    })
                  }
                />
              ))}
            </div>
          </WContainer>
        )}
        <div className={styles.menuSectionRef} ref={menuRef}>
          <div className={styles.menuSection}>
            <WContainer>
              {/* DESKTOP */}
              <div className={`${styles.menu} ${styles['menu--desktop']}`}>
                <Swiper
                  id="swiper-menu"
                  slidesPerView="auto"
                  onSwiper={(swiper) => setMenuSwiper(swiper)}
                  modules={[A11y]}
                  a11y={{
                    enabled: true,
                    prevSlideMessage: t('Slide precedente'),
                    nextSlideMessage: t('Slide successiva'),
                    paginationBulletMessage: 'Vai alla slide {{index}}',
                  }}
                >
                  {menuItems.map((child, index) => (
                    <SwiperSlide key={child.pk}>
                      <a
                        key={child.pk}
                        href={`#menu_${child.pk}`}
                        className={`${styles.menuItem} ${
                          menuActive === child.pk ? styles['menuItem--active'] : ''
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          focusToElement(child.pk ? child.pk : '')
                          setScrolling(true)
                          scrollTo(sectionRefs.current[index], toolbarPresente, 80) // Offset: 80 di menu
                          setTimeout(() => setScrolling(false), 1000)
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'sotto_menu',
                            eventAction: child.name?.toGTMFormat() || '',
                          })
                        }}
                        aria-labelledby={`#menu_${child.pk}_label`}
                      >
                        <span
                          id={`#menu_${child.pk}_label`}
                          dangerouslySetInnerHTML={{ __html: child.name || '' }}
                        />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className={`${styles.menu} ${styles['menu--mobile']}`}>
                {/* MOBILE */}
                <Swiper
                  id="swiper-menu-mobile"
                  spaceBetween={0}
                  onSwiper={(swiper) => setMenuSwiper(swiper)}
                  slidesPerView={1}
                  modules={[A11y, Navigation]}
                  navigation={{ enabled: true }}
                  a11y={{
                    enabled: true,
                    prevSlideMessage: t('Slide precedente'),
                    nextSlideMessage: t('Slide successiva'),
                    paginationBulletMessage: 'Vai alla slide {{index}}',
                  }}
                  className={styles.swiperMobile}
                  slideNextClass={styles.btnNext}
                  slidePrevClass={styles.btnPrev}
                >
                  {menuItems.map((child, index) => (
                    <SwiperSlide key={child.pk}>
                      <a
                        key={child.pk}
                        href={`#menu_${child.pk}`}
                        className={`${styles.menuItem} ${
                          menuActive === child.pk ? styles['menuItem--active'] : ''
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          focusToElement(child.pk ? child.pk : '')
                          setScrolling(true)
                          scrollTo(sectionRefs.current[index], toolbarPresente, 80) // Offset: 80 di menu
                          setTimeout(() => setScrolling(false), 1000)
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'sotto_menu',
                            eventAction: child.name?.toGTMFormat() || '',
                          })
                        }}
                        aria-labelledby={`#menu_${child.pk}_label`}
                      >
                        <span
                          id={`#menu_${child.pk}_label`}
                          dangerouslySetInnerHTML={{ __html: child.name || '' }}
                        />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </WContainer>
            {/* {scrollBarVisible && <div className={styles.progressBar}>
              <div style={{ width: scrollBarWidth }}></div>
            </div>} */}
          </div>
        </div>
        {descrizioneBoxesMenu && descrizioneBoxesMenu.children?.edges.length && (
          <section
            id="menu_descrizione"
            ref={addSectionRef}
            className={styles.descrizione}
            tabIndex={0}
          >
            <WContainer variant="sm">
              <div className={styles.descrizioneBoxes}>
                {descrizioneBoxesMenu.name && (
                  <h2
                    dangerouslySetInnerHTML={{ __html: descrizioneBoxesMenu.name }}
                    className={styles.descrizioneTitle}
                  />
                )}
                <div className={styles.descrizioneBoxesLayout}>
                  {descrizioneBoxesMenu.children.edges.map((item) => (
                    <FilledTextBox key={item.node.pk} item={item.node} />
                  ))}
                </div>
              </div>
            </WContainer>
          </section>
        )}
        {offertaMenu && offertaMenu.children?.edges.length && (
          <section id="menu_offerta" ref={addSectionRef} className="bg-grey" tabIndex={0}>
            <WContainer>
              <div className={styles.offerta}>
                {offertaMenu.name && (
                  <h2
                    dangerouslySetInnerHTML={{ __html: offertaMenu.name }}
                    className={styles.offertaTitle}
                  />
                )}
                <div className={styles.offertaLayout}>
                  {offertaMenu.children.edges.map((item) => (
                    <div key={item.node.pk} className={styles.offertaItem}>
                      {getIcon(item.node.style) && (
                        <span className={styles.offertaItemIcon}>
                          {getIcon(item.node.style, 'offertamenu_' + item.node.pk.toString())}
                        </span>
                      )}
                      {item.node.description && (
                        <span
                          className={styles.offertaItemContent}
                          dangerouslySetInnerHTML={{ __html: item.node.description }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </WContainer>
          </section>
        )}
        {motiviMenu && (
          <section id="menu_motivi" className="bg-grey-white" tabIndex={0}>
            <MotiviSection item={motiviMenu} />
          </section>
        )}
        {vantaggiMenu && (
          <section id="menu_vantaggi" ref={addSectionRef} className={styles.vantaggi} tabIndex={0}>
            <ContentBox item={vantaggiMenu} />
          </section>
        )}
        {!!prodotto.linee && !!lineeMenu && (
          <section
            id="menu_linee"
            ref={addSectionRef}
            className={`${styles.linee} bg-grey`}
            tabIndex={0}
          >
            <LineeSection
              title={lineeMenu.name}
              linee={prodotto.linee}
              note={lineeMenu.description}
            />
          </section>
        )}
        {!!prodotto.faqs && !!faqMenu && (
          <section id="menu_faqs" ref={addSectionRef} className={styles.faqs} tabIndex={0}>
            <WContainer>
              <FaqSection faqMenu={faqMenu} faqs={prodotto.faqs} />
            </WContainer>
          </section>
        )}
        {!!areaClientiMenu && (
          <section className={styles.areaClienti} tabIndex={0}>
            <WContainer>
              <TextCtaBox item={areaClientiMenu} />
            </WContainer>
          </section>
        )}
        {fondiMenu && fondiMenu.children?.edges.length && (
          <section id="menu_fondi" ref={addSectionRef} className={styles.fondi} tabIndex={0}>
            <WContainer>
              {fondiMenu.name && (
                <h2
                  className={styles.fondiTitle}
                  dangerouslySetInnerHTML={{ __html: fondiMenu.name }}
                />
              )}
              <div className={styles.fondiList}>
                {fondiMenu.children.edges.map((child) => (
                  <OutlineTextCtaBox
                    key={child.node.pk}
                    item={child.node}
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'andamento_fondi',
                        eventAction: 'scopri_andamento_dei_tuoi_fondi',
                        eventLabel: child.node?.url?.toGTMFormat(),
                      })
                    }
                    onChildrenClick={child.node.children?.edges.map((child) => {
                      return {
                        pk: child.node.pk,
                        onClick: () =>
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'andamento_fondi',
                            eventAction: 'scopri_andamento_dei_tuoi_fondi',
                            eventLabel: child.node?.url?.toGTMFormat(),
                          }),
                      }
                    })}
                  />
                ))}
              </div>
            </WContainer>
          </section>
        )}
        {!!allegatiMenu && (
          <section id="menu_allegati" ref={addSectionRef} className={styles.allegati} tabIndex={0}>
            <WContainer>
              <AllegatiBox item={allegatiMenu} />
            </WContainer>
          </section>
        )}
        {!!appMenu && (
          <section
            id="menu_app"
            ref={addSectionRef}
            className={`${styles.app} bg-white-grey`}
            tabIndex={0}
          >
            <WContainer variant="lg">
              <AppBox item={appMenu} controlled />
            </WContainer>
          </section>
        )}
        {!!prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length > 0 && (
          <section className={`${styles.correlati} bg-grey`} tabIndex={0}>
            <ProdottiSection
              item={{
                description: t('Prodotti <strong>correlati</strong>'),
              }}
              prodotti={
                prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length
                  ? prodotto.prodottiCorrelati.edges.map((p) => {
                      return p.node
                    })
                  : []
              }
              whatIs="prodottiCorrelati"
            />
          </section>
        )}
        {!!disclaimerMenu && (
          <section className={styles.disclaimer} tabIndex={0}>
            <WContainer variant="sm">
              {disclaimerMenu.description && (
                <div
                  className={`${styles.disclaimerContent} custom-content`}
                  dangerouslySetInnerHTML={{ __html: disclaimerMenu.description }}
                />
              )}
            </WContainer>
          </section>
        )}
        {isFastQuoteCasa(prodotto) || isFastQuoteAuto(prodotto) ? (
          <>
            <PreventivatoreFastQuoteModal
              prodotto={prodotto}
              open={!!preventivatoreModalOpen}
              onClose={() => setPreventivatoreModalOpen(false)}
              submitting={fastQuoteSubmitting}
              setSubmitting={setFastQuoteSubmitting}
              variant={'prodotto'}
            />
            <PreventivatoreFastQuoteLoader open={fastQuoteSubmitting} prodotto={prodotto} />
          </>
        ) : (
          <PreventivatoreModal
            title={t('Consulta il nostro <strong>preventivatore</strong>')}
            prodotto={prodotto}
            open={preventivatoreModalOpen}
            setOpen={setPreventivatoreModalOpen}
            selectedLinee={selectedLineePreventivatore}
          />
        )}
        <AppModal app={appModal} open={appModalOpen} setOpen={setAppModalOpen} />
      </main>
    </Page>
  ) : (
    <></>
  )
}

export default ProdottoTemplate
