import React, { useEffect } from 'react'
import { QuotazioneAutoType, QuotazioneType } from '@next-nx/utils/types'
import {
  Breadcrumbs,
  Button,
  MainDescription,
  MainTitle,
  WContainer,
} from '@next-nx/shared-ui/atoms'
import { Page, ProdottiSection, SimpleHeader, SimpleFooter } from '@next-nx/shared-ui/organisms'
import styles from './ThankYouPageTemplate.module.sass'
import { useSite, useTrans } from '@next-nx/hooks'
import { BANCA } from 'libs/shared-ui/utils/settings'
import { getGaranziaDetails, sendGTMData } from 'libs/shared-ui/utils/gtm'

interface Props {
  pagina?: any
  quotazione?: QuotazioneType | QuotazioneAutoType
  variant?: 'thank-you' | 'thank-you-no-cliente' | 'error' | 'error-cf'
}

const ThankYouPageTemplate = (props: Props) => {
  const { variant = 'thank-you', pagina = undefined, quotazione = undefined } = props

  const t = useTrans()
  const site = useSite()

  const handleGtm = () => {
    if (quotazione) {
      if (variant === 'thank-you') {
        sendGTMData({
          event: 'GAevent',
          eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
          eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
          eventLabel: 'ok',
        })
      } else if (variant === 'thank-you-no-cliente') {
        sendGTMData({
          event: 'GAevent',
          eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
          eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
          eventLabel: 'ok_no_cliente',
        })
      }

      if (variant === 'thank-you') {
        sendGTMData({
          event: 'purchase',
          ecommerce: {
            currencyCode: 'eur',
            purchase: {
              actionField: {
                id: quotazione.idQuotazione,
                affiliation: 'no_value',
                revenue: quotazione?.importoLordoAnnuoScontato
                  ? quotazione.importoLordoAnnuoScontato.toFixed(2)
                  : quotazione.importoLordoAnnuo?.toFixed(2),
                tax: '', // non disponibile
                shipping: '0.00',
              },
              products: quotazione.garanzieSelezionate?.map((garanzia) =>
                getGaranziaDetails(garanzia, site, quotazione, garanzia.pacchetto?.titolo || '')
              ),
            },
          },
        })
      }
    }
  }

  useEffect(() => {
    handleGtm()
  }, [])

  return (
    <Page
      pagina={pagina}
      item={{}}
      header={<SimpleHeader />}
      footer={<SimpleFooter />}
      contentClassName={
        variant === 'thank-you' &&
        quotazione?.prodotto?.prodottiCorrelati &&
        quotazione?.prodotto?.prodottiCorrelati?.edges?.length > 0
          ? styles.thankYouPageContent
          : ''
      }
      pageWrapClassName={styles.thankYouPageWrap}
    >
      <main>
        <div className="bg-white">
          <WContainer>
            {site !== BANCA && <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />}
            <div
              className={`${styles.content} ${
                site === BANCA ? styles['content--no-breadcrumbs'] : ''
              }`}
            >
              <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
              <MainDescription
                html={pagina?.descrizione?.replace('{email}', quotazione?.email)}
                className={styles.description}
              />
            </div>
          </WContainer>
        </div>
        {variant === 'thank-you' &&
          quotazione?.prodotto?.prodottiCorrelati &&
          quotazione?.prodotto?.prodottiCorrelati?.edges?.length > 0 && (
            <div className={`bg-grey ${styles.bottom}`}>
              <ProdottiSection
                item={{
                  pk: 1,
                  description: t('Scopri le altre nostre <strong>soluzioni</strong>'),
                }}
                prodotti={quotazione?.prodotto?.prodottiCorrelati?.edges.map((x) => x.node)}
              />
              <WContainer>
                <div className={styles.backBox}>
                  <Button label={t('Torna alla home')} href="/" />
                </div>
              </WContainer>
            </div>
          )}
        {variant === 'thank-you-no-cliente' && (
          <WContainer>
            <div className={styles.diventaCliente}>
              <Button
                label={t('Diventa cliente')}
                href="https://conti.credit-agricole.it/"
                onClick={() => {
                  quotazione &&
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                      eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'typ_diventa_cliente',
                    })
                }}
              />
            </div>
          </WContainer>
        )}
        {(variant === 'error' ||
          variant === 'error-cf' ||
          (variant === 'thank-you' && !quotazione?.prodotto?.prodottiCorrelati?.edges?.length)) && (
          <WContainer>
            <div className={styles.backBox}>
              <Button
                label={t('Torna alla home')}
                href={site === BANCA ? 'https://www.credit-agricole.it/' : '/'}
              />
            </div>
          </WContainer>
        )}
      </main>
    </Page>
  )
}

export default ThankYouPageTemplate
