import React, { useContext } from 'react'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { AssistenzaButton, Button, IntroTitle, WImage } from '@next-nx/shared-ui/atoms'
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext'
import styles from './AssistenzaSection.module.sass'
import { getIcon } from '@next-nx/utils/icons'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  item: MenuType
  className?: string
}

const AssistenzaSection = (props: Props) => {
  const { item = undefined, className = '' } = props

  const chatbotCtx = useContext(ChatBotContext)

  return item ? (
    <div className={`${styles.assistenzaSection} ${className ? ` ${className}` : ''}`}>
      <div className={styles.content}>
        {!!item.name && <IntroTitle label={item.name} className={styles.intro} />}
        {!!item.description && (
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: item.description }} />
        )}
        {!!item.children?.edges?.length && (
          <div className={styles.list}>
            {item.children.edges.map((child) => (
              <AssistenzaButton
                key={child.node.pk}
                label={child.node.description || ''}
                icon={getIcon(child.node.style, 'assistenzasection_' + child.node.pk?.toString())}
                newPage={child.node.blank}
                {...(child.node.url
                  ? {
                      url: child.node.url,
                      onClick: () => {
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'box_info',
                          eventAction: 'chiedi_aiuto',
                          eventLabel: child.node.description?.toGTMFormat() || '',
                        })
                      },
                    }
                  : {
                      onClick: () => {
                        chatbotCtx.openChatBot(child.node)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'box_info',
                          eventAction: 'chiedi_aiuto',
                          eventLabel: child.node.description?.toGTMFormat() || '',
                        })
                      },
                    })}
              />
            ))}
          </div>
        )}
        {!!item.ctaLabel && !!item.chatbotUrl && (
          <div className={styles.ctaBox}>
            <Button
              label={item.ctaLabel}
              rightIcon={<ArrowRight />}
              variant="ghost"
              onClick={() => {
                chatbotCtx.openChatBot(item)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'box_info',
                  eventAction: 'chiedi_aiuto',
                  eventLabel: item.ctaLabel?.toGTMFormat(),
                })
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.images}>
        <div className={styles.imageBox}>
          <WImage
            src={item.secondaryImage}
            maxWidth={380}
            maxHeight={440}
            layout="intrinsic"
            objectFit="cover"
          />
        </div>
        <div className={styles.imageBox}>
          <WImage
            src={item.mainImage}
            maxWidth={380}
            maxHeight={440}
            layout="intrinsic"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AssistenzaSection
