import React, { useState, useRef } from 'react'
import Link from 'next/link'
import { useIsMobile, useTrans } from '@next-nx/hooks'
import { ArrowRight, Close, ChevronDown } from '@next-nx/shared-ui/icons'
import styles from './FaqSidebarMenu.module.sass'

interface Props {
  items: {
    pk: number
    titolo: string
    url: string
    active?: boolean
  }[]
  isSearchFaq?: boolean
  handleSelectCategory?: (pk: number) => void
  pkCategoriaSelected?: number
  className?: string
}

const FaqSidebarMenu = (props: Props) => {
  const {
    items = [],
    className = '',
    isSearchFaq = false,
    handleSelectCategory = () => undefined,
    pkCategoriaSelected = null,
  } = props

  const [mobileOpen, setMobileOpen] = useState(false)
  const innerListingRef = useRef<HTMLDivElement>(null)
  const t = useTrans()

  const contentId = `faq_categorie_panel`
  const labelId = 'faq_categorie_label'

  const isMobile = useIsMobile()

  return items.length ? (
    <div
      className={`${styles.faqSidebarMenu} ${
        mobileOpen ? styles['faqSidebarMenu--mobileOpen'] : ''
      } ${className ? ` ${className}` : ''}`}
    >
      <button
        type="button"
        className={styles.mobileAction}
        onClick={() => setMobileOpen(!mobileOpen)}
        aria-controls={contentId}
        aria-expanded={mobileOpen}
      >
        <span id={labelId} className={styles.actionLabel}>
          {t('Categorie')}
        </span>
        <span className={styles.actionIcon}>{mobileOpen ? <Close /> : <ChevronDown />}</span>
      </button>
      <div
        id={contentId}
        aria-labelledby={labelId}
        // aria-expanded={mobileOpen}
        className={styles.listing}
        style={{
          maxHeight:
            innerListingRef?.current && mobileOpen ? innerListingRef.current.clientHeight : 0,
          visibility: !isMobile
            ? 'visible'
            : innerListingRef?.current && mobileOpen
            ? 'visible'
            : 'hidden',
        }}
      >
        <div className={styles.innerListing} ref={innerListingRef}>
          {items.map((item) =>
            isSearchFaq ? (
              pkCategoriaSelected != item.pk ? (
                <button
                  className={`${styles.item} ${
                    item.pk === pkCategoriaSelected ? styles['item--active'] : ''
                  }`}
                  onClick={() => handleSelectCategory(item.pk)}
                  aria-label={'Categoria ' + item?.titolo}
                >
                  <span className={styles.itemTitle}>{item.titolo}</span>
                  <span className={styles.itemIcon}>
                    <ArrowRight />
                  </span>
                </button>
              ) : null
            ) : (
              <Link legacyBehavior key={item.pk} href={item?.url}>
                <a
                  className={`${styles.item} ${item.active ? styles['item--active'] : ''}`}
                  onClick={() => handleSelectCategory(item.pk)}
                >
                  <span className={styles.itemTitle}>{item.titolo}</span>
                  <span className={styles.itemIcon}>
                    <ArrowRight />
                  </span>
                </a>
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FaqSidebarMenu
