import React, { useRef, useState, useEffect } from 'react'
import Link from 'next/link'
import { useSite, useTrans, useFindMenu } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import { ArrowRight, Close, Search } from '@next-nx/shared-ui/icons'
import { WContainer, Loader, NotificationBar, Button, TabGroup } from '@next-nx/shared-ui/atoms'
import styles from './SearchOverlay.module.sass'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { useLazyQuery } from '@apollo/client'
import { SearchDocument as SearchDocumentAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { SearchDocument as SearchDocumentVita } from '@next-nx/shared-graphql/vita'
import { mappingRisultatiSearchOverlay, MODELLI_SEARCH } from 'libs/shared-ui/utils/search'
import { SEARCH_OVERLAY_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  visible: boolean
  setVisible: (v: boolean) => void
  className?: string
}

interface ResultProps {
  pk: number
  name: string
  style: string
  items: {
    pk: number
    text: string
    url: string
  }[]
}

const SearchOverlay = (props: Props) => {
  const { visible = false, setVisible = undefined, className = '' } = props

  const t = useTrans()
  const site = useSite()
  const SearchDocument = site === ASSICURAZIONI ? SearchDocumentAssicurazioni : SearchDocumentVita
  const [getSearch, { data: searchData, error: searchError, loading: searchLoading }] =
    useLazyQuery(SearchDocument)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const resultsPerPage = SEARCH_OVERLAY_ITEMS_PER_PAGE

  const [loading, setLoading] = useState<boolean>(false)
  const [risultati, setRisultati] = useState<ResultProps[]>([])
  const error = false
  const menuTopSearch = useFindMenu('top-search')
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newQuery = e.target.q.value

    if (newQuery && newQuery.trim() !== '' && newQuery !== query) {
      setSubmitted(true)
      setLoading(true)
      setQuery(newQuery)
      sendGTMData({
        event: 'GAevent',
        eventCategory: 'search',
        eventAction: newQuery?.toString()?.toGTMFormat(),
        eventLabel: 'free_search',
      })

      getSearch({
        variables: {
          query: newQuery,
          models: MODELLI_SEARCH,
        },
      })
    }
  }

  useEffect(() => {
    if (searchData?.search?.edges?.length && !searchLoading)
      setRisultati(mappingRisultatiSearchOverlay(searchData))
    else setRisultati([])
    if (!searchLoading) setLoading(false)
  }, [searchData, searchLoading])

  const inputRef = useRef(null)

  useEffect(() => {
    if (visible) {
      inputRef.current.focus()
    }
  }, [visible])

  const ref = useRef(null)
  const closeRef = useRef(null)

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Escape') {
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  const handleFocus = (e: any) => {
    // Verifica se l'elemento che ha ricevuto il focus è all'interno della modale
    if (ref.current && !ref.current.contains(e.target)) {
      // Riporta il focus al primo elemento interattivo all'interno della modale
      closeRef.current.focus()
    }
  }
  useEffect(() => {
    document.addEventListener('focus', handleFocus, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
    }
  }, [])

  const focusOnVisibleSearchButton = () => {
    const searchButtons = document?.querySelectorAll('#search-button')

    for (const button of searchButtons) {
      let currentElement = button
      let isVisible = true

      while (currentElement) {
        if (window?.getComputedStyle(currentElement).display === 'none') {
          isVisible = false
          break
        }
        currentElement = currentElement.parentElement
      }

      if (isVisible) {
        setTimeout(() => {
          button.focus()
        }, 1)
        return
      }
    }
  }

  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState(null)

  const onFocus = (e: any) => {
    setFocused(true)
  }

  const onBlur = (e: any) => {
    setFocused(false)
  }

  const getTotaleItems = (data: ResultProps[]) => {
    let totaleItems = 0

    data.forEach((categoria) => {
      totaleItems += categoria.items.length
    })

    return totaleItems
  }

  return visible ? (
    <dialog
      className={`${styles.searchOverlay} ${className ? ` ${className}` : ''}`}
      hidden={!visible}
      aria-modal
      aria-label={t('Cerca')}
      ref={ref}
      tabIndex={0}
    >
      {!!setVisible && (
        <button
          type="button"
          aria-label={t('Chiudi Ricerca')}
          onClick={() => {
            setVisible(false)
            focusOnVisibleSearchButton()
          }}
          className={styles.close}
          ref={closeRef}
        >
          <Close />
        </button>
      )}
      <WContainer variant="sm">
        <form className={styles.form} onSubmit={submit} autoComplete="off">
          <label className={styles.formLabel} htmlFor="ricerca-input">
            <span
              className={`${styles.formLabelText} ${
                focused || value === 0 || !!value ? styles['formLabelText--focused'] : ''
              }`}
            >
              {t('Cerca')}
            </span>
          </label>
          <div className={styles.inputWrap}>
            <input
              id="ricerca-input"
              type="text"
              name="q"
              ref={inputRef}
              className={styles.input}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => {
                setValue(e?.target?.value)
              }}
            />
            <button type="submit" className={styles.submit} aria-label={t('Cerca')}>
              <Search />
            </button>
          </div>
        </form>
        <label className={styles.helpText}>{t('Premi invio per cercare')}</label>
        {risultati?.length > 0 && submitted && (
          <label
            className={styles.totRisultati}
            aria-live={'polite'}
            dangerouslySetInnerHTML={{
              __html: t('{0} Risultati', [getTotaleItems(risultati)?.toString()]),
            }}
          />
        )}
        {!submitted ? (
          <div className={styles.suggestions}>
            {menuTopSearch &&
              menuTopSearch.children?.edges &&
              menuTopSearch.children.edges.slice(0, 4).map((item) => (
                <Link legacyBehavior href={item.node.url ? item.node.url : '/'} key={item.node.pk}>
                  <a
                    className={styles.suggestionItem}
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'search',
                        eventAction: item.node.name?.toGTMFormat(),
                        eventLabel: 'suggested',
                      })
                    }
                  >
                    {!!item.node.style && (
                      <span className={styles.suggestionItemIcon}>
                        {getIcon(item.node.style, 'searchoverlay_menu_' + item.node.pk.toString())}
                      </span>
                    )}
                    {!!item.node.name && (
                      <p className={styles.suggestionItemTitle}>{item.node.name}</p>
                    )}
                  </a>
                </Link>
              ))}
          </div>
        ) : loading ? (
          <Loader />
        ) : error ? (
          <NotificationBar variant="alert" label={error} />
        ) : risultati.length ? (
          <div className={styles.results}>
            <TabGroup
              triggerSize="lg"
              tabs={risultati.map((resultType) => ({
                key: resultType.pk.toString(),
                label: resultType.name,
                icon: getIcon(resultType.style, 'searchoverlay_tab_' + resultType.pk.toString()),
                content: (
                  <div className={styles.tabContent}>
                    <div className={styles.page}>
                      {resultType.items.slice(0, resultsPerPage).map((item) => (
                        <Link legacyBehavior href={item.url} key={item.pk}>
                          <a
                            className={styles.pageItem}
                            dangerouslySetInnerHTML={{ __html: `<span>${item.text}</span>` }}
                            role="group"
                          />
                        </Link>
                      ))}
                    </div>
                    <div className={styles.pageActions}>
                      <p
                        className={styles.pageIndicator}
                        dangerouslySetInnerHTML={{
                          __html: t('{0} di {1}', [
                            `<strong>${
                              resultType.items.length > resultsPerPage
                                ? resultsPerPage
                                : resultType.items.length
                            }</strong>`,
                            resultType.items.length.toString(),
                          ]),
                        }}
                      />
                      <p
                        className="sr-only"
                        aria-live={'polite'}
                        dangerouslySetInnerHTML={{
                          __html: t('Risultati {2}: {0} di {1}', [
                            `${
                              resultType.items.length > resultsPerPage
                                ? resultsPerPage
                                : resultType.items.length
                            }`,
                            resultType.items.length.toString(),
                            resultType.name,
                          ]),
                        }}
                      />
                      <Button
                        label={t('Vedi tutti i risultati')}
                        rightIcon={<ArrowRight />}
                        variant="ghost"
                        external
                        href={'/ricerca?q=' + query}
                        onClick={() =>
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'search',
                            eventAction: 'ricerca_suggerita',
                            eventLabel: 'vedi_tutti_risultati',
                          })
                        }
                      />
                    </div>
                  </div>
                ),
              }))}
            />
          </div>
        ) : (
          <p className={styles.noResults} aria-live={'polite'}>
            {t('Non ci sono risultati per questa ricerca')}
          </p>
        )}
      </WContainer>
    </dialog>
  ) : (
    <></>
  )
}

export default SearchOverlay
