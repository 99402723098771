import React from 'react'
import Link from 'next/link'
import { WImage, Chip } from '@next-nx/shared-ui/atoms'
import styles from './ProdottoCard.module.sass'
import { ProdottoCardType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  id?: string
  prodotto: ProdottoCardType
  variant?: '' | 'horizontal'
  className?: string
  onClick?: () => void
}

const ProdottoCard = (props: Props) => {
  const {
    id = '',
    prodotto = undefined,
    variant = '',
    className = '',
    onClick = () => undefined,
  } = props

  const textId = (prodotto?.titolo?.replaceAll(' ', '_') || 'prodotto') + prodotto?.pk + '-text'

  return prodotto ? (
    <Link legacyBehavior href={prodotto.url}>
      <a
        id={id || undefined}
        className={`${styles.prodottoCard} ${
          variant ? styles[`prodottoCard--${variant}`] : ''
        } ${className}`}
        onClick={onClick}
        aria-labelledby={textId}
      >
        <div className={styles.imageBox}>
          <WImage
            src={prodotto.previewImage}
            maxWidth={318}
            maxHeight={140}
            layout="intrinsic"
            objectFit="cover"
          />
        </div>
        <div className={styles.content}>
          {!!prodotto.tags?.length && (
            <div className={styles.chips}>
              {prodotto.tags.slice(0, 4).map((tag) => (
                <Chip key={tag.pk} label={tag.titolo} variant="blue" size="sm" weight="regular" />
              ))}
            </div>
          )}
          {!!prodotto.titolo && (
            <p id={textId} className={styles.title}>
              {prodotto.titolo}
            </p>
          )}
          {!!prodotto.descrizioneBreve && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: prodotto.descrizioneBreve }}
            />
          )}
        </div>
      </a>
    </Link>
  ) : (
    <></>
  )
}

export default ProdottoCard
