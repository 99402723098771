import React from 'react'
import { WContainer, Breadcrumbs, MainTitle } from '@next-nx/shared-ui/atoms'
import { TextCtaBox } from '@next-nx/shared-ui/molecules'
import { Page, ProdottiListing } from '@next-nx/shared-ui/organisms'
import styles from './CategoriaProdottoTemplate.module.sass'
import { ProdottoCardType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  categoria: {
    pk: number
    contentTypeId: number
    nome?: string
    descrizione?: string
    descrizioneAggiuntiva?: string
    breadcrumbs: {
      titolo: string
      url?: string
    }[]
    box: {
      pk: number
      alias: string
      key?: string
      name?: string
      description?: string
      url?: string
      blank?: boolean
      ctaLabel?: string
    }
    prodotti?: ProdottoCardType[]
  }
  pagina?: any
}

const CategoriaProdottoTemplate = (props: Props) => {
  const { categoria = null, pagina = null } = props

  return categoria ? (
    <Page pagina={pagina} item={categoria} contentClassName={styles.categoriaProdottoContent}>
      <main>
        <div className={`${styles.headBg} ${categoria.box ? 'bg-grey-white' : 'bg-grey'}`}>
          <WContainer>
            <Breadcrumbs breadcrumbs={pagina.breadcrumbs} />
            <div className={styles.head}>
              <div className={styles.headContent}>
                {!!categoria.nome && (
                  <MainTitle label={categoria.nome} variant="light" className={styles.title} />
                )}
                {!!categoria.descrizione && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: categoria.descrizione }}
                  />
                )}
              </div>
              {!!categoria.box && (
                <div className={styles.promo}>
                  <TextCtaBox
                    item={categoria.box}
                    size="lg-narrow"
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'banner_promo',
                        eventAction: categoria.box.name?.toGTMFormat() ?? '',
                        eventLabel: 'plp',
                      })
                    }
                  />
                </div>
              )}
            </div>
          </WContainer>
        </div>
        {categoria.prodotti && (
          <WContainer>
            <ProdottiListing prodotti={categoria.prodotti} />
          </WContainer>
        )}
        {!!categoria.descrizioneAggiuntiva && (
          <WContainer>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: categoria.descrizioneAggiuntiva }}
            />
          </WContainer>
        )}
      </main>
    </Page>
  ) : (
    <></>
  )
}

export default CategoriaProdottoTemplate
