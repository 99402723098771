import React from 'react'
import { Button } from '@next-nx/shared-ui/atoms'
import styles from './TextCtaBox.module.sass'

interface Props {
  item?: {
    name?: string
    description?: string
    url?: string
    ctaLabel?: string
  }
  size?: 'sm' | 'lg' | 'lg-narrow'
  className?: string
  onClick?: () => void
}

const TextCtaBox = (props: Props) => {
  const { item = undefined, size = 'lg', className = '', onClick = () => undefined } = props

  return item ? (
    <div
      className={`${styles.textCtaBox} ${styles[`textCtaBox--${size}`]} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={styles.content}>
        {!!item.name && <h3 className={styles.title}>{item.name}</h3>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>
      {!!item.ctaLabel && !!item.url && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel}
            variant="tertiary2"
            href={item.url}
            onClick={onClick}
            ariaLabel={item.ctaLabel}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default TextCtaBox
