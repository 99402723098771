import React from 'react'
import { WContainer, Breadcrumbs, MainTitle, MainDescription } from '@next-nx/shared-ui/atoms'
import { Page, ArchivioFascicoliListing } from '@next-nx/shared-ui/organisms'
import styles from './ArchivioFascicoliTemplate.module.sass'

interface Props {
  pagina?: any
  isCatalogoFondi?: boolean
}

const ArchivioFascicoliTemplate = (props: Props) => {
  const { pagina = null, isCatalogoFondi = false } = props

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.archivioFascicoliContent}>
      <main>
        <div className={`${styles.headBg} bg-grey`}>
          <WContainer>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
            <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
            <MainDescription html={pagina?.descrizione} />
          </WContainer>
        </div>
        <div className="bg-white">
          <WContainer>
            <ArchivioFascicoliListing isCatalogoFondi={isCatalogoFondi} />
          </WContainer>
        </div>
      </main>
    </Page>
  )
}

export default ArchivioFascicoliTemplate
