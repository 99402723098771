import React, { useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import styles from './QuotazioneAutoPromoBox.module.sass'
import { QuotazioneAutoType } from '@next-nx/utils/types'
import {
  getPacchetto,
  CODICE_PACCHETTO_VEICOLO,
  CODICE_PACCHETTO_PERSONA,
  CODICE_PACCHETTO_EVENTI,
} from '@next-nx/utils/fastquote'
import { Button, Chip, Modal } from '@next-nx/shared-ui/atoms'
import { Add, ChevronDown, ChevronUp, Promo } from '@next-nx/shared-ui/icons'

interface Props {
  quotazione: QuotazioneAutoType
  className?: string
}

const QuotazioneAutoPromoBox = (props: Props) => {
  const { quotazione = undefined, className = '' } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const scontiValues = [
    quotazione?.scontoPacchettoPersonaVeicolo,
    quotazione?.scontoPacchettoPersonaEventi,
    quotazione?.scontoPacchettoVeicoloEventi,
    quotazione?.scontoPacchettoPersonaVeicoloEventi,
  ].filter((x) => !!x)
  const maxSconto = scontiValues.length > 0 ? Math.max(...scontiValues) : 0

  const classeNames = `
    ${styles.root}
    ${className ? ` ${className}` : ''}
  `

  return quotazione ? (
    <div className={classeNames}>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.head}>
            <span className={styles.icon}>
              <Promo />
            </span>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: t('Risparmia fino al <strong>{0}% sui pacchetti</strong>', [
                  maxSconto.toString(),
                ]),
              }}
            />
            <div className={`${styles['ctaBox--desktop']}`}>
              <Button
                variant="secondary"
                size="md"
                label={t(open ? 'Nascondi' : 'Mostra di più')}
                rightIcon={open ? <ChevronUp /> : <ChevronDown />}
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
          <div className={`${styles['ctaBox--mobile']}`}>
            <Button
              variant="secondary"
              size="md"
              label={t('Mostra di più')}
              rightIcon={<Add />}
              onClick={() => setOpenModal(!openModal)}
            />
          </div>
          {open && (
            <div className={styles.description}>
              {quotazione.scontoPacchettoPersonaVeicoloEventi && (
                <div>
                  <p>
                    Aggiungi <span>tutti e tre i pacchetti</span> per ottenere il{' '}
                    <span>{quotazione.scontoPacchettoPersonaVeicoloEventi}% di sconto</span>
                  </p>
                  <div className={styles.info}>
                    {quotazione.pacchetti.map((p, i) => (
                      <React.Fragment key={i}>
                        {i > 0 && <span>+</span>}
                        <Chip label={p.titolo} variant="neutral" size="sm" weight="regular" />
                      </React.Fragment>
                    ))}
                    <span>=</span>
                    <Chip
                      label={`${quotazione.scontoPacchettoPersonaVeicoloEventi}% di sconto`}
                      variant="light"
                      size="sm"
                    />
                  </div>
                </div>
              )}
              {(quotazione.scontoPacchettoVeicoloEventi ||
                quotazione.scontoPacchettoPersonaVeicolo ||
                quotazione.scontoPacchettoPersonaEventi) && (
                <div>
                  <p>
                    Aggiungi <span>due pacchetti</span> per ottenere lo sconto associato
                  </p>
                  {quotazione.scontoPacchettoPersonaVeicolo && (
                    <div className={styles.info}>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_PERSONA)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />
                      <span>+</span>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_VEICOLO)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />

                      <span>=</span>
                      <Chip
                        label={`${quotazione.scontoPacchettoPersonaVeicolo}% di sconto`}
                        variant="light"
                        size="sm"
                      />
                    </div>
                  )}
                  {quotazione.scontoPacchettoPersonaEventi && (
                    <div className={styles.info}>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_PERSONA)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />
                      <span>+</span>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_EVENTI)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />

                      <span>=</span>
                      <Chip
                        label={`${quotazione.scontoPacchettoPersonaEventi}% di sconto`}
                        variant="light"
                        size="sm"
                      />
                    </div>
                  )}
                  {quotazione.scontoPacchettoVeicoloEventi && (
                    <div className={styles.info}>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_VEICOLO)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />
                      <span>+</span>
                      <Chip
                        label={getPacchetto(quotazione, CODICE_PACCHETTO_EVENTI)?.titolo}
                        variant="neutral"
                        size="sm"
                        weight="regular"
                      />

                      <span>=</span>
                      <Chip
                        label={`${quotazione.scontoPacchettoVeicoloEventi}% di sconto`}
                        variant="light"
                        size="sm"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        closeButton
        className={`${styles.modal} ${className ? ` ${className}` : ''}`}
        buttons={
          <>
            <Button label={t('Chiudi')} variant="ghost" onClick={() => setOpenModal(false)} />
          </>
        }
        id={'QuotazioneAutoPromo'}
      >
        <div className={styles.content}>
          <p
            id={'QuotazioneAutoPromo_title'}
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: t('Risparmia fino al <strong>{0}% sui pacchetti</strong>', [
                maxSconto.toString(),
              ]),
            }}
          />
          {quotazione.scontoPacchettoPersonaVeicoloEventi && (
            <div className={styles.info}>
              <div className={styles.infoText}>
                {quotazione.pacchetti.map((p, i) => (
                  <React.Fragment key={i}>
                    {i > 0 && <span> + </span>}
                    {p.titolo}
                  </React.Fragment>
                ))}
              </div>
              <Chip
                labelHtml={`Aggiungi <strong>tutti e tre i pacchetti</strong> e ottieni il <strong>${quotazione.scontoPacchettoPersonaVeicoloEventi}% di sconto</strong>`}
                variant="light"
                size="sm"
                weight="regular"
              />
            </div>
          )}
          {(quotazione.scontoPacchettoVeicoloEventi ||
            quotazione.scontoPacchettoPersonaVeicolo ||
            quotazione.scontoPacchettoPersonaEventi) && (
            <div>
              <p>
                Aggiungi <strong>due pacchetti</strong> per ottenere lo sconto associato
              </p>
              {quotazione.scontoPacchettoPersonaVeicolo && (
                <div className={styles.info}>
                  <div className={styles.infoText}>
                    {getPacchetto(quotazione, CODICE_PACCHETTO_PERSONA)?.titolo} +{' '}
                    {getPacchetto(quotazione, CODICE_PACCHETTO_VEICOLO)?.titolo}
                  </div>
                  <Chip
                    label={`${quotazione.scontoPacchettoPersonaVeicolo}% di sconto`}
                    variant="light"
                    size="sm"
                  />
                </div>
              )}
              {quotazione.scontoPacchettoPersonaEventi && (
                <div className={styles.info}>
                  <div className={styles.infoText}>
                    {getPacchetto(quotazione, CODICE_PACCHETTO_PERSONA)?.titolo} +{' '}
                    {getPacchetto(quotazione, CODICE_PACCHETTO_EVENTI)?.titolo}
                  </div>
                  <Chip
                    label={`${quotazione.scontoPacchettoPersonaEventi}% di sconto`}
                    variant="light"
                    size="sm"
                  />
                </div>
              )}
              {quotazione.scontoPacchettoVeicoloEventi && (
                <div className={styles.info}>
                  <div className={styles.infoText}>
                    {getPacchetto(quotazione, CODICE_PACCHETTO_VEICOLO)?.titolo} +{' '}
                    {getPacchetto(quotazione, CODICE_PACCHETTO_EVENTI)?.titolo}
                  </div>
                  <Chip
                    label={`${quotazione.scontoPacchettoVeicoloEventi}% di sconto`}
                    variant="light"
                    size="sm"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneAutoPromoBox
