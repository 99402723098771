import React, { useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { Accordion, Button } from '@next-nx/shared-ui/atoms'
import { OutlineTextCtaBox } from '@next-nx/shared-ui/molecules'
import styles from './FaqSection.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  faqs: {
    pk: number
    domanda: string
    risposta: string
  }[]
  faqMenu?: MenuType | null
  className?: string
}

const FaqSection = (props: Props) => {
  const { faqs = [], faqMenu = null, className = '' } = props

  const t = useTrans()
  const maxVisible = 8
  const [showAll, setShowAll] = useState(false)

  const contacts = faqMenu?.children?.edges.find((child) => child.node.key === 'contacts')?.node

  return faqMenu && faqs?.length > 0 ? (
    <div className={`${styles.faqSection} ${className ? ` ${className}` : ''}`}>
      {faqMenu?.name && (
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: faqMenu.name }} />
      )}
      <div className={styles.list}>
        {(showAll ? faqs : faqs.slice(0, maxVisible)).map((faq) => (
          <Accordion
            key={faq.pk}
            label={faq.domanda}
            content={faq.risposta}
            onClick={() =>
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'faq',
                eventAction: faq.domanda?.toGTMFormat(),
                eventLabel: 'pdp',
              })
            }
          />
        ))}
        {!showAll && faqs.length > maxVisible && (
          <div className={styles.showAll}>
            <Button
              label={t(
                faqs.length - maxVisible === 1
                  ? 'Mostra {0} altra domanda'
                  : 'Mostra altre {0} domande',
                [(faqs.length - maxVisible).toString()]
              )}
              rightIcon={<ArrowRight />}
              variant="ghost"
              onClick={() => setShowAll(true)}
            />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        {faqMenu?.url && (
          <div className={styles.allLink}>
            <Button
              href={faqMenu.url}
              label={faqMenu.ctaLabel}
              rightIcon={<ArrowRight />}
              variant="ghost"
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'faq',
                  eventAction: 'vai_a_tutte',
                  eventLabel: 'pdp',
                })
              }
              ariaLabel={faqMenu.ctaLabel}
            />
          </div>
        )}
        {!!contacts && (
          <OutlineTextCtaBox
            item={{
              name: contacts.name,
              description: contacts.description,
              ctaLabel: contacts.ctaLabel,
              url: contacts.url,
            }}
            className={styles.contacts}
            onClick={() =>
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'faq',
                eventAction: contacts.ctaLabel?.toGTMFormat() || '',
                eventLabel: 'contact_us',
              })
            }
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FaqSection
