import React, { useRef, useState } from 'react'
import {
  Close,
  LogoAssicurazioni,
  LogoCa,
  LogoVita,
  MenuRight,
  Profile,
  Search,
} from '@next-nx/shared-ui/icons'
import { Button, WContainer } from '@next-nx/shared-ui/atoms'
import { SearchOverlay, Nav, NavMobile } from '@next-nx/shared-ui/organisms'
import styles from './Header.module.sass'
import { useFindMenu, useSite, useTrans } from '@next-nx/hooks'
import Link from 'next/link'
import { ASSICURAZIONI, VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'
import router from 'next/router'

interface Props {
  className?: string
}

const Header = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const site = useSite()

  const [searchOpen, setSearchOpen] = useState(false)
  const [navMobileOpen, setNavMobileOpen] = useState(false)

  const menuTop = useFindMenu('top_header')
  const menuAreaClienti = useFindMenu('header_area_clienti')

  const scopri_nuova_app_elems =
    menuTop &&
    menuTop.children?.edges &&
    menuTop.children.edges.filter(
      (el) => el && el.node && el.node.key == 'top_header_scopri_nuova_app'
    )

  const closeRef = useRef(null)

  return (
    <header className={`${styles.header} ${className ? ` ${className}` : ''}`}>
      <div className={styles.top}>
        <WContainer variant="lg">
          <div className={styles.topMenu}>
            {menuTop &&
              menuTop.children?.edges &&
              menuTop.children.edges
                .filter((el) => el && el.node && el.node.key != 'top_header_scopri_nuova_app')
                .map((item) => (
                  <a href={item.node.url} key={item.node.pk}>
                    {item.node.name}
                  </a>
                ))}
            {scopri_nuova_app_elems && scopri_nuova_app_elems.length > 0 ? (
              <div className={styles.vl}></div>
            ) : null}
            {scopri_nuova_app_elems && scopri_nuova_app_elems.length > 0
              ? scopri_nuova_app_elems.map((item) => (
                  <a
                    href={item.node.url}
                    key={item.node.pk}
                    dangerouslySetInnerHTML={{ __html: item.node.name || '' }}
                  />
                ))
              : null}
          </div>
        </WContainer>
      </div>
      <div className={styles.main}>
        <WContainer variant="lg">
          <div className={styles.mainContent}>
            <Link legacyBehavior href="/">
              <a
                onClick={() =>
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: 'header',
                    eventAction: 'logo',
                  })
                }
                aria-label={
                  site === ASSICURAZIONI
                    ? t('Crédit Agricole Assicurazioni - Homepage')
                    : t('Crédit Agricole Vita - Homepage')
                }
                className={styles.logo}
              >
                {site === ASSICURAZIONI ? (
                  <LogoAssicurazioni title={t('Logo')} />
                ) : site === VITA ? (
                  <LogoVita title={t('Logo')} />
                ) : (
                  <LogoCa />
                )}
              </a>
            </Link>
            <div className={styles.navigation}>
              <Nav />
            </div>
            <div className={styles.search}>
              <Button
                label={t('Cerca')}
                leftIcon={<Search />}
                variant="ghost"
                onClick={() => setSearchOpen(!searchOpen)}
                ariaLabel={t('Cerca')}
                id={'search-button'}
              />
            </div>
            {!!menuAreaClienti && (
              <div className={styles.areaClienti}>
                <Button
                  label={menuAreaClienti.ctaLabel}
                  href={menuAreaClienti.url}
                  external
                  newPage
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'area_clienti',
                    })
                  }}
                  ariaLabel={t('Area Clienti')}
                />
              </div>
            )}
            <div className={styles.mobileButtons}>
              {!!menuAreaClienti && (
                <a
                  href={menuAreaClienti.url}
                  className={styles.mobileButton}
                  style={navMobileOpen ? { visibility: 'hidden' } : {}}
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'area_clienti',
                    })
                  }}
                  aria-label={t('Area clienti')}
                >
                  <Profile />
                </a>
              )}
              <button
                id={'search-button'}
                type="button"
                className={styles.mobileButton}
                onClick={() => setSearchOpen(!searchOpen)}
                style={navMobileOpen ? { visibility: 'hidden' } : {}}
                aria-label={t('Ricerca')}
              >
                <Search />
              </button>
              <button
                type="button"
                className={styles.mobileButton}
                onClick={() => setNavMobileOpen(!navMobileOpen)}
                aria-label={navMobileOpen ? t('Chiudi menu') : t('Apri menu')}
                aria-expanded={navMobileOpen}
                aria-controls="NavMobile_content"
                ref={closeRef}
              >
                {navMobileOpen ? <Close /> : <MenuRight />}
              </button>
            </div>
          </div>
        </WContainer>
      </div>
      <SearchOverlay visible={searchOpen} setVisible={setSearchOpen} />
      <NavMobile open={navMobileOpen} setOpen={setNavMobileOpen} closeRef={closeRef} />
    </header>
  )
}

export default Header
