import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { Search } from '@next-nx/shared-ui/icons'
import styles from './FaqSearchForm.module.sass'
import { useRouter } from 'next/router'

interface Props {
  className?: string
}

const FaqSearchForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const router = useRouter()

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const query = e.target.q.value

    router.push('/faq?q=' + query)
  }

  return (
    <form
      className={`${styles.faqSearchForm} ${className ? ` ${className}` : ''}`}
      onSubmit={submit}
      autoComplete="off"
    >
      <label className={styles.label} id="form_search_faq_label">
        {t('Ho bisogno di...')}
      </label>
      <div className={styles.inputWrap}>
        <input
          aria-labelledby="form_search_faq_label"
          type="text"
          name="q"
          placeholder={t('Ho bisogno di...')}
          className={styles.input}
          autoComplete="off"
        />
        <button type="submit" className={styles.submit} aria-label={t('Cerca')}>
          <Search />
        </button>
      </div>
    </form>
  )
}

export default FaqSearchForm
