import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination, Navigation } from 'swiper/modules'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { IntroTitle, WContainer, Button } from '@next-nx/shared-ui/atoms'
import { ArticoloCard } from '@next-nx/shared-ui/molecules'
import styles from './NewsSection.module.sass'
import { sendGTMData } from '../../../utils/gtm'
import { useTrans } from '@next-nx/hooks'

interface Props {
  intro?: string
  title?: string
  ctaLabel?: string
  url?: string
  news: {
    node: {
      pk: number
      categoria?: {
        title?: string
      }
      publicationDate?: string
      title?: string
      url: string
      image?: string
    }
  }[]
  className?: string
}

const NewsSection = (props: Props) => {
  const { intro = '', title = '', ctaLabel = '', url = '', news = [], className = '' } = props

  const t = useTrans()

  const swiperSettings = {
    slidesPerView: 1.25,
    spaceBetween: 16,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.news-button-next',
      prevEl: '.news-button-prev',
    },
    modules: [A11y, Pagination, Navigation],
    watchOverflow: true,
    centeredSlides: false,
    className: 'swiper--pagination',
    breakpoints: {
      1100: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.5,
        centeredSlides: false,
      },
      576: {
        slidesPerView: 1.5,
      },
      0: {
        centeredSlides: true,
      },
    },
    a11y: {
      enabled: true,
      prevSlideMessage: t('Slide precedente'),
      nextSlideMessage: t('Slide successiva'),
      paginationBulletMessage: 'Vai alla slide {{index}}',
    },
  }

  return news.length ? (
    <div className={`${styles.newsSection} ${className}`}>
      <WContainer variant="md">
        {!!intro && <IntroTitle label={intro} className={styles.intro} />}
        {!!title && <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
        <div className={styles.list}>
          <button
            type="button"
            className="custom-button-prev news-button-prev"
            aria-label={t('precedente')}
            onClick={() =>
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'box_info',
                eventAction: 'informati',
                eventLabel: 'prev',
              })
            }
          >
            <ArrowLeft />
          </button>
          <button
            type="button"
            className="custom-button-next news-button-next"
            aria-label={t('successivo')}
            onClick={() =>
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'box_info',
                eventAction: 'informati',
                eventLabel: 'next',
              })
            }
          >
            <ArrowRight />
          </button>
          <Swiper {...swiperSettings} aria-live={'polite'}>
            {news.map((child) => (
              <SwiperSlide key={child.node.pk}>
                <div className={styles.item}>
                  <ArticoloCard
                    articolo={child.node}
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'box_info',
                        eventAction: 'informati',
                        eventLabel: child.node?.title?.toGTMFormat() || '',
                      })
                    }
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {!!ctaLabel && !!url && (
            <div
              className={styles.ctaBox}
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'box_info',
                  eventAction: 'informati',
                  eventLabel: 'leggi tutte le news',
                })
              }
            >
              <Button label={ctaLabel} rightIcon={<ArrowRight />} href={url} variant="ghost" />
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default NewsSection
