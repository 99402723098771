import React from 'react'
import Link from 'next/link'
import { Chip, WImage } from '@next-nx/shared-ui/atoms'
import styles from './ArticoloCard.module.sass'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  id?: string
  articolo: {
    categoria?: {
      title?: string
    }
    publicationDate?: string
    title?: string
    url: string
    mainImage?: string
  }
  className?: string
  onClick?: () => void
}

const ArticoloCard = (props: Props) => {
  const { id = '', articolo = undefined, className = '', onClick = () => undefined } = props

  const textId = (articolo?.title?.replaceAll(' ', '_').slice(0, 15) || 'articolo') + '-text'

  return articolo ? (
    <Link legacyBehavior href={articolo.url}>
      <a
        id={id || undefined}
        className={`${styles.articoloCard} ${className}`}
        onClick={onClick}
        aria-labelledby={textId}
      >
        <div className={styles.imageBox}>
          <WImage
            src={articolo.mainImage}
            maxWidth={350}
            maxHeight={124}
            layout="intrinsic"
            objectFit="cover"
          />
        </div>
        <div className={styles.content}>
          {!!articolo.categoria?.title && (
            <div className={styles.chips}>
              <Chip label={articolo.categoria.title} variant="light" size="sm" weight="regular" />
            </div>
          )}
          {!!articolo.publicationDate && <p className={styles.date}>{articolo.publicationDate}</p>}
          {!!articolo.title && (
            <p className={styles.title} id={textId}>
              {articolo.title}
            </p>
          )}
        </div>
      </a>
    </Link>
  ) : (
    <></>
  )
}

export default ArticoloCard
