import React from 'react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IntroTitle, WContainer, Button } from '@next-nx/shared-ui/atoms'
import { ProdottoCard } from '@next-nx/shared-ui/molecules'
import styles from './ProdottiSection.module.sass'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { MenuType, ProdottoCardType, GaEventType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'
import { useTrans } from '@next-nx/hooks'

interface Props {
  item: MenuType
  prodotti: ProdottoCardType[]
  className?: string
  prev?: GaEventType
  next?: GaEventType
  whatIs?: string
}

const ProdottiSection = (props: Props) => {
  const {
    item = undefined,
    prodotti = [],
    className = '',
    prev = undefined,
    next = undefined,
    whatIs = '',
  } = props

  const t = useTrans()

  const swiperSettings = {
    slidesPerView: 1.25,
    spaceBetween: 16,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.prodotti-button-next',
      prevEl: '.prodotti-button-prev',
    },
    watchOverflow: true,
    centeredSlides: false,
    className: 'swiper--pagination',
    breakpoints: {
      1100: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.5,
        centeredSlides: false,
      },
      576: {
        slidesPerView: 1.5,
      },
      0: {
        centeredSlides: true,
      },
    },
    a11y: {
      enabled: true,
      prevSlideMessage: t('Slide precedente'),
      nextSlideMessage: t('Slide successiva'),
      paginationBulletMessage: 'Vai alla slide {{index}}',
    },
    modules: [Pagination, Navigation, A11y],
  }

  const handleClick = (
    prodotto: ProdottoCardType | undefined = undefined,
    item?: MenuType | undefined
  ): void => {
    whatIs === 'prodottiMenu'
      ? sendGTMData({
          event: 'GAevent',
          eventCategory: 'box_info',
          eventAction: 'esplora',
          eventLabel: prodotto?.titolo?.toGTMFormat() ?? item?.ctaLabel?.toGTMFormat(),
        })
      : whatIs === 'prodottiCorrelati' &&
        sendGTMData({
          event: 'GAevent',
          eventCategory: 'prodotti_correlati',
          eventAction: prodotto?.titolo?.toGTMFormat() ?? item?.ctaLabel?.toGTMFormat(),
          eventLabel: 'click',
        })
  }

  return item ? (
    <div className={`${styles.prodottiSection} ${className}`}>
      <WContainer variant="md">
        {!!item.name && <IntroTitle label={item.name} className={styles.intro} />}
        {!!item.description && (
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: item.description }} />
        )}
        {!!prodotti.length && (
          <div className={styles.list}>
            <button
              type="button"
              className="custom-button-prev prodotti-button-prev"
              aria-label={t('precedente')}
              onClick={prev ? () => sendGTMData({ ...prev }) : undefined}
            >
              <ArrowLeft />
            </button>
            <button
              type="button"
              className="custom-button-next prodotti-button-next"
              aria-label={t('successivo')}
              onClick={next ? () => sendGTMData({ ...next }) : undefined}
            >
              <ArrowRight />
            </button>
            <Swiper {...swiperSettings}>
              {prodotti.map((prodotto) => (
                <SwiperSlide key={prodotto.pk}>
                  <div className={styles.item}>
                    <ProdottoCard prodotto={prodotto} onClick={() => handleClick(prodotto)} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {!!item.ctaLabel && !!item.url && (
              <div className={styles.ctaBox}>
                <Button
                  label={item.ctaLabel}
                  rightIcon={<ArrowRight />}
                  href={item.url}
                  variant="ghost"
                  onClick={() => handleClick(undefined, item)}
                  ariaLabel={item?.ctaLabel}
                />
              </div>
            )}
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ProdottiSection
