import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { Chip, ProgressBar, WContainer } from '@next-nx/shared-ui/atoms'
import styles from './PreventivatoreFastQuoteAutoLoader.module.sass'
import { ProdottoType } from '@next-nx/utils/types'
import { getIcon } from '@next-nx/utils/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'

interface Props {
  className?: string
  prodotto?: ProdottoType
}

const PreventivatoreFastQuoteAutoLoader = (props: Props) => {
  const { className = '', prodotto = undefined } = props

  const t = useTrans()

  const classNames = `
    ${className ? `${className}` : ''}
  `

  const pacchettiItems = [
    {
      pk: 1,
      name: t('Se aggiungi i pacchetti'),
      description: (
        <>
          <span>{t('Persona')}</span> + <span>{t('Veicolo')}</span> + <span>{t('Eventi')}</span>
        </>
      ),
      scontoValue: 13,
    },
    {
      pk: 2,
      name: t('Se aggiungi i pacchetti'),
      description: (
        <>
          <span>{t('Persona')}</span> + <span>{t('Veicolo')}</span>
        </>
      ),
      scontoValue: 9,
    },
    {
      pk: 3,
      name: t('Se aggiungi i pacchetti'),
      description: (
        <>
          <span>{t('Persona')}</span> + <span>{t('Eventi')}</span>
        </>
      ),
      scontoValue: 5,
    },
    {
      pk: 4,
      name: t('Se aggiungi i pacchetti'),
      description: (
        <>
          <span>{t('Veicolo')}</span> + <span>{t('Eventi')}</span>
        </>
      ),
      scontoValue: 5,
    },
  ]

  return (
    <div className={classNames}>
      <WContainer>
        <div className={styles.loaderBox}>
          <ProgressBar className={styles.loader} duration={60} />
        </div>
        <div>
          <p className={styles.title}>{t('Stiamo calcolando il tuo preventivo.')}</p>
          <p className={styles.subtitle}>
            {t("Non chiudere la pagina, l'operazione potrebbe durare fino a 60 secondi.")}
          </p>
        </div>
      </WContainer>
      <div className={styles.pacchettiBox}>
        <div className={`${styles.pacchettiSection} ${className ? ` ${className}` : ''}`}>
          <WContainer variant="lg">
            <div className={styles.pacchettiMain}>
              <div className={styles.pacchettiContent}>
                <p className={styles.pacchettiTitle}>
                  {t('Combina più pacchetti per ottenere maggiori sconti')}
                </p>
                <div
                  className={styles.pacchettiDescription}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'A breve potrai personalizzare la tua offerta scegliendo le garanzie di cui hai bisogno'
                    ),
                  }}
                />
              </div>
            </div>
            {pacchettiItems.length && (
              <div className={styles.pacchettiSlider}>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  modules={[Pagination, A11y]}
                  pagination={{ clickable: true }}
                  watchOverflow
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    1100: {
                      slidesPerView: 4,
                    },
                  }}
                  a11y={{
                    enabled: true,
                    prevSlideMessage: t('Slide precedente'),
                    nextSlideMessage: t('Slide successiva'),
                    paginationBulletMessage: 'Vai alla slide {{index}}',
                  }}
                >
                  {pacchettiItems.map((child) => (
                    <SwiperSlide key={child.pk}>
                      <div className={styles.pacchettiItem}>
                        {!!child.scontoValue && (
                          <div className={styles.pacchettiItemSconto}>
                            <div className={styles.pacchettiScontoChip}>
                              <p>
                                <strong>{child.scontoValue}%</strong> di sconto
                              </p>
                            </div>
                          </div>
                        )}
                        {!!child.name && <p className={styles.pacchettiItemTitle}>{child.name}</p>}
                        {!!child.description && (
                          <div className={styles.pacchettiItemDescription}>{child.description}</div>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </WContainer>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <WContainer>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: t('Ti ricordiamo che la quotazione sarà valida se:'),
            }}
          />
          <div className={styles.itemscontainer}>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className={styles.icon}>{getIcon('Profilo', ``)}</span>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{
                    __html: t('Contraente e intestatario sono una persona fisica'),
                  }}
                ></span>
              </div>
              <div className={styles.item}>
                <span className={styles.icon}>{getIcon('Intestatario', ``)}</span>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: t('Contraente e intestatario coincidono') }}
                ></span>
              </div>
              <div className={styles.item}>
                <span className={styles.icon}>{getIcon('Veicoli', ``)}</span>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: t('Il tuo veicolo è ad uso privato') }}
                ></span>
              </div>
              <div className={styles.item}>
                <span className={styles.icon}>{getIcon('Altro', ``)}</span>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: t('Non hai usufruito della legge Bersani') }}
                ></span>
              </div>
            </div>
            <p
              className={styles.note}
              dangerouslySetInnerHTML={{
                __html: t('*Informazioni recuperate dalla banca dati ANIA'),
              }}
            ></p>
            <p
              className={styles.note}
              dangerouslySetInnerHTML={{
                __html: t('loader_preventivatore_auto_notalegale'),
              }}
            ></p>
          </div>
        </WContainer>
      </div>
    </div>
  )
}

export default PreventivatoreFastQuoteAutoLoader
