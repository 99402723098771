import React, { useEffect, useContext, useState, useRef } from 'react'
import Link from 'next/link'
import { useTrans, useSite } from '@next-nx/hooks'
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { Close, LogoAssicurazioni, LogoVita } from '@next-nx/shared-ui/icons'
import styles from './ChatBotOverlay.module.sass'

interface Props {}

const ChatBotOverlay = (props: Props) => {
  const {} = props

  const t = useTrans()
  const site = useSite()

  const chatbotCtx = useContext(ChatBotContext)

  useEffect(() => {
    if (chatbotCtx.chatBotVisible) {
      document.body.classList.add('w-noscroll')

      const recaptchaBadge = document.querySelector('.grecaptcha-badge')
      if (recaptchaBadge) {
        recaptchaBadge.style.zIndex = '8001'
      }
    } else {
      document.body.classList.remove('w-noscroll')
      const recaptchaBadge = document.querySelector('.grecaptcha-badge')
      if (recaptchaBadge) {
        recaptchaBadge.style.zIndex = ''
      }
    }
  }, [chatbotCtx.chatBotVisible])

  const ref = useRef(null)
  const closeRef = useRef(null)
  const focusOnFirstElem = () => {
    const elem = document.querySelector('#trova-subito-btn')
    if (elem) {
      elem.focus()
    }
  }

  const handleFocus = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      closeRef.current.focus()
    }
  }
  useEffect(() => {
    document.addEventListener('focus', handleFocus, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
    }
  }, [])

  return (
    <dialog
      className={`${styles.chatBotOverlay} ${
        chatbotCtx.chatBotVisible ? styles['chatBotOverlay--visible'] : ''
      }`}
      hidden={!chatbotCtx.chatBotVisible}
      aria-modal
      aria-label={t('Non è quello che stavi cercando?')}
      ref={ref}
    >
      <div className={styles.head}>
        <div className={styles.headContent}>
          <div className={styles.logo}>
            <Link legacyBehavior href="/">
              <a
                aria-label={
                  site === ASSICURAZIONI
                    ? t('Crédit Agricole Assicurazioni - Homepage')
                    : t('Crédit Agricole Vita - Homepage')
                }
              >
                {site === ASSICURAZIONI ? <LogoAssicurazioni /> : <LogoVita />}
              </a>
            </Link>
          </div>
          {!!chatbotCtx.setChatBotVisible && (
            <button
              type="button"
              aria-label={t('Chiudi')}
              onClick={() => {
                chatbotCtx.setChatBotVisible(false)
                setTimeout(focusOnFirstElem, 100)
              }}
              className={styles.close}
              ref={closeRef}
            >
              <Close />
            </button>
          )}
        </div>
      </div>
      <div id="xenioowebchat" className={styles.content} />
    </dialog>
  )
}

export default ChatBotOverlay
