import { useState, useEffect } from 'react'
import { useSite } from '@next-nx/hooks'
import { WContainer, Breadcrumbs, MainTitle } from '@next-nx/shared-ui/atoms'
import { Page, ArticoloPrimoPiano, MagazineListing } from '@next-nx/shared-ui/organisms'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import styles from './MagazineTemplate.module.sass'
import { useArticoliMagazineQuery as useArticoliMagazineQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useArticoliMagazineQuery as useArticoliMagazineQueryVita } from '@next-nx/shared-graphql/vita'

interface Props {
  pagina?: any
  className?: string
}

const MagazineTemplate = ({ pagina = null, className = '' }: Props) => {
  const site = useSite()
  const useArticoliMagazineQuery =
    site === ASSICURAZIONI ? useArticoliMagazineQueryAssicurazioni : useArticoliMagazineQueryVita

  const { data } = useArticoliMagazineQuery({
    variables: {
      first: 1,
    },
  })

  const [primoPiano, setPrimoPiano] = useState<any>()

  useEffect(() => {
    if (data?.articoliMagazine?.edges) {
      if (data.articoliMagazine.edges.length > 0) {
        setPrimoPiano(data.articoliMagazine.edges[0]?.node)
      }
    }
  }, [data])

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.magazineContent}>
      <main>
        <section className="bg-grey">
          <WContainer>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
            <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
            <ArticoloPrimoPiano title="In <strong>primo piano</strong>" articolo={primoPiano} />
          </WContainer>
        </section>
        <section className="bg-white">
          <WContainer>
            <MagazineListing />
          </WContainer>
        </section>
      </main>
    </Page>
  )
}

export default MagazineTemplate
