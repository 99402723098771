import React, { useRef, useState, useEffect } from 'react'
import { scrollTo } from '@next-nx/utils/safe'
import { useSite, useTrans, useUtente } from '@next-nx/hooks'
import { WContainer, Breadcrumbs, MainTitle, MainDescription } from '@next-nx/shared-ui/atoms'
import {
  QuotazioneGaranziaBox,
  QuotazioneGaranziaHomeBoxForm,
  QuotazioneGaranziaProtezioneBaseBox,
  QuotazioneGaranziaPetForm,
  QuotazioneHeadBox,
} from '@next-nx/shared-ui/molecules'
import {
  Page,
  SimpleHeader,
  SimpleFooter,
  QuotazioneModal,
  QuotazioneRiepilogo,
  QuotazioneVerificaForm,
  PreventivatoreFastQuoteLoader,
} from '@next-nx/shared-ui/organisms'
import styles from './QuotazioneCasaTemplate.module.sass'
import { AnimaleQuotazioneType, GaranziaQuotazioneType, QuotazioneType } from '@next-nx/utils/types'
import { BANCA } from 'libs/shared-ui/utils/settings'
import {
  CODICE_GARANZIA_FURTO,
  CODICE_GARANZIA_HOMEBOX,
  CODICE_GARANZIA_PET,
  CODICE_GARANZIA_PROTEZIONE_BASE,
  CODICE_GARANZIA_VALNUOVO,
  TIPO_GARANZIA_OBBLIGATORIA,
  validateDataDecorrenza,
} from '@next-nx/utils/fastquote'
import { getGaranziaDetails, sendGTMData } from 'libs/shared-ui/utils/gtm'

interface Props {
  pagina?: any
  quotazione?: QuotazioneType
}

const QuotazioneCasaTemplate = (props: Props) => {
  const { pagina = null, quotazione: quotazioneIniziale = undefined } = props

  const t = useTrans()
  const site = useSite()
  const formRef = useRef()
  const { utente } = useUtente()

  const [quotazione, setQuotazione] = useState(quotazioneIniziale)
  const [quotationVerificaSubmitting, setQuotationVerificaSubmitting] = useState(false)
  const [garanzieSelezionate, setGaranzieSelezionate] = useState<string[]>([])
  const [garanziaHomeBoxSelezionata, setGaranziaHomeBoxSelezionata] = useState<string>()
  const [actualKeyMassimale, setActualKeyMassimale] = useState<number>()
  const [massimaliFurto, setMassimaliFurto] = useState<number[]>([])
  const [openGaranzieObbligatorie, setOpenGaranzieObbligatorie] = useState(false)
  const [garanzie, setGaranzie] = useState<GaranziaQuotazioneType[]>([])
  const [animali, setAnimali] = useState<AnimaleQuotazioneType[]>([])
  const [isValid, setValid] = useState(true)
  const [isValidHomeBox, setValidHomeBox] = useState(true)
  const [isValidAnimali, setValidAnimali] = useState(true)
  const [isValidDataDecorrenza, setValidDataDecorrenza] = useState(true)
  const [totaleQuotazione, setTotaleQuotazione] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState(false)
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const getGaranzieUniche = (q: QuotazioneType) => {
    if (q?.garanzie) {
      let _gs = []
      _gs = q.garanzie.filter((g: GaranziaQuotazioneType) => g.keyMassimale == actualKeyMassimale)
      return _gs
    }
    return []
  }

  const getMassimaliFurto = (q: QuotazioneType) => {
    if (q?.garanzie) {
      return [...new Set(q.garanzie.map((g: GaranziaQuotazioneType) => g.keyMassimale))].sort(
        (a, b) => a - b
      )
    }
    return []
  }

  const resetGaranzie = () => {
    if (quotazione) {
      const garanzieUniche = getGaranzieUniche(quotazione)
      setGaranzie(garanzieUniche)

      if (garanzieUniche?.length > 0) handleGtm('impressions', null, '', garanzieUniche)

      // Se era selezionata garanzia CODICE_GARANZIA_PET e non esiste più in quotazione
      // => reset animali
      if (!garanzieUniche.find((g) => g.codice === CODICE_GARANZIA_PET)) {
        setAnimali([])
      }
      // Se era selezionata garanzia CODICE_GARANZIA_HOMEBOX e non esiste più in quotazione
      // => reset garanziaHomeBoxSelezionata
      if (!garanzieUniche.find((g) => g.codice === CODICE_GARANZIA_HOMEBOX)) {
        setGaranziaHomeBoxSelezionata(undefined)
      }
      // Se una garanzia selezionata non è più presente tra le garanzie della quotazione
      // => la rimuovo
      const allGaranzieCodici: string[] = []
      garanzieUniche.forEach((g) => {
        allGaranzieCodici.push(g.codice)
        g.children?.forEach((gc) => {
          allGaranzieCodici.push(gc.codice)
        })
      })
      setGaranzieSelezionate((garanzieSelezionate) =>
        garanzieSelezionate.filter((codice) => allGaranzieCodici.includes(codice))
      )
    }
  }

  const validateDataQuotazione = () => {
    setValidDataDecorrenza(validateDataDecorrenza(quotazione?.dataDecorrenza))
  }

  useEffect(() => {
    if (quotazione) {
      resetGaranzie()
      setMassimaliFurto(getMassimaliFurto(quotazione))
      validateDataQuotazione()
      setGaranzie((garanzie) => updatePrezziGaranziaPet(garanzie))
      setGaranzie((garanzie) => updatePrezziGaranziaHomeBox(garanzie))
    }
  }, [quotazione])

  useEffect(() => {
    if (massimaliFurto?.length && !actualKeyMassimale) {
      setActualKeyMassimale(massimaliFurto[0])
    }
  }, [massimaliFurto])

  useEffect(() => {
    if (quotazione) {
      resetGaranzie()
    }
  }, [actualKeyMassimale])

  const validateAnimali = () => {
    let status = true
    if (animali.length > 5) status = false
    animali.forEach((x) => {
      if (!x.tipo || !x.nome || !x.dataNascita) status = false
    })
    return status
  }

  const updatePrezziGaranziaValNuovo = (garanzie: GaranziaQuotazioneType[]) => {
    let garanziaValNuovo = garanzie?.find((g) => g.codice === CODICE_GARANZIA_VALNUOVO)
    const garanziaSelezionataFurto = garanzieSelezionate?.find(
      (codice) => codice === CODICE_GARANZIA_FURTO
    )
    const garanziaFurto = garanzie?.find((g) => g.codice === CODICE_GARANZIA_FURTO)
    if (garanziaValNuovo) {
      garanziaValNuovo.premioLordoAnnuoTotale =
        0.3 * garanziaFurto?.premioLordoAnnuo + garanziaValNuovo.premioLordoAnnuo
      if (!garanziaSelezionataFurto) {
        garanziaValNuovo.premioLordoAnnuoTotale = garanziaValNuovo.premioLordoAnnuo
      }
    }

    const newGaranzie = [...garanzie]
    if (garanziaValNuovo) {
      newGaranzie.splice(
        newGaranzie.findIndex((g) => g.codice === CODICE_GARANZIA_VALNUOVO),
        1,
        garanziaValNuovo
      )
    }
    return newGaranzie
  }

  const updatePrezziGaranziaPet = (garanzie: GaranziaQuotazioneType[]) => {
    let garanziaPet = garanzie?.find((g) => g.codice === CODICE_GARANZIA_PET)
    if (garanziaPet) {
      if (animali?.length > 0) {
        garanziaPet.massimaleTotale = 0
        garanziaPet.premioLordoAnnuoTotale = 0
      } else {
        garanziaPet.massimaleTotale = garanziaPet.massimale
        garanziaPet.premioLordoAnnuoTotale = garanziaPet.premioLordoAnnuo
      }
    }

    animali.forEach((x) => {
      const garanziaAnimale = garanziaPet?.children?.find((g) => g.titolo?.includes(x.tipo))
      if (garanziaPet && garanziaAnimale) {
        garanziaPet.massimaleTotale =
          (garanziaPet.massimaleTotale || 0) + (garanziaAnimale.massimale || 0)
        garanziaPet.premioLordoAnnuoTotale =
          (garanziaPet.premioLordoAnnuoTotale || 0) + (garanziaAnimale.premioLordoAnnuo || 0)
      }
    })

    const newGaranzie = [...garanzie]
    if (garanziaPet) {
      newGaranzie.splice(
        newGaranzie.findIndex((g) => g.codice === CODICE_GARANZIA_PET),
        1,
        garanziaPet
      )
    }
    return newGaranzie
  }

  useEffect(() => {
    setValidAnimali(validateAnimali())
    setGaranzie((garanzie) => updatePrezziGaranziaPet(garanzie))
  }, [animali])

  const validateHomeBox = () => {
    let status = true
    const garanziaHomeBox = garanzieSelezionate.find((codice) => codice === CODICE_GARANZIA_HOMEBOX)
    if (garanziaHomeBox && !garanziaHomeBoxSelezionata) {
      status = false
    }
    return status
  }

  const updatePrezziGaranziaHomeBox = (garanzie: GaranziaQuotazioneType[]) => {
    let garanziaHomeBox = garanzie?.find((g) => g.codice === CODICE_GARANZIA_HOMEBOX)
    if (garanziaHomeBox) {
      garanziaHomeBox.premioLordoAnnuoTotale =
        garanziaHomeBox.children?.find((g) => g.codice === garanziaHomeBoxSelezionata)
          ?.premioLordoAnnuo || garanziaHomeBox.premioLordoAnnuo
    }

    const newGaranzie = [...garanzie]
    if (garanziaHomeBox) {
      newGaranzie.splice(
        newGaranzie.findIndex((g) => g.codice === CODICE_GARANZIA_HOMEBOX),
        1,
        garanziaHomeBox
      )
    }
    return newGaranzie
  }

  useEffect(() => {
    setValidHomeBox(validateHomeBox())
    setGaranzie((garanzie) => updatePrezziGaranziaHomeBox(garanzie))
  }, [garanzieSelezionate, garanziaHomeBoxSelezionata])

  useEffect(() => {
    if (garanzieSelezionate?.length >= 0) {
      // Quando deselezio garanzia CODICE_GARANZIA_PET => reset animali
      const garanziaPet = garanzieSelezionate.find((codice) => codice === CODICE_GARANZIA_PET)
      if (!garanziaPet && animali?.length > 0) {
        setAnimali([])
      }

      // Quando deselezio garanzia CODICE_GARANZIA_HOMEBOX => reset garanziaHomeBoxSelezionata
      const garanziaHomeBox = garanzieSelezionate.find(
        (codice) => codice === CODICE_GARANZIA_HOMEBOX
      )
      if (!garanziaHomeBox) {
        setGaranziaHomeBoxSelezionata(undefined)
      }

      // Quando seleziono o deseleziono CODICE_GARANZIA_FURTO e CODICE_GARANZIA_VALNUOVO => aggiorno prezzo valnuovo
      const garanziaValNuovo = garanzieSelezionate.find(
        (codice) => codice === CODICE_GARANZIA_VALNUOVO
      )
      if (garanziaValNuovo) {
        setGaranzie((garanzie) => updatePrezziGaranziaValNuovo(garanzie))
      }
    }
  }, [garanzieSelezionate])

  useEffect(() => {
    setValid(isValidHomeBox && isValidAnimali && isValidDataDecorrenza)
  }, [isValidHomeBox, isValidAnimali, isValidDataDecorrenza])

  /*
  Ritorno le garanzie nell'ordine mostrato sul sito per impostare il campo "position" correttamente nel tracciamento
  */
  const getGaranziePerTracciamenti = (garanzie: GaranziaQuotazioneType[]) => {
    const garanziePerTracciamenti: GaranziaQuotazioneType[] = []

    const garanziaObb = garanzie.find((g) => g.codice === CODICE_GARANZIA_PROTEZIONE_BASE)
    if (garanziaObb) garanziePerTracciamenti.push(garanziaObb)

    garanzie
      ?.filter((x) => x.tipo !== TIPO_GARANZIA_OBBLIGATORIA)
      .forEach((g) => {
        garanziePerTracciamenti.push(g)
      })

    return garanziePerTracciamenti
  }

  const handleGtm = (
    type: 'add' | 'remove' | 'impressions',
    garanzia: GaranziaQuotazioneType,
    garanziaPadre?: string,
    garanzie?: GaranziaQuotazioneType[]
  ) => {
    const garanziePerTracciamenti: any[] = []
    if (type === 'impressions' && garanzie && garanzie.length > 0) {
      getGaranziePerTracciamenti(garanzie).forEach((g: GaranziaQuotazioneType) => {
        // Per la garanzia HOMEBOX passo le garanzie figlie
        if (g.codice === CODICE_GARANZIA_HOMEBOX) {
          g.children?.forEach((gc: GaranziaQuotazioneType) => {
            garanziePerTracciamenti.push(getGaranziaDetails(gc, site, quotazione, g.titolo || ''))
          })
        } else {
          garanziePerTracciamenti.push(getGaranziaDetails(g, site, quotazione, ''))
        }
      })
    }
    sendGTMData({
      event:
        type === 'add' ? 'addToCart' : type === 'remove' ? 'removeFromCart' : 'productImpression',
      ecommerce: {
        currencyCode: 'eur',
        ...(type === 'add' && {
          add: {
            actionField: {},
            products: [
              { ...getGaranziaDetails(garanzia, site, quotazione, garanziaPadre), quantity: 1 },
            ],
          },
        }),
        ...(type === 'remove' && {
          remove: {
            actionField: {},
            products: [
              { ...getGaranziaDetails(garanzia, site, quotazione, garanziaPadre), quantity: 1 },
            ],
          },
        }),
        ...(type === 'impressions' && {
          impressions: garanziePerTracciamenti.map((x, i) => ({ ...x, position: i + 1 })),
        }),
      },
    })
  }

  useEffect(() => {
    site === BANCA &&
      sendGTMData({
        event: 'GAevent',
        eventCategory: 'preventivatore_banca',
        eventAction: quotazione?.prodotto?.titolo?.toGTMFormat() || '',
        eventLabel: 'step1',
      })
  }, [])

  return (
    <Page
      pagina={pagina}
      item={{}}
      header={<SimpleHeader fixed={quotationVerificaSubmitting} />}
      footer={<SimpleFooter className={styles.footer} />}
      contentClassName={styles.quotazioneContent}
      pageWrapClassName={styles.quotazionePageWrap}
      recaptchaRequired
    >
      <main>
        {!!quotazione && (
          <>
            <div
              className={`${styles.headBg} bg-grey ${
                site === BANCA ? styles['headBg--no-breadcrumbs'] : ''
              }`}
            >
              <WContainer>
                {site !== BANCA && <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />}
                <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
                <MainDescription html={pagina?.descrizione} />
              </WContainer>
            </div>
            <div className={styles.headBox}>
              <QuotazioneHeadBox
                quotazione={quotazione}
                onClick={() => scrollTo(formRef.current, toolbarPresente, 0)}
              />
            </div>
            <div className="bg-white">
              <WContainer>
                <p
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: t('<strong>Seleziona le garanzie</strong> in base ai tuoi bisogni'),
                  }}
                />
                <div className={styles.layout}>
                  <div className={styles.main}>
                    <div className={styles.garanzieObbligatorie}>
                      {garanzie
                        ?.filter((x) => x.codice === CODICE_GARANZIA_PROTEZIONE_BASE)
                        .map((x) => (
                          <QuotazioneGaranziaProtezioneBaseBox
                            key={x.pk}
                            checked
                            openChildren={openGaranzieObbligatorie}
                            toggleChildren={() =>
                              setOpenGaranzieObbligatorie(!openGaranzieObbligatorie)
                            }
                            garanzia={x}
                            description={t('La protezione base comprende le seguenti garanzie:')}
                          />
                        ))}
                    </div>
                    <div className={styles.garanzieOpzionali}>
                      <p
                        className={`${styles.garanzieOpzionaliTitle} ${styles['garanzieOpzionaliTitle--desktop']}`}
                      >
                        {t('Personalizza la tua copertura')}
                      </p>
                      <p
                        className={`${styles.garanzieOpzionaliTitle} ${styles['garanzieOpzionaliTitle--mobile']}`}
                      >
                        {t('Completa la copertura del pacchetto')}
                      </p>
                      <div className={styles.garanzieOpzionaliList}>
                        {garanzie
                          ?.filter((x) => x.tipo !== TIPO_GARANZIA_OBBLIGATORIA)
                          .map((x) => (
                            <QuotazioneGaranziaBox
                              key={x.pk}
                              garanzia={x}
                              massimaleValue={
                                x.codice == CODICE_GARANZIA_FURTO ? actualKeyMassimale : undefined
                              }
                              setMassimaleValue={
                                x.codice == CODICE_GARANZIA_FURTO
                                  ? setActualKeyMassimale
                                  : undefined
                              }
                              massimaliFurto={
                                x.codice == CODICE_GARANZIA_FURTO
                                  ? massimaliFurto.filter(
                                      (item) => item <= quotazione.valArredamento
                                    )
                                  : undefined
                              }
                              checked={garanzieSelezionate.indexOf(x.codice) > -1}
                              onClick={() => {
                                const checked = garanzieSelezionate.indexOf(x.codice) > -1
                                setGaranzieSelezionate(
                                  checked
                                    ? garanzieSelezionate.filter((y) => y !== x.codice)
                                    : garanzieSelezionate.concat([x.codice])
                                )

                                if (x.codice === CODICE_GARANZIA_HOMEBOX) {
                                  if (!checked) {
                                    const firstSottoGaranzia = x?.children?.[0]
                                    firstSottoGaranzia &&
                                      handleGtm('add', firstSottoGaranzia, x.titolo)
                                  } else {
                                    const garanziaSelezionata = x.children?.find(
                                      (x) => x.codice === garanziaHomeBoxSelezionata
                                    )
                                    garanziaSelezionata &&
                                      handleGtm('remove', garanziaSelezionata, x.titolo)
                                  }
                                } else {
                                  if (!checked) {
                                    handleGtm('add', x)
                                  } else {
                                    handleGtm('remove', x)
                                  }
                                }
                              }}
                            >
                              {garanzieSelezionate.indexOf(x?.codice) > -1 &&
                                (x.codice === CODICE_GARANZIA_PET ? (
                                  <QuotazioneGaranziaPetForm
                                    description={t(
                                      '<strong>Configura la protezione per i tuoi animali domestici.</strong> Puoi aggiungere fino ad un massimo di 5 animali'
                                    )}
                                    animali={animali}
                                    setAnimali={setAnimali}
                                    minMeseCane={quotazione.etaMinimaCane}
                                    maxMeseCane={quotazione.etaMassimaCane}
                                    minMeseGatto={quotazione.etaMinimaGatto}
                                    maxMeseGatto={quotazione.etaMassimaGatto}
                                  />
                                ) : x.codice === CODICE_GARANZIA_HOMEBOX ? (
                                  <QuotazioneGaranziaHomeBoxForm
                                    garanzia={x}
                                    garanziaSelezionata={garanziaHomeBoxSelezionata}
                                    setGaranziaSelezionata={setGaranziaHomeBoxSelezionata}
                                    description={t('Scegli tra uno dei due pacchetti:')}
                                    handleGtm={handleGtm}
                                  />
                                ) : null)}
                            </QuotazioneGaranziaBox>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sidebar}>
                    <QuotazioneRiepilogo
                      quotazione={quotazione}
                      garanzie={garanzie}
                      garanzieSelezionate={garanzieSelezionate}
                      garanziaHomeBoxSelezionata={garanziaHomeBoxSelezionata}
                      totaleQuotazione={totaleQuotazione}
                      setTotaleQuotazione={setTotaleQuotazione}
                      isValid={isValid}
                      onClick={() => {
                        setModalOpen(true)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                          eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                          eventLabel: 'step2_0',
                          value: totaleQuotazione,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className={styles.bottom} ref={formRef}>
                  <QuotazioneVerificaForm
                    // CONTROLLO RIMOSSO il 06/04/23 SU RICHIESTA DEL CLIENTE - OLD:Valore si mostra solo se è selezionata la garanzia "Valorizzazione a nuovo"
                    quotazione={quotazione}
                    setQuotazione={setQuotazione}
                    setSubmitting={setQuotationVerificaSubmitting}
                    showValore={true}
                  />
                </div>
              </WContainer>
            </div>
            <QuotazioneModal
              quotazione={quotazione}
              massimaleFurtoSelezionato={actualKeyMassimale || 0}
              importoLordoAnnuoTotale={totaleQuotazione}
              garanzieObbligatorie={
                garanzie
                  .find((g) => g.codice === CODICE_GARANZIA_PROTEZIONE_BASE)
                  ?.children?.map((g) => g.codice) || []
              }
              garanzieSelezionate={garanzieSelezionate}
              garanziaHomeBoxSelezionata={garanziaHomeBoxSelezionata}
              animali={animali}
              open={modalOpen}
              setOpen={setModalOpen}
            />
          </>
        )}
        <PreventivatoreFastQuoteLoader
          prodotto={quotazione?.prodotto}
          open={quotationVerificaSubmitting}
        />
      </main>
    </Page>
  )
}

export default QuotazioneCasaTemplate
