import React, { useEffect, useState } from 'react'
import {
  ArrowRight,
  LogoAssicurazioni,
  LogoAssicurazioniMono,
  LogoCaMono,
  LogoVita,
  LogoVitaMono,
  SocialFacebook,
  SocialLinkedin,
} from '@next-nx/shared-ui/icons'
import { WContainer, Button } from '@next-nx/shared-ui/atoms'
import styles from './Footer.module.sass'
import { useFindMenu, useSite, useTrans } from '@next-nx/hooks'
import { ASSICURAZIONI, BANCA } from 'libs/shared-ui/utils/settings'
import { AppModal } from '@next-nx/shared-ui/organisms'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

const Footer = () => {
  const t = useTrans()
  const site = useSite()

  const footerMenu = useFindMenu('footer')

  /**
   * @TODO
   *
   * ASSICURAZIONI: style = big
   * VITA: style ? big-mobile
   */

  const footerAppMenu = useFindMenu('footer_app')

  const [appModalOpen, setAppModalOpen] = useState<boolean>(false)
  const [appModal, setAppModal] = useState<MenuType | undefined>(undefined)

  useEffect(() => {
    if (appModal) {
      setAppModalOpen(true)
    }
  }, [appModal])

  useEffect(() => {
    if (!appModalOpen) {
      setAppModal(undefined)
    }
  }, [appModalOpen])

  const footerSocialMenu = useFindMenu('footer_social')

  return (
    <footer className={styles.footer}>
      <WContainer variant="lg">
        <div className={styles.head}>
          <h2 className="sr-only">{t('Footer menu')}</h2>
          <a
            href="/"
            className={styles.logo}
            aria-label={
              site === ASSICURAZIONI
                ? t('Crédit Agricole Assicurazioni - Homepage')
                : t('Crédit Agricole Vita - Homepage')
            }
          >
            {site === ASSICURAZIONI ? (
              <LogoAssicurazioniMono title={t('Logo')} />
            ) : site === BANCA ? (
              <LogoVitaMono title={t('Logo')} />
            ) : (
              <LogoCaMono />
            )}
          </a>
        </div>
      </WContainer>
      <WContainer variant="md">
        <div className={styles.menus}>
          {footerMenu &&
            footerMenu.children?.edges &&
            footerMenu.children.edges.map((item) => (
              <div
                className={`${styles.col} ${
                  item.node.style ? styles[`col--${item.node.style}`] : ''
                }`}
                key={item.node.pk}
              >
                <h3 className={styles.menuTitle}>{item.node.name}</h3>
                <ul className={styles.menuChildren}>
                  {item.node.children?.edges.map((item) => (
                    <li key={item.node.pk}>
                      <a
                        href={item.node.url}
                        className={styles.menuItem}
                        key={item.node.pk}
                        onClick={() =>
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'footer',
                            eventAction: item.node.name?.toGTMFormat() || '',
                          })
                        }
                        {...(item.node.blank && { target: '_blank', rel: 'noopener noreferrer' })}
                      >
                        {item.node.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          <div className={styles.col}>
            <div className={styles.socials}>
              {footerSocialMenu &&
                footerSocialMenu.children?.edges &&
                footerSocialMenu.children.edges.map((item) => (
                  <a
                    href={item.node.url}
                    className={styles.socialItem}
                    key={item.node.pk}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'social',
                        eventAction: item.node.key,
                        eventLabel: 'footer',
                      })
                    }
                    aria-label={t(item.node.key || 'social')}
                  >
                    {item.node.key === 'facebook' && <SocialFacebook />}
                    {item.node.key === 'linkedin' && <SocialLinkedin />}
                  </a>
                ))}
            </div>
            <div className={styles.apps}>
              {footerAppMenu &&
                footerAppMenu.children?.edges &&
                footerAppMenu.children.edges.map((item) => (
                  <Button
                    key={item.node.pk}
                    label={item.node.ctaLabel}
                    className={styles.footerButton}
                    rightIcon={<ArrowRight />}
                    href={item.node.url}
                    variant="ghostleft"
                    color="white"
                    onClick={() => {
                      setAppModal(item.node)
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'app',
                        eventAction: item.node.name?.toGTMFormat(),
                        eventLabel: 'download',
                      })
                    }}
                  />
                ))}
              <AppModal app={appModal} open={appModalOpen} setOpen={setAppModalOpen} />
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          {site === ASSICURAZIONI
            ? t('footer_copyright_assicurazioni')
            : t('footer_copyright_vita')}
        </div>
      </WContainer>
    </footer>
  )
}

export default Footer
