import React, { Ref, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useFindMenu, useTrans } from '@next-nx/hooks'
import styles from './NavMobile.module.sass'
import { ArrowLeft, ArrowRight, Mail } from '@next-nx/shared-ui/icons'
import { getIcon } from '@next-nx/utils/icons'
import { Button, WContainer } from '@next-nx/shared-ui/atoms'
import { AppBoxMini } from '@next-nx/shared-ui/molecules'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface PanelProps {
  parent: MenuType
  open: boolean
  setOpen: (v: boolean) => void
  setNavOpen: (v: boolean) => void
  levelMenu?: number
}

interface ItemProps {
  item: MenuType
  variant?: 'primary' | 'secondary'
  open: boolean
  setOpen: (v: boolean) => void
  setNavOpen: (v: boolean) => void
  levelMenu?: number
  openChild?: number | null
}

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
  closeRef?: Ref<HTMLElement>
}

const NavMobileItem = (props: ItemProps) => {
  const {
    item = undefined,
    variant = 'primary',
    open = false,
    setOpen = (v) => undefined,
    setNavOpen = (v) => undefined,
    levelMenu = 0,
    openChild = null,
  } = props

  const t = useTrans()

  return item ? (
    <>
      <li
        className={`${styles.item} ${variant ? styles[`item--${variant}`] : ''} ${
          open ? styles['item--open'] : ''
        }`}
      >
        {item.url ? (
          <div className={`${styles.itemHead} ${styles['itemHead--link']}`}>
            <Link legacyBehavior href={item.url}>
              <a
                className={styles.itemLink}
                onClick={() => {
                  setNavOpen(false)
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: 'menu',
                    eventAction:
                      levelMenu === 1
                        ? 'first_level_menu'
                        : levelMenu === 2
                        ? 'second_level_menu'
                        : levelMenu === 3
                        ? 'third_level_menu'
                        : levelMenu === 0
                        ? 'top_menu'
                        : '',
                    eventLabel: item.name?.toGTMFormat(),
                  })
                }}
                aria-label={item.name}
                tabIndex={
                  ((levelMenu === 1 || levelMenu === 0) && openChild != null) ||
                  (levelMenu === 2 && openChild != null)
                    ? -1
                    : 0
                }
              >
                {!!item.style && (
                  <span className={styles.itemIcon}>
                    {getIcon(item.style, 'navmobile_' + item?.pk?.toString())}
                  </span>
                )}
                <span
                  className={styles.itemLabel}
                  dangerouslySetInnerHTML={{ __html: item.name || '' }}
                  aria-label={item.name}
                />
              </a>
            </Link>
            {!!item.children?.edges?.length && (
              <button
                type="button"
                onClick={() => setOpen(true)}
                className={styles.itemArrow}
                aria-label={t('Apri menu ') + item?.name}
                tabIndex={
                  ((levelMenu === 1 || levelMenu === 0) && openChild != null) ||
                  (levelMenu === 2 && openChild != null)
                    ? -1
                    : 0
                }
              >
                <ArrowRight />
              </button>
            )}
          </div>
        ) : item.children?.edges?.length ? (
          <button
            className={`${styles.itemHead} ${styles['itemHead--noLink']}`}
            onClick={() => setOpen(true)}
            tabIndex={
              ((levelMenu === 1 || levelMenu === 0) && openChild != null) || levelMenu === 3
                ? -1
                : 0
            }
          >
            {!!item.style && (
              <span className={styles.itemIcon}>
                {getIcon(item.style, 'navmobile_' + item?.pk?.toString())}
              </span>
            )}
            <span className={styles.itemLabel} aria-label={item.name}>
              {item.name}
            </span>
            <span className={styles.itemArrow}>
              <ArrowRight />
            </span>
          </button>
        ) : (
          <></>
        )}
      </li>
      {!!item.children?.edges?.length && (
        <NavMobilePanel
          parent={item}
          open={open}
          setOpen={setOpen}
          setNavOpen={setNavOpen}
          levelMenu={levelMenu + 1}
        />
      )}
    </>
  ) : (
    <></>
  )
}

const NavMobilePanel = (props: PanelProps) => {
  const {
    parent = undefined,
    open = false,
    setOpen = (v) => undefined,
    setNavOpen = () => undefined,
    levelMenu = 0,
  } = props

  const [openChild, setOpenChild] = useState<number | null>(null)

  return parent ? (
    <div className={`${styles.panel} ${open ? styles['panel--open'] : ''}`} aria-hidden={!open}>
      <button
        type="button"
        className={styles.back}
        onClick={() => setOpen(false)}
        tabIndex={open ? 0 : -1}
      >
        <span className={styles.backArrow}>
          <ArrowLeft />
        </span>
        {!!parent.style && (
          <span className={styles.backIcon}>
            {getIcon(parent.style, 'navmobile_back_' + parent.pk?.toString())}
          </span>
        )}
        <span className={styles.backLabel}>{parent.name}</span>
      </button>
      {!!parent.children?.edges?.length && (
        <ul className={styles.panelMenu}>
          {parent.children?.edges
            ?.filter((item) => item.node.style !== 'box' && item.node.style !== 'all')
            .map((item) => (
              <NavMobileItem
                key={item.node.pk}
                item={item.node}
                variant="secondary"
                open={openChild === item.node.pk}
                setOpen={(v: boolean) => setOpenChild(v ? item.node.pk : null)}
                setNavOpen={(v: boolean) => setOpen(v)}
                levelMenu={levelMenu}
                openChild={openChild}
              />
            ))}
        </ul>
      )}
    </div>
  ) : (
    <></>
  )
}

const NavMobile = (props: Props) => {
  const { open = false, setOpen = () => undefined, className = '', closeRef = undefined } = props

  const t = useTrans()
  const [openChild, setOpenChild] = useState<number | null>(null)

  useEffect(() => {
    if (!open) setOpenChild(null)
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const mainMenu = useFindMenu('main')
  const menuAreaClienti = useFindMenu('header_area_clienti')
  const mainExtraMobileMenu = useFindMenu('main_extra_mobile')
  const menuTop = useFindMenu('top_header')
  const menuScaricaApp = useFindMenu('nav_scarica_app')

  const ref = useRef(null)

  const handleFocus = (e: any) => {
    if (open) {
      if (ref.current && !ref.current.contains(e.target)) {
        if (closeRef && e.target !== closeRef.current) {
          closeRef.current?.focus()
        }
      }
    }
    return
  }

  useEffect(() => {
    document.addEventListener('focus', handleFocus, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
    }
  }, [open])

  const isEscPressed = (e: KeyboardEvent) => {
    if (e?.key === 'Escape') {
      return true
    } else {
      return false
    }
  }

  return (
    <div
      className={`${styles.navMobile} ${open ? styles['navMobile--open'] : ''} ${
        className ? ` ${className}` : ''
      }`}
      ref={ref}
      id="NavMobile_content"
      onKeyDown={(e) => {
        if (isEscPressed(e)) {
          setOpen(false)
          setOpenChild(null)
        }
      }}
    >
      <ul className={`${styles.menu} ${openChild ? styles['menu--openChild'] : ''}`}>
        {mainMenu &&
          mainMenu.children?.edges &&
          mainMenu.children.edges.map((item) => (
            <NavMobileItem
              key={item.node.pk}
              item={item.node}
              open={openChild === item.node.pk}
              setOpen={(v: boolean) => setOpenChild(v ? item.node.pk : null)}
              setNavOpen={(v: boolean) => setOpen(v)}
              levelMenu={1}
              openChild={openChild}
            />
          ))}
        {menuTop &&
          menuTop.children?.edges &&
          menuTop.children.edges.map((item) => (
            <NavMobileItem
              key={item.node.pk}
              item={item.node}
              variant="secondary"
              open={openChild === item.node.pk}
              setOpen={(v: boolean) => !!setOpenChild && setOpenChild(v ? item.node.pk : null)}
              setNavOpen={(v: boolean) => setOpen(v)}
              levelMenu={0}
              openChild={openChild}
            />
          ))}
        {menuScaricaApp && openChild === null && (
          <li>
            <AppBoxMini item={menuScaricaApp} />
          </li>
        )}
        <div className={`${styles.actions} ${styles['actions--mobileHeightXs']}`}>
          {!!menuAreaClienti && (
            <Button
              className={styles.action}
              label={menuAreaClienti.ctaLabel}
              href={menuAreaClienti.url}
              external
              onClick={() => {
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'header',
                  eventAction: 'area_clienti',
                })
              }}
              ariaLabel={t('Area Clienti')}
            />
          )}
          {mainExtraMobileMenu &&
            mainExtraMobileMenu.children?.edges &&
            mainExtraMobileMenu.children.edges.map((item) => (
              <Button
                key={item.node.pk}
                label={item.node.ctaLabel}
                href={item.node.url}
                leftIcon={getIcon(item.node.style, 'navmobile_extra_' + item?.node?.pk?.toString())}
                variant="ghost"
                className={styles.action}
              />
            ))}
        </div>
      </ul>
      <div className={styles.actions}>
        {!!menuAreaClienti && (
          <Button
            className={styles.action}
            label={menuAreaClienti.ctaLabel}
            href={menuAreaClienti.url}
            external
            onClick={() => {
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'header',
                eventAction: 'area_clienti',
              })
            }}
            ariaLabel={t('Area Clienti')}
          />
        )}
        {mainExtraMobileMenu &&
          mainExtraMobileMenu.children?.edges &&
          mainExtraMobileMenu.children.edges.map((item) => (
            <Button
              key={item.node.pk}
              label={item.node.ctaLabel}
              href={item.node.url}
              leftIcon={getIcon(item.node.style, 'navmobile_extra_' + item?.node?.pk?.toString())}
              variant="ghost"
              className={styles.action}
            />
          ))}
      </div>
    </div>
  )
}

export default NavMobile
