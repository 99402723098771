import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { useFindMenu, useUtente, useTrans, useSite, useIsMobile } from '@next-nx/hooks'
import { scrollTo } from '@next-nx/utils/safe'
import { Button, Breadcrumbs, WContainer, WImage, Modal } from '@next-nx/shared-ui/atoms'
import { AllegatiBox } from '@next-nx/shared-ui/molecules'
import { Page, ContattaciForm } from '@next-nx/shared-ui/organisms'
import styles from './ContattaciTemplate.module.sass'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'

import { Swiper as SwiperType } from 'swiper'
interface Props {
  pagina?: any
}

const ContattaciTemplate = (props: Props) => {
  const { pagina = {} } = props

  const site = useSite()
  const t = useTrans()
  const isMobile = useIsMobile()
  const { utente } = useUtente()
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const [mobileModalOpen, setMobileModalOpen] = useState<boolean>(false)

  const formContattiRef = useRef<HTMLDivElement>(null)
  const [menuActive, setMenuActive] = useState<string | null>('')
  const menuRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<HTMLDivElement[]>([])

  const addSectionRef = (ref: HTMLDivElement) => {
    if (ref) {
      if (sectionRefs.current.filter((x) => x.id === ref.id).length > 0)
        sectionRefs.current = sectionRefs.current.map((x) => (x.id === ref.id ? ref : x))
      else sectionRefs.current.push(ref)
    }
  }

  const handleScroll = () => {
    if (menuRef.current) {
      if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 40 : 0) - 80)
        document.body.classList.add('contattaci-menu-sticky')
      else document.body.classList.remove('contattaci-menu-sticky')
    }

    // Calcolo se sono all'altezza di un box del menu
    const scrollOffset = window.scrollY + (toolbarPresente ? 40 : 0) + 80 + 80 + 20 + 10 + 80 // + header + menu + margine + correzzione
    const match = sectionRefs.current.filter(
      (x) => scrollOffset >= x.offsetTop && scrollOffset <= x.offsetTop + x.clientHeight
    )
    // Potrebbero esserci box sovrapposti, prendo l'ultimo match
    setMenuActive(match.length > 0 ? match[match.length - 1].id?.replace('menu_', '') : null)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const menuContattaci = useFindMenu('contattaci')

  const focusToElement = (id: string | number) => {
    const elem = document.querySelector(`#menu_${id.toString() || ''}`)
    if (elem) {
      elem.focus()
    }
  }

  const [menuSwiper, setMenuSwiper] = useState<SwiperType>()
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    if (menuActive && !scrolling) {
      const index = menuContattaci?.children?.edges.findIndex(
        (item) => `${item.node.pk}` === menuActive
      )
      if (index !== -1) menuSwiper?.slideTo(index)
    }
  }, [menuActive])

  // const findPercentilePosition = (items: any, item: string) => {
  //   const index = items.indexOf(item)

  //   if (index === -1) {
  //     return '0%'
  //   }

  //   const percentile = items.length > 1 ? ((index / (items.length - 1)) * 100) : 0

  //   return percentile.toString() + '%'
  // }

  // const sectionIds = menuContattaci?.children?.edges?.map((child) => child?.node?.pk?.toString())

  // const [scrollBarWidth, setScrollBarWidth] = useState<string>('0%')
  // const [scrollBarVisible, setScrollBarVisible] = useState<boolean>(false)

  // useEffect(() => {
  //   if (menuActive) {
  //     setScrollBarWidth(findPercentilePosition(sectionIds, menuActive))
  //   }
  // }, [menuActive])

  // const checkIfScrollNeeded = () => {
  //   const swiperMenu = document.getElementById('swiper-menu')
  //   if (swiperMenu) {
  //     const menuWidth = swiperMenu.scrollWidth
  //     const screenWidth = window.innerWidth
  //     return menuWidth > screenWidth
  //   }
  //   return false
  // }

  // useEffect(() => {
  //   if (checkIfScrollNeeded()) {
  //     setScrollBarVisible(true)
  //   }
  // }, [])

  const isEnterPressed = (e: KeyboardEvent) => {
    if (e?.key === 'Enter') {
      return true
    } else {
      return false
    }
  }

  const isSpacePressed = (e: KeyboardEvent) => {
    if (e?.code === 'Space') {
      return true
    } else {
      return false
    }
  }

  return (
    <Page
      pagina={pagina}
      item={{}}
      contentClassName={styles.chiSiamoContent}
      recaptchaRequired
      className={styles.root}
    >
      <main>
        {pagina?.breadcrumbs && (
          <WContainer>
            <Breadcrumbs
              breadcrumbs={pagina?.breadcrumbs}
              className={styles.contattaciBreadcrumbs}
            />
          </WContainer>
        )}
        <div className={styles.head}>
          <div className={styles.imageBox}>
            <WImage
              src={isMobile ? pagina.previewImage : pagina.mainImage}
              maxWidth={1920}
              maxHeight={340}
              layout="intrinsic"
              objectFit="cover"
              priority={true}
              loading="eager"
              key={`contattaci-main-image${isMobile ? '-mobile' : ''}`}
            />
          </div>
          <div className={styles.heroBox}>
            <h1 className={styles.heroTitle}>{pagina.nome}</h1>
            {pagina.descrizione && (
              <div
                className={styles.heroDescription}
                dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
              />
            )}
          </div>
        </div>
        <div className={styles.mainSection}>
          <WContainer>
            <div className={styles.main}>
              <div className={styles.formMobile}>
                <h2 className={styles.formMobileTitle}>{t('Hai bisogno di assistenza?')}</h2>
                <div className={styles.formMobileCtaBox}>
                  <Button
                    label={t('Invia messaggio')}
                    className={styles.formMobileCta}
                    onClick={() => setMobileModalOpen(true)}
                  />
                </div>
              </div>
              <div className={styles.formBox}>
                <ContattaciForm />
              </div>
              <div
                className={`${styles.content} custom-content`}
                dangerouslySetInnerHTML={{ __html: menuContattaci?.description || '' }}
              />
            </div>
          </WContainer>
        </div>
        {menuContattaci && menuContattaci.children?.edges && (
          <>
            {site === ASSICURAZIONI && (
              <div className={styles.menuSectionRef} ref={menuRef}>
                <div className={styles.menuSection}>
                  <WContainer>
                    <div className={`${styles.menu} ${styles['menu--desktop']}`}>
                      {/* DESKTOP */}
                      <Swiper
                        id="swiper-menu"
                        spaceBetween={0}
                        onSwiper={(swiper) => setMenuSwiper(swiper)}
                        slidesPerView="auto"
                        modules={[A11y]}
                        a11y={{
                          enabled: true,
                          prevSlideMessage: t('Slide precedente'),
                          nextSlideMessage: t('Slide successiva'),
                          paginationBulletMessage: 'Vai alla slide {{index}}',
                        }}
                        className={styles.swiperDesktop}
                      >
                        {menuContattaci.children.edges.map((child, index) => (
                          <SwiperSlide key={child.node.pk}>
                            <a
                              href={`#menu_${child.node.pk}`}
                              className={`${styles.menuItem} ${
                                menuActive === `${child.node.pk}` ? styles['menuItem--active'] : ''
                              }`}
                              onClick={(e) => {
                                e.preventDefault()
                                setScrolling(true)
                                scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                                setTimeout(() => setScrolling(false), 1000)
                              }}
                              aria-labelledby={`#menu_${child.node.pk}_label`}
                              onKeyDown={(e: KeyboardEvent) => {
                                if (isEnterPressed(e) || isSpacePressed(e)) {
                                  focusToElement(child.node.pk)
                                  setScrolling(true)
                                  scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                                  setTimeout(() => setScrolling(false), 1000)
                                }
                              }}
                            >
                              <span
                                id={`#menu_${child.node.pk}_label`}
                                dangerouslySetInnerHTML={{ __html: child.node.name || '' }}
                              />
                            </a>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div className={`${styles.menu} ${styles['menu--mobile']}`}>
                      {/* MOBILE */}
                      <Swiper
                        id="swiper-menu-mobile"
                        spaceBetween={0}
                        onSwiper={(swiper) => setMenuSwiper(swiper)}
                        slidesPerView={1}
                        modules={[A11y, Navigation]}
                        navigation={{ enabled: true }}
                        a11y={{
                          enabled: true,
                          prevSlideMessage: t('Slide precedente'),
                          nextSlideMessage: t('Slide successiva'),
                          paginationBulletMessage: 'Vai alla slide {{index}}',
                        }}
                        className={styles.swiperMobile}
                        slideNextClass={styles.btnNext}
                        slidePrevClass={styles.btnPrev}
                      >
                        {menuContattaci.children.edges.map((child, index) => (
                          <SwiperSlide key={child.node.pk}>
                            <a
                              href={`#menu_${child.node.pk}`}
                              className={`${styles.menuItem} ${
                                menuActive === `${child.node.pk}` ? styles['menuItem--active'] : ''
                              }`}
                              onClick={(e) => {
                                e.preventDefault()
                                setScrolling(true)
                                scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                                setTimeout(() => setScrolling(false), 1000)
                              }}
                              aria-labelledby={`#menu_${child.node.pk}_label`}
                              onKeyDown={(e: KeyboardEvent) => {
                                if (isEnterPressed(e) || isSpacePressed(e)) {
                                  focusToElement(child.node.pk)
                                  setScrolling(true)
                                  scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                                  setTimeout(() => setScrolling(false), 1000)
                                }
                              }}
                            >
                              <span
                                id={`#menu_${child.node.pk}_label`}
                                dangerouslySetInnerHTML={{ __html: child.node.name || '' }}
                              />
                            </a>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </WContainer>
                  {/* {scrollBarVisible && <div className={styles.progressBar}>
                    <div style={{ width: scrollBarWidth }}></div>
                  </div>} */}
                </div>
              </div>
            )}
            <WContainer variant="xs">
              <div className={styles.list}>
                {menuContattaci.children.edges
                  .filter((child) => child.node.style !== 'allegati')
                  .map((child) => (
                    <section
                      id={`menu_${child.node.pk}`}
                      key={child.node.pk}
                      className={`${styles.item} custom-content`}
                      dangerouslySetInnerHTML={{
                        __html: child.node.description || '',
                      }}
                      ref={addSectionRef}
                      tabIndex={0}
                    />
                  ))}
              </div>
            </WContainer>
            {menuContattaci.children.edges.filter((child) => child.node.style === 'allegati')
              .length > 0 && (
              <div className={styles.allegati}>
                <WContainer>
                  {menuContattaci.children.edges
                    .filter((child) => child.node.style === 'allegati')
                    .map((child) => (
                      <section
                        id={`menu_${child.node.pk}`}
                        ref={addSectionRef}
                        key={child.node.pk}
                        tabIndex={0}
                      >
                        <AllegatiBox item={child.node} />
                      </section>
                    ))}
                </WContainer>
              </div>
            )}
          </>
        )}
        <div className={styles.formMobile}>
          <Modal
            open={mobileModalOpen}
            setOpen={setMobileModalOpen}
            closeButton
            className={styles.formModal}
            ariaLabel="Contattaci"
          >
            <div className={styles.formModalContent} ref={formContattiRef}>
              <ContattaciForm id="mobile" formContattiRef={formContattiRef} />
            </div>
          </Modal>
        </div>
      </main>
    </Page>
  )
}

export default ContattaciTemplate
