import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useSite, useTrans } from '@next-nx/hooks'
import { Alert } from '@next-nx/shared-ui/icons'
import {
  Button,
  FormDate,
  FormField,
  FormError,
  FormInput,
  FormLayout,
  FormRadio,
  Modal,
  NotificationBar,
} from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoModal.module.sass'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import {
  useCalcolaCodiceFiscaleMutation,
  useSalvaTrasformaQuotazioneFastQuoteAutoMutation,
} from '@next-nx/shared-graphql/assicurazioni'
import { useCreateRichiestaPreventivatoreMutation } from '@next-nx/shared-graphql/assicurazioni'
import { ASSICURAZIONI, BANCA, SITES_ID } from 'libs/shared-ui/utils/settings'
import { QuotazioneAutoType, GaranziaQuotazioneAutoType } from '@next-nx/utils/types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useRouter } from 'next/router'
import { sendGTMData } from 'libs/shared-ui/utils/gtm'

interface Props {
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  keyPremio: string
  importoLordoAnnuoTotale: number
  importoLordoAnnuoTotaleScontato: number
  garanzieObbligatorie: string[]
  garanzieSelezionate: string[]
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
}

const QuotazioneAutoModal = (props: Props) => {
  const {
    quotazione,
    garanzie,
    keyPremio,
    importoLordoAnnuoTotale,
    importoLordoAnnuoTotaleScontato,
    garanzieObbligatorie,
    garanzieSelezionate,
    open = false,
    setOpen = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  const site = useSite()
  const router = useRouter()
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [step, setStep] = useState('1')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [calcolaCodiceFiscale] = useCalcolaCodiceFiscaleMutation()
  const [salvaTrasformaQuotazioneFastQuoteAuto] = useSalvaTrasformaQuotazioneFastQuoteAutoMutation()
  const [createRichiestaPreventivatore] = useCreateRichiestaPreventivatoreMutation()

  const getGaranzieSelezionateList = () => {
    let garanzieList: string[] = []
    // per ciascuna garanzia obbligatoria (_RCA)
    garanzieObbligatorie.forEach((g) => {
      const garanzia = garanzie.find((x) => x.codice === g)
      garanzieList.push(g)
      garanzia?.children?.forEach((x) => {
        if (!x.codice.startsWith('_')) {
          garanzieList.push(x.codice)
        }
      })
    })
    // per ciascuna garanzia selezionata (appartenente o no a un pacchetto)
    garanzieSelezionate.forEach((g) => {
      const garanzia = garanzie.find((x) => x.codice === g)
      garanzieList.push(g)
      garanzia?.children?.forEach((x) => {
        if (!x.codice.startsWith('_')) {
          garanzieList.push(x.codice)
        }
      })
    })

    // rimuovo eventuali duplicati (non dovrebbero esserci)
    garanzieList = [...new Set([...garanzieList])]

    return garanzieList
  }

  const handleChangeStep = (v: string, formChangeValue: any) => {
    setStep(v)
    formChangeValue('step', v)
  }

  useEffect(() => {
    // alla chiusura della modale resetto i valori di default
    if (!open) {
      setStep('1')
      setError(null)
    }
  }, [open])

  const calcolaCf = async (values: any) => {
    return calcolaCodiceFiscale({
      variables: {
        input: {
          nome: values?.nomeCf || '',
          cognome: values?.cognomeCf || '',
          dataNascita: values?.dataNascitaCf || '',
          luogoNascita: values?.luogoNascitaCf || '',
          genere: values?.genereCf || '',
        },
      },
    })
  }

  const salvaTrasformaQuotazione = async (values: any, captcha: any) => {
    return salvaTrasformaQuotazioneFastQuoteAuto({
      variables: {
        input: {
          siteId: SITES_ID[site],
          cf: values?.cf || '',
          idQuotazione: quotazione?.idQuotazione || '',
          garanzieSelezionate: getGaranzieSelezionateList(),
          keyPremio: keyPremio,
          importoLordoAnnuo: importoLordoAnnuoTotale,
          importoLordoAnnuoScontato: importoLordoAnnuoTotaleScontato,
          captcha,
        },
      },
    })
  }

  const createPreventivo = async (values: any, captcha: string) => {
    return createRichiestaPreventivatore({
      variables: {
        input: {
          siteId: SITES_ID[site],
          nome: values.nome,
          cognome: values.cognome,
          email: values.email,
          telefono: values.telefono,
          prodottoId: quotazione?.prodotto.pk,
          lineeIds: [],
          privacy: values.privacy,
          captcha: captcha,
          quotazioneAutoId: quotazione.pk,
        },
      },
    })
  }

  const validationSchema = {
    '1': yup.object().shape({
      cf: yup
        .string()
        .length(16, 'La lunghezza del testo deve essere di 16 caratteri')
        .required(t('Campo obbligatorio')),
    }),
    '1-bis': yup.object().shape({
      nomeCf: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, t('Puoi inserire solo lettere in questo campo'))
        .required(t('Campo obbligatorio')),
      cognomeCf: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, t('Puoi inserire solo lettere in questo campo'))
        .required(t('Campo obbligatorio')),
      dataNascitaCf: yup
        .string()
        .typeError(t('É necessario specificare una data di nascita'))
        .required(t('Campo obbligatorio')),
      luogoNascitaCf: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, t('Puoi inserire solo lettere in questo campo'))
        .required(t('Campo obbligatorio')),
      genereCf: yup.string().required(t('Campo obbligatorio')),
    }),
    '2': yup.object().shape({
      nome: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, t('Puoi inserire solo lettere in questo campo'))
        .required(t('Campo obbligatorio')),
      cognome: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, t('Puoi inserire solo lettere in questo campo'))
        .required(t('Campo obbligatorio')),
      telefono: yup
        .string()
        .matches(/^[+]?[0-9]+$/, 'Non è un formato telefonico corretto')
        .required('Campo obbligatorio'),
      email: yup.string().email(t("Inserire un'email valida")).required(t('Campo obbligatorio')),
    }),
  }

  const initialValues = {
    step: step,
    cf: '',
    nomeCf: '',
    cognomeCf: '',
    dataNascitaCf: '',
    luogoNascitaCf: '',
    genereCf: '',
    nome: '',
    cognome: '',
    telefono: '',
    email: quotazione?.email || '',
    privacy: true, // Di default è accettata
  }

  return (
    <Modal
      id={'QuotazioneAutoModal'}
      open={open}
      setOpen={setOpen}
      closeButton
      className={`${styles.quotazioneModal}
        ${step === '1-bis' ? styles['quotazioneModal--calcoloCf'] : ''}
        ${step === '2' ? styles['quotazioneModal--datiCliente'] : ''}
      ${className ? ` ${className}` : ''}`}
    >
      <div className={styles.content}>
        <p
          id={'QuotazioneAutoModal_title'}
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: t('Ci siamo quasi') }}
        />
        <div className={styles.description}>
          {step === '2'
            ? t('Inserisci i tuoi dati per ricevere la quotazione.')
            : t('Inserisci il tuo codice fiscale per proseguire.')}
        </div>
        <Formik
          validationSchema={validationSchema[step]}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            if (step === '1') {
              if (!executeRecaptcha) {
                return
              }

              // chiamata webservice api 2 e 3 per concludere quotazione
              let isError = false
              try {
                const token = await executeRecaptcha('salva_trasforma_quotazione_submit')

                const salvaTrasformaQuotazioneResponse = await salvaTrasformaQuotazione(
                  values,
                  token
                )
                const salvaTrasformaQuotazioneData =
                  salvaTrasformaQuotazioneResponse.data?.salvaTrasformaQuotazioneAutoFast || {}

                if (salvaTrasformaQuotazioneData?.status) {
                  handleChangeStep('2', actions.setFieldValue)
                  setError(null)
                  actions.setTouched({
                    nome: false,
                    cognome: false,
                    telefono: false,
                    email: false,
                  })
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                    eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                    eventLabel: 'step3_ok',
                  })
                } else {
                  if (
                    !salvaTrasformaQuotazioneData?.statusSalva &&
                    salvaTrasformaQuotazioneData?.message &&
                    salvaTrasformaQuotazioneData?.message != 'KO'
                  ) {
                    setError(salvaTrasformaQuotazioneData?.message)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                      eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'step3_error',
                    })
                  } else if (salvaTrasformaQuotazioneData?.statusCode == 400) {
                    setCompleted(true)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                      eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'step3_error',
                    })
                    window.location.href = `/quotazione/auto${site === BANCA ? '-ca' : ''}/${
                      quotazione.idQuotazione
                    }/error-cf`
                  } else if (!salvaTrasformaQuotazioneData?.quotazione?.isCliente) {
                    setCompleted(true)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                      eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'step3_no_cliente_ok',
                    })
                    window.location.href = `/quotazione/auto${site === BANCA ? '-ca' : ''}/${
                      quotazione.idQuotazione
                    }/thank-you-no-cliente`
                    // router.push(`/quotazione/auto${site === BANCA ? '-ca' : ''}/${quotazione.idQuotazione}/thank-you-no-cliente`)
                  } else {
                    isError = true
                  }
                }
              } catch (error) {
                isError = true
              }
              if (isError) {
                setCompleted(true)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                  eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                  eventLabel: 'step3_error',
                })
                window.location.href = `/quotazione/auto${site === BANCA ? '-ca' : ''}/${
                  quotazione.idQuotazione
                }/error`
                // router.push(`/quotazione/auto${site === BANCA ? '-ca' : ''}/${quotazione.idQuotazione}/error`)
              }
            }
            if (step === '1-bis') {
              let isError = false
              try {
                const calcolaCfResponse = await calcolaCf(values)
                const { status, cf } = calcolaCfResponse.data?.calcolaCodiceFiscale || {}
                if (status) {
                  actions.setFieldValue('cf', cf)
                  setError(null)
                  handleChangeStep('1', actions.setFieldValue)
                } else {
                  isError = true
                }
              } catch (error) {
                isError = true
              }

              if (isError) {
                setError(t('Errore nel calcolo del codice fiscale'))
              }

              actions.setSubmitting(false)
            }
            if (step === '2') {
              // chiamata api per salvare richiesta preventivo da sincronizzare
              if (!executeRecaptcha) {
                return
              }

              try {
                const token = await executeRecaptcha('richiesta_preventivatore_submit')
                const createPreventivoResponse = await createPreventivo(values, token)
                setCompleted(true)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                  eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                  eventLabel: 'step4_ok',
                })
                window.location.href = `/quotazione/auto${site === BANCA ? '-ca' : ''}/${
                  quotazione.idQuotazione
                }/thank-you`
                // router.push(`/quotazione/auto${site === BANCA ? '-ca' : ''}/${quotazione.idQuotazione}/thank-you`)
              } catch (error) {
                console.log(error)
                setCompleted(true)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                  eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                  eventLabel: 'step4_error',
                })
                window.location.href = `/quotazione/auto/${site === BANCA ? '-ca' : ''}${
                  quotazione.idQuotazione
                }/error`
                // router.push(`/quotazione/auto${site === BANCA ? '-ca' : ''}/${quotazione.idQuotazione}/error`)
              }

              // actions.setSubmitting(false)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isValid,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              {step === '1-bis' && (
                <div className={styles.cfFormPart}>
                  <p className={styles.cfTitle}>{t('Calcola il tuo codice fiscale')}</p>
                  <div className={`${styles.cfCloseCta} ${styles['cfCloseCta--mobile']}`}>
                    <Button
                      label={t('Inserisci il codice manualmente')}
                      variant="ghost"
                      onClick={() => {
                        handleChangeStep('1', setFieldValue)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                          eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                          eventLabel: '02_calcolo_cf_inserisci_cf_manualmente',
                        })
                      }}
                    />
                  </div>
                  <FormLayout>
                    <FormField
                      label={t('Nome')}
                      {...getFormFieldArgs('nomeCf', values, errors, touched)}
                    >
                      <FormInput
                        name="nomeCf"
                        placeholder={t('Inserisci il tuo nome')}
                        value={values.nomeCf}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('Cognome')}
                      {...getFormFieldArgs('cognomeCf', values, errors, touched)}
                    >
                      <FormInput
                        name="cognomeCf"
                        placeholder={t('Inserisci il tuo cognome')}
                        value={values.cognomeCf}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('Data di nascita')}
                      {...getFormFieldArgs('dataNascitaCf', values, errors, touched)}
                      layout="sm"
                    >
                      <FormDate
                        name="dataNascitaCf"
                        placeholder={t('gg/mm/aaaa')}
                        value={values.dataNascitaCf}
                        onChange={(e) => {
                          setFieldValue(e.target.name, e.target.value)
                          // setFieldTouched(e.target.name)
                        }}
                        popperPlacement="top-start"
                        onBlur={handleBlur}
                        maxDate={new Date()}
                      />
                    </FormField>
                    <FormField
                      label={t('Luogo di nascita')}
                      {...getFormFieldArgs('luogoNascitaCf', values, errors, touched)}
                    >
                      <FormInput
                        name="luogoNascitaCf"
                        placeholder={t('Inserisci il luogo di nascita')}
                        value={values.luogoNascitaCf}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('Genere')}
                      {...getFormFieldArgs('genereCf', values, errors, touched)}
                      layout="sm"
                    >
                      <FormRadio
                        id="quotazionemodal_generecf"
                        name="genereCf"
                        value={values.genereCf}
                        options={[
                          { id: 'f', label: 'F', value: 'F' },
                          { id: 'm', label: 'M', value: 'M' },
                        ]}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                      />
                    </FormField>
                  </FormLayout>
                  <div className={styles.cfBottom}>
                    <div className={`${styles.cfCloseCta} ${styles['cfCloseCta--desktop']}`}>
                      <Button
                        label={t('Inserisci il codice manualmente')}
                        variant="ghost"
                        onClick={() => {
                          handleChangeStep('1', setFieldValue)
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                            eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                            eventLabel: '02_calcolo_cf_inserisci_cf_manualmente',
                          })
                        }}
                      />
                    </div>
                    <div className={styles.cfSubmit}>
                      <Button
                        label={t('Calcola il tuo codice fiscale')}
                        type="submit"
                        disabled={!Object.keys(touched).length || !isValid || isSubmitting}
                        onClick={() => {
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                            eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                            eventLabel: '02_calcolo_cf_calcola_cf',
                          })
                        }}
                        loading={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              )}
              {step === '1' && (
                <div className={styles.clienteFormPart}>
                  <div className={styles.clienteRow}>
                    <div className={styles.cfCol}>
                      <FormField
                        label={t('Codice fiscale')}
                        {...getFormFieldArgs('cf', values, errors, touched)}
                        className={styles.cfField}
                        space="none"
                        layout="full"
                      >
                        <FormInput
                          name="cf"
                          placeholder={t('Inserisci il codice fiscale')}
                          value={values.cf}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value.toUpperCase())
                            setFieldTouched(e.target.name, true, false)
                            setError(null)
                          }}
                          onBlur={handleBlur}
                        />
                      </FormField>
                      <div className={styles.calcoloCf}>
                        {t('Non te lo ricordi?')}{' '}
                        <a
                          className={styles.cfCta}
                          onClick={() => {
                            handleChangeStep('1-bis', setFieldValue)
                            sendGTMData({
                              event: 'GAevent',
                              eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
                              eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
                              eventLabel: '01_calcolo_cf',
                            })
                          }}
                        >
                          <strong>{t('Calcola il tuo codice fiscale')}</strong>
                        </a>
                      </div>
                      {error && (
                        <div className={styles.error}>
                          <FormError message={`<strong>Attenzione</strong>: ${error}`} visible />
                        </div>
                      )}
                      <div className={styles.bottom}>
                        <p
                          className={styles.privacyDisclaimer}
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'Proseguendo, si accetta l’informativa {0}Privacy{1} e si conferma di voler ricevere comunicazioni commerciali ai recapiti da lei forniti.',
                              ['<a href="/privacy" target="_blank">', '</a>']
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.clienteSubmit}>
                      <Button
                        type="submit"
                        label={t('Conferma e verifica')}
                        disabled={
                          !Object.keys(touched).length || !isValid || isSubmitting || completed
                        }
                        loading={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              )}
              {step === '2' && (
                <>
                  <FormLayout>
                    <FormField
                      label={t('Nome')}
                      {...getFormFieldArgs('nome', values, errors, touched)}
                    >
                      <FormInput
                        name="nome"
                        placeholder={t('Inserisci il tuo nome')}
                        value={values.nome}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('Cognome')}
                      {...getFormFieldArgs('cognome', values, errors, touched)}
                    >
                      <FormInput
                        name="cognome"
                        placeholder={t('Inserisci il tuo cognome')}
                        value={values.cognome}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('Telefono cellulare')}
                      {...getFormFieldArgs('telefono', values, errors, touched)}
                    >
                      <FormInput
                        type="tel"
                        name="telefono"
                        placeholder={t('Inserisci il tuo numero')}
                        value={values.telefono}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField
                      label={t('E-mail')}
                      {...getFormFieldArgs('email', values, errors, touched)}
                    >
                      <FormInput
                        name="email"
                        placeholder={t('Inserisci la tua e-mail')}
                        type="email"
                        value={values.email}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </FormLayout>
                  <div className={styles.noClienteBottom}>
                    <div className={styles.noClienteSubmit}>
                      <Button
                        type="submit"
                        label={t('Conferma e invia')}
                        disabled={
                          !Object.keys(touched).length || !isValid || isSubmitting || completed
                        }
                        loading={isSubmitting}
                      />
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoModal
